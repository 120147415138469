@import "mixins/notification-widget.mixin.material";

.parcel-notification-widget {
  @include parcel-notification-widget;
}

@import "themes/notification-widget-kion.material";

.theme-stl {
  @import "themes/notification-widget-stl.material";
}
.theme-lmh, .theme-fwk {
  @import "themes/notification-widget-lmh.material";
}
.theme-bao {
  @import "themes/notification-widget-bao.material";
}
.theme-kion {
  @import "themes/notification-widget-kion.material";
}
