@mixin parcel-mat-tree {
  &.mat-tree {
    .mat-tree-node {
      height: $space-8;
    }
    .mdc-icon-button__ripple {
      display: none;
    }
    .mat-mdc-icon-button.mat-mdc-button-base {
      padding-left: $space-4;
    }
  }
}
