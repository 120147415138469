@mixin parcel-mat-modal {

  &.fixed-width {
    max-width: 520px !important;
  }

  .mdc-dialog {
    .mdc-dialog {
      &__title {
        margin: 0;
        padding: $space-5 $space-5 $space-2 $space-5;

        > * {
          margin: 0;
          padding: 0;
        }

        &:before {
          display: none;
        }
      }
      &__content {
        padding: $space-2 $space-5;
      }
      &__actions {
        padding: $space-4 $space-5 $space-5 $space-5;

        .mat-button-base + .mat-button-base,
        .mat-mdc-button-base + .mat-mdc-button-base {
          margin-left: $space-4;
        }
      }
    }

    .parcel-mat-modal--close {
      background: transparent;
      border: none;
      position: absolute;
      top: $space-5;
      right: $space-5;

      width: $space-6;
      height: $space-6;
      padding: 0;

      > * {
        width: 100%;
        height: 100%;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}
