$slider-track-height: $space-1;
$slider-knob-size: 20px;

@mixin parcel-mat-slider {
  &.mat-mdc-slider {
    .mdc-slider__track {
      height: $slider-track-height;

      .mdc-slider__track--inactive {
        height: $slider-track-height;
        opacity: 1;
      }
      .mdc-slider__track--active {
        top: 0;
        height: $slider-track-height;
      }
    }

    .mdc-slider__thumb {
      .mat-ripple {
        display: none;
      }

      .mdc-slider__thumb-knob {
        box-shadow: none;
        border-radius: 50%;
        width: $slider-knob-size;
        height: $slider-knob-size;
        border-style: solid;
        border-width: calc(#{$slider-knob-size} / 2) calc(#{$slider-knob-size} / 2);
      }
    }

    .mdc-slider__value-indicator {
      opacity: 1;
    }

    .mdc-slider__tick-mark--active {
      opacity: 1;
    }
     .mdc-slider__tick-mark--inactive {
       opacity: 1;
     }

    &.mdc-slider--disabled {
      opacity: 1;
    }
  }
}
