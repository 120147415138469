@mixin parcel-breadcrumb {
  &.mat-list-base {
    padding: 0;
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;

    &.mat-list-base {
      > .mat-list-item {
        display: inline-block;
        width: auto;
        height: auto;

        > .mat-list-item-content {
          padding: 0;

          &:after {
            content: '';
            background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="%23505050" viewBox="0 0 32 32"><path fill-rule="evenodd" d="M12.414 24.414 11 23l7.293-7.293L11 8.414 12.414 7l8.707 8.707-8.707 8.707Z" clip-rule="evenodd"/></svg>') center center;
            width: $space-4;
            height: 20px;
            background-size: contain;
            background-repeat: no-repeat;
            margin: 0 $space-1;
          }

          .parcel-anchor {
            &:hover {
              text-decoration: underline;
            }
          }
        }

        &:last-child {
          > .mat-list-item-content {
            &:after {
              display: none;
            }
            .parcel-anchor {
              font-weight: bold;
              text-decoration: none !important;
            }
          }
        }
      }
    }
  }
}
