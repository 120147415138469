.parcel-mat-slide-toggle {
  &__on, &__off {
    @include font('body-s', 'stl');
    color: $color-stl-grey-300;

    &.active {
      color: $color-stl-grey-900;
    }
  }

  &.mat-mdc-slide-toggle,
  &.mat-mdc-slide-toggle.mat-primary,
  &.mat-mdc-slide-toggle.mat-accent,
  &.mat-mdc-slide-toggle.mat-warn {
    --mdc-switch-disabled-selected-handle-color: #{$color-stl-grey-white};
    --mdc-switch-disabled-unselected-handle-color: #{$color-stl-grey-white};
    --mdc-switch-disabled-selected-track-color: #{$color-stl-brand-500};
    --mdc-switch-disabled-unselected-track-color: #{$color-stl-grey-300};
    --mdc-switch-disabled-track-opacity: .6;

    --mdc-switch-unselected-focus-state-layer-color: #{$color-stl-grey-300};
    --mdc-switch-unselected-pressed-state-layer-color: #{$color-stl-grey-300};
    --mdc-switch-unselected-hover-state-layer-color: #{$color-stl-grey-300};

    --mdc-switch-unselected-focus-track-color: #{$color-stl-grey-400};
    --mdc-switch-unselected-hover-track-color: #{$color-stl-grey-400};
    --mdc-switch-unselected-pressed-track-color: #{$color-stl-grey-400};
    --mdc-switch-unselected-track-color: #{$color-stl-grey-300};
    --mdc-switch-unselected-focus-handle-color: #{$color-stl-grey-white};
    --mdc-switch-unselected-hover-handle-color: #{$color-stl-grey-white};
    --mdc-switch-unselected-pressed-handle-color: #{$color-stl-grey-white};
    --mdc-switch-unselected-handle-color: #{$color-stl-grey-white};
    --mdc-switch-handle-surface-color: #{$color-stl-grey-white};

    --mdc-switch-selected-handle-color: #{$color-stl-grey-white};
    --mdc-switch-selected-focus-handle-color: #{$color-stl-grey-white};
    --mdc-switch-selected-hover-handle-color: #{$color-stl-grey-white};
    --mdc-switch-selected-pressed-handle-color: #{$color-stl-grey-white};
    --mdc-switch-selected-focus-track-color: #{$color-stl-brand-500};
    --mdc-switch-selected-hover-track-color: #{$color-stl-brand-700};
    --mdc-switch-selected-pressed-track-color: #{$color-stl-brand-900};
    --mdc-switch-selected-track-color: #{$color-stl-brand-500};

    --mdc-switch-selected-focus-state-layer-color: #{$color-stl-brand-500};
    --mdc-switch-selected-hover-state-layer-color: #{$color-stl-brand-700};
    --mdc-switch-selected-pressed-state-layer-color: #{$color-stl-brand-900};
  }

  &.cdk-keyboard-focused {
    .mdc-switch {
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: -1px;
        border: 1px solid $color-stl-feedback-focused;
        border-radius: 8px;
      }
    }
  }

  &.parcel-mat-slide-toggle--state-intermediate {
    > .mdc-form-field {
      .mdc-switch {
        .mdc-switch__track {
          &:before, &:after {
            background: $color-stl-brand-500 !important;
          }
        }
        &:hover {
          &:not(.mdc-switch--disabled) {
            .mdc-switch__track {
              &:before, &:after {
                background: $color-stl-brand-700 !important;
              }
            }
          }
        }
      }
    }
  }
}
