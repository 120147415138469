.parcel-mat-tooltip {
  .mdc-tooltip__surface {
    @include border('primary-rounded-2-5', 'primary', $color-stl-grey-900);
    @include font('body-s', 'stl');
    background: $color-stl-grey-900;
    color: $color-stl-grey-white !important;
    padding: $space-1 $space-2;
  }
}

.mat-mdc-tooltip-panel-left {
  .parcel-mat-tooltip {
    &:after {
      border-color: transparent transparent transparent $color-stl-grey-900;
    }
  }
}

.mat-mdc-tooltip-panel-right {
  .parcel-mat-tooltip {
    &:after {
      border-color: transparent $color-stl-grey-900 transparent transparent;
    }
  }
}

.mat-mdc-tooltip-panel-below {
  .parcel-mat-tooltip {
    &:after {
      border-color: transparent transparent $color-stl-grey-900 transparent;
    }
  }
}

.mat-mdc-tooltip-panel-above {
  .parcel-mat-tooltip {
    &:after {
      border-color: $color-stl-grey-900 transparent transparent transparent;
    }
  }
}
