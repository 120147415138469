@font-face {
  font-family: Daxline Pro;
  src: url("fonts/lmh/daxline-web-pro/DaxlineWebPro.woff2") format("woff2");
  ascent-override: 90%; // fix baseline
}
@font-face {
  font-family: Daxline Pro;
  src: url("fonts/lmh/daxline-web-pro/DaxlineWebPro-Bold.woff2") format("woff2");
  ascent-override: 90%; // fix baseline
  font-weight: 700;
}

@font-face {
  font-family: DaxlinePro;
  src: url("fonts/lmh/daxline-web-pro/DaxlineWebPro.woff2") format("woff2");
  ascent-override: 90%; // fix baseline
}

@font-face {
  font-family: STILL Type;
  src: url('fonts/stl/STILL-type/STILLType-Regular.ttf') format("truetype");
  ascent-override: 88%; // fix baseline
}
@font-face {
  font-family: STILL Type;
  src: url('fonts/stl/STILL-type/STILLType-Bold.ttf') format("truetype");
  ascent-override: 88%; // fix baseline
  font-weight: 700;
}
