@mixin parcel-mat-menu {
  width: 290px !important;

  > .mat-menu-content {
    padding: 0 !important;
    margin: 0 !important;

    > mat-selection-list {
      padding: 0 !important;
      margin: 0 !important;

      .mat-list-text {
        padding: 0 !important;
      }
    }
  }
}
