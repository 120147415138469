.parcel-anchor {
  color: $color-lmh-brand-500;
  @include font('body-m', 'lmh');
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
  &:active {
    color: $color-lmh-grey-700;
  }
  &.cdk-focused {
    &.cdk-keyboard-focused {
      outline: 2px solid $color-lmh-feedback-focused;
    }
  }

  &.disabled {
    color: $color-lmh-grey-300;
    pointer-events: none;
  }

  &--light {
    color: $color-lmh-grey-white;
    @include font('body-m', 'lmh');
    font-weight: bold;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
    &:active {
      color: $color-lmh-grey-white;
    }
    &.cdk-focused {
      &.cdk-keyboard-focused {
        outline: 2px solid $color-lmh-feedback-focused;
      }
    }

    &.disabled {
      color: rgba($color-lmh-grey-white, .5);
      pointer-events: none;
    }
  }
  &--dark {
    color: $color-lmh-grey-900;
    @include font('body-m', 'lmh');
    text-decoration: none;

    &:hover {
      color: $color-lmh-brand-500;
      text-decoration: underline;
    }
    &:active {
      color: $color-lmh-grey-700;
    }
    &.cdk-focused {
      &.cdk-keyboard-focused {
        outline: 2px solid $color-lmh-feedback-focused;
      }
    }

    &.disabled {
      color: $color-lmh-grey-300;
      pointer-events: none;
    }
  }
}
