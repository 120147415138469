.mat-mdc-card {
  &.parcel-mat-card {
    background: $color-bao-grey-100;
    @include border('primary-rounded-4', 'primary', $color-bao-grey-100);

    &:hover {
      @include elevation('bao', 'm');
    }

    &:active {
      @include elevation('bao', 's');
    }

    &.cdk-focused:not(.parcel-mat-card--disabled), &:focus:not(.parcel-mat-card--disabled) {
      &.cdk-keyboard-focused {
        border-color: $color-bao-feedback-focused;
      }
    }

    // backgrounds
    &.background-grey {
      border-color: $color-bao-grey-white;
      background: $color-bao-grey-white;
    }

    &.background-dark {
      border-color: $color-bao-grey-white;
      background: $color-bao-grey-white;
      &:hover {
        @include elevation('bao', 'xl');
      }
    }
  }
}
