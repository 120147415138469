.parcel-mat-table {

  // default
  &.mat-mdc-table {
    .mdc-data-table__header-cell {
      @include font('body-s', 'stl');
      color: $color-stl-brand-500;
      background-color: $color-stl-grey-100;
      font-weight: bold;
      border-bottom-width: 0;
    }
    .mdc-data-table__cell {
      @include font('body-s', 'stl');
      color: $color-stl-grey-black;
      background-color: $color-stl-grey-100;
      border-bottom-width: 0;
    }

    .mat-sort-header-arrow {
      color: $color-stl-brand-500;
    }
  }

  // headline divider
  &--header-divider {
    &.mat-mdc-table {
      .mdc-data-table__header-cell {
        border-bottom-width: 1px;
        border-bottom-color: $color-stl-grey-300;
      }
    }
  }

  // horizontal divider
  &--horizontal-divider {
    &.mat-mdc-table {
      .mdc-data-table__cell, .mdc-data-table__header-cell {
        border-bottom-width: 1px;
        border-bottom-color: $color-stl-grey-300;
      }
    }
  }

  // vertical divider
  &--vertical-divider {
    &.mat-mdc-table {
      .mdc-data-table__cell, .mdc-data-table__header-cell {
        &:not(:last-of-type) {
          border-right-width: 1px;
          border-right-style: solid;
          border-right-color: $color-stl-grey-300;
        }
      }
    }
  }

  // alternating background
  &--alternating {
    &.mat-mdc-table {
      tr:nth-child(odd) {
        .mdc-data-table__cell{
          background-color: $color-stl-grey-50;
        }
      }
    }
  }

  // transparent background
  &--transparent {
    // default
    &.mat-mdc-table {
      .mdc-data-table__header-cell {
        @include font('body-s', 'stl');
        color: $color-stl-brand-500;
        background-color: transparent;
        font-weight: bold;
        border-bottom-width: 0;
      }
      .mdc-data-table__cell {
        @include font('body-s', 'stl');
        color: $color-stl-grey-black;
        background-color: transparent;
        border-bottom-width: 0;
      }
    }
  }

  // drag & drop
  &--drag-drop {
    .cdk-drag {
      .mat-sort-header-content {
        .parcel-mat-table--grabber {
          display: inline-block;
          width: 16px;
          height: 17px;
          background: url('data:image/svg+xml;utf8,<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="6.5" y="2.5" width="0.555" height="12" rx="0.2775" fill="%23F96915FF"/><rect x="9.05469" y="1.5" width="0.554999" height="14" rx="0.2775" fill="%23F96915FF"/></svg>');
          margin-left: -5px;
          &:hover {
            cursor: grab;
          }
        }
      }
    }
  }
}

