$no-space: 0;
$space-1: 4px;
$space-2: 8px;
$space-3: 12px;
$space-4: 16px;
$space-5: 24px;
$space-6: 32px;
$space-7: 40px;
$space-8: 48px;
$space-9: 64px;
$space-10: 80px;
$space-11: 100px;
// asd aswdas asd