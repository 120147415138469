$radio-size: $space-4;
$radio-space: $space-1;
$radio-border-width: 1px;
$radio-border-inner: $space-2;

@mixin parcel-mat-radio-button {
  .mat-mdc-radio-button {
    .mdc-radio {
      width: $radio-size;
      height: $radio-size;
      padding: $radio-space;

      &:not(.mdc-radio--disabled) + label {
        &:hover {
          cursor: pointer;
        }
      }

      .mdc-radio__native-control {
        width: $radio-size;
        height: $radio-size;
        top: $radio-space;
        left: $radio-space;
        right: $radio-space;
      }
      .mat-mdc-radio-touch-target {
        width: $space-6;
        height: $space-6;
      }
      .mdc-radio__background {
        width: $radio-size;
        height: $radio-size;

        &:before {
          display: none;
        }
      }

      .mdc-radio__outer-circle {
        border-width: $radio-border-width;
      }
      .mdc-radio__inner-circle {
        border-width: $radio-border-inner;
      }
      .mat-radio-ripple {
        display: none;
      }
    }
  }
}
