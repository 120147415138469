.parcel-mat-progress-spinner {
  &.default {
    &.mat-mdc-progress-spinner circle, .mat-spinner circle {
      stroke: $color-lmh-grey-900;
    }
  }
  &.primary {
    &.mat-mdc-progress-spinner circle, .mat-spinner circle {
      stroke: $color-lmh-brand-500;
    }
  }
  &.warning {
    &.mat-mdc-progress-spinner circle, .mat-spinner circle {
      stroke: $color-lmh-feedback-danger;
    }
  }
  &.accent {
    &.mat-mdc-progress-spinner circle, .mat-spinner circle {
      stroke: $color-lmh-feedback-focused;
    }
  }
}
