@import "mixins/button-toggle.mixin.material";

.parcel-mat-button-toggle {
  @include parcel-mat-button-toggle;
}

@import "themes/button-toggle-kion.material";

.theme-stl {
  @import "themes/button-toggle-stl.material";
}
.theme-lmh, .theme-fwk {
  @import "themes/button-toggle-lmh.material";
}
.theme-bao {
  @import "themes/button-toggle-bao.material";
}
.theme-kion {
  @import "themes/button-toggle-kion.material";
}
