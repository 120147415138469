@mixin parcel-mat-notification-list {
  &.mdc-list {
    padding: 0;
    width: 100%;

    .mat-mdc-list-item {
      height: $space-8;

      .mdc-list-item__content {
        pointer-events: auto;
        align-self: auto;

        .mat-mdc-list-item-unscoped-content {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: flex-start;
          align-items: center;
          min-height: 100%;

          > * {
            flex: 0 0 auto;
          }
        }
      }

      &.empty {
        height: 53px;
      }
    }
  }

  &__item__timestamp {
    min-width: 51px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
    margin-right: $space-2;
  }

  &__item__icon {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;

    > mat-icon {
      padding: $space-1;
      box-sizing: border-box;
      margin-top: -1px;
    }
    > span {
      margin-right: $space-2;
    }
  }
  &__item__text {
    margin-left: $space-2;
    flex: 1 1 auto !important;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
    overflow: hidden;

    > * {
      flex: 1 1 auto;
    }
  }

  &__item__title {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__item__subtitle {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__item__arrow {
    height: $space-5;
    margin-right: $space-1;
  }
  &__item__empty {
    flex: 1 1 auto !important;
    text-align: center;
  }
}
