
/******************************************************
 *                                                    *
 *  ⚠️  ATTENTION: THIS CONTENT IS AUTO-GENERATED  ⚠️ *
 *                  (css-variables-generator.js)              *
 *                                                    *
 *        DO NOT MODIFY ClASS MANUALLY!               *
 ******************************************************/

/* width */

.menu-width {
	width: var(--menu-width);
}

.border-width-primary {
	width: var(--border-width-primary);
}

.border-width-secondary {
	width: var(--border-width-secondary);
}

/* height */

.header-height {
	height: var(--header-height);
}

.header-survey-height {
	height: var(--header-survey-height);
}

.item-height {
	height: var(--item-height);
}

.connection-height {
	height: var(--connection-height);
}

/* font-size */

.icon-size-font {
	font-size: var(--icon-size);
}

/* transition-duration */

.parcel-duration-fast-transition {
	transition-duration: var(--theme-duration-fast);
}

.parcel-duration-standard-transition {
	transition-duration: var(--theme-duration-standard);
}

.parcel-duration-slow-transition {
	transition-duration: var(--theme-duration-slow);
}

/* padding  |  padding-inline | padding-block | padding-bottom | padding-top | padding-right | padding-left | margin | margin-inline | margin-block | margin-bottom | margin-top | margin-right | margin-left */

.no-space-padding {
	padding: var(--no-space);
}

.no-space-padding-inline {
	padding-inline: var(--no-space);
}

.no-space-padding-block {
	padding-block: var(--no-space);
}

.no-space-padding-bottom {
	padding-bottom: var(--no-space);
}

.no-space-padding-top {
	padding-top: var(--no-space);
}

.no-space-padding-right {
	padding-right: var(--no-space);
}

.no-space-padding-left {
	padding-left: var(--no-space);
}

.no-space-margin {
	margin: var(--no-space);
}

.no-space-margin-inline {
	margin-inline: var(--no-space);
}

.no-space-margin-block {
	margin-block: var(--no-space);
}

.no-space-margin-bottom {
	margin-bottom: var(--no-space);
}

.no-space-margin-top {
	margin-top: var(--no-space);
}

.no-space-margin-right {
	margin-right: var(--no-space);
}

.no-space-margin-left {
	margin-left: var(--no-space);
}

.space-1-padding {
	padding: var(--space-1);
}

.space-1-padding-inline {
	padding-inline: var(--space-1);
}

.space-1-padding-block {
	padding-block: var(--space-1);
}

.space-1-padding-bottom {
	padding-bottom: var(--space-1);
}

.space-1-padding-top {
	padding-top: var(--space-1);
}

.space-1-padding-right {
	padding-right: var(--space-1);
}

.space-1-padding-left {
	padding-left: var(--space-1);
}

.space-1-margin {
	margin: var(--space-1);
}

.space-1-margin-inline {
	margin-inline: var(--space-1);
}

.space-1-margin-block {
	margin-block: var(--space-1);
}

.space-1-margin-bottom {
	margin-bottom: var(--space-1);
}

.space-1-margin-top {
	margin-top: var(--space-1);
}

.space-1-margin-right {
	margin-right: var(--space-1);
}

.space-1-margin-left {
	margin-left: var(--space-1);
}

.space-2-padding {
	padding: var(--space-2);
}

.space-2-padding-inline {
	padding-inline: var(--space-2);
}

.space-2-padding-block {
	padding-block: var(--space-2);
}

.space-2-padding-bottom {
	padding-bottom: var(--space-2);
}

.space-2-padding-top {
	padding-top: var(--space-2);
}

.space-2-padding-right {
	padding-right: var(--space-2);
}

.space-2-padding-left {
	padding-left: var(--space-2);
}

.space-2-margin {
	margin: var(--space-2);
}

.space-2-margin-inline {
	margin-inline: var(--space-2);
}

.space-2-margin-block {
	margin-block: var(--space-2);
}

.space-2-margin-bottom {
	margin-bottom: var(--space-2);
}

.space-2-margin-top {
	margin-top: var(--space-2);
}

.space-2-margin-right {
	margin-right: var(--space-2);
}

.space-2-margin-left {
	margin-left: var(--space-2);
}

.space-3-padding {
	padding: var(--space-3);
}

.space-3-padding-inline {
	padding-inline: var(--space-3);
}

.space-3-padding-block {
	padding-block: var(--space-3);
}

.space-3-padding-bottom {
	padding-bottom: var(--space-3);
}

.space-3-padding-top {
	padding-top: var(--space-3);
}

.space-3-padding-right {
	padding-right: var(--space-3);
}

.space-3-padding-left {
	padding-left: var(--space-3);
}

.space-3-margin {
	margin: var(--space-3);
}

.space-3-margin-inline {
	margin-inline: var(--space-3);
}

.space-3-margin-block {
	margin-block: var(--space-3);
}

.space-3-margin-bottom {
	margin-bottom: var(--space-3);
}

.space-3-margin-top {
	margin-top: var(--space-3);
}

.space-3-margin-right {
	margin-right: var(--space-3);
}

.space-3-margin-left {
	margin-left: var(--space-3);
}

.space-4-padding {
	padding: var(--space-4);
}

.space-4-padding-inline {
	padding-inline: var(--space-4);
}

.space-4-padding-block {
	padding-block: var(--space-4);
}

.space-4-padding-bottom {
	padding-bottom: var(--space-4);
}

.space-4-padding-top {
	padding-top: var(--space-4);
}

.space-4-padding-right {
	padding-right: var(--space-4);
}

.space-4-padding-left {
	padding-left: var(--space-4);
}

.space-4-margin {
	margin: var(--space-4);
}

.space-4-margin-inline {
	margin-inline: var(--space-4);
}

.space-4-margin-block {
	margin-block: var(--space-4);
}

.space-4-margin-bottom {
	margin-bottom: var(--space-4);
}

.space-4-margin-top {
	margin-top: var(--space-4);
}

.space-4-margin-right {
	margin-right: var(--space-4);
}

.space-4-margin-left {
	margin-left: var(--space-4);
}

.space-5-padding {
	padding: var(--space-5);
}

.space-5-padding-inline {
	padding-inline: var(--space-5);
}

.space-5-padding-block {
	padding-block: var(--space-5);
}

.space-5-padding-bottom {
	padding-bottom: var(--space-5);
}

.space-5-padding-top {
	padding-top: var(--space-5);
}

.space-5-padding-right {
	padding-right: var(--space-5);
}

.space-5-padding-left {
	padding-left: var(--space-5);
}

.space-5-margin {
	margin: var(--space-5);
}

.space-5-margin-inline {
	margin-inline: var(--space-5);
}

.space-5-margin-block {
	margin-block: var(--space-5);
}

.space-5-margin-bottom {
	margin-bottom: var(--space-5);
}

.space-5-margin-top {
	margin-top: var(--space-5);
}

.space-5-margin-right {
	margin-right: var(--space-5);
}

.space-5-margin-left {
	margin-left: var(--space-5);
}

.space-6-padding {
	padding: var(--space-6);
}

.space-6-padding-inline {
	padding-inline: var(--space-6);
}

.space-6-padding-block {
	padding-block: var(--space-6);
}

.space-6-padding-bottom {
	padding-bottom: var(--space-6);
}

.space-6-padding-top {
	padding-top: var(--space-6);
}

.space-6-padding-right {
	padding-right: var(--space-6);
}

.space-6-padding-left {
	padding-left: var(--space-6);
}

.space-6-margin {
	margin: var(--space-6);
}

.space-6-margin-inline {
	margin-inline: var(--space-6);
}

.space-6-margin-block {
	margin-block: var(--space-6);
}

.space-6-margin-bottom {
	margin-bottom: var(--space-6);
}

.space-6-margin-top {
	margin-top: var(--space-6);
}

.space-6-margin-right {
	margin-right: var(--space-6);
}

.space-6-margin-left {
	margin-left: var(--space-6);
}

.space-7-padding {
	padding: var(--space-7);
}

.space-7-padding-inline {
	padding-inline: var(--space-7);
}

.space-7-padding-block {
	padding-block: var(--space-7);
}

.space-7-padding-bottom {
	padding-bottom: var(--space-7);
}

.space-7-padding-top {
	padding-top: var(--space-7);
}

.space-7-padding-right {
	padding-right: var(--space-7);
}

.space-7-padding-left {
	padding-left: var(--space-7);
}

.space-7-margin {
	margin: var(--space-7);
}

.space-7-margin-inline {
	margin-inline: var(--space-7);
}

.space-7-margin-block {
	margin-block: var(--space-7);
}

.space-7-margin-bottom {
	margin-bottom: var(--space-7);
}

.space-7-margin-top {
	margin-top: var(--space-7);
}

.space-7-margin-right {
	margin-right: var(--space-7);
}

.space-7-margin-left {
	margin-left: var(--space-7);
}

.space-8-padding {
	padding: var(--space-8);
}

.space-8-padding-inline {
	padding-inline: var(--space-8);
}

.space-8-padding-block {
	padding-block: var(--space-8);
}

.space-8-padding-bottom {
	padding-bottom: var(--space-8);
}

.space-8-padding-top {
	padding-top: var(--space-8);
}

.space-8-padding-right {
	padding-right: var(--space-8);
}

.space-8-padding-left {
	padding-left: var(--space-8);
}

.space-8-margin {
	margin: var(--space-8);
}

.space-8-margin-inline {
	margin-inline: var(--space-8);
}

.space-8-margin-block {
	margin-block: var(--space-8);
}

.space-8-margin-bottom {
	margin-bottom: var(--space-8);
}

.space-8-margin-top {
	margin-top: var(--space-8);
}

.space-8-margin-right {
	margin-right: var(--space-8);
}

.space-8-margin-left {
	margin-left: var(--space-8);
}

.space-9-padding {
	padding: var(--space-9);
}

.space-9-padding-inline {
	padding-inline: var(--space-9);
}

.space-9-padding-block {
	padding-block: var(--space-9);
}

.space-9-padding-bottom {
	padding-bottom: var(--space-9);
}

.space-9-padding-top {
	padding-top: var(--space-9);
}

.space-9-padding-right {
	padding-right: var(--space-9);
}

.space-9-padding-left {
	padding-left: var(--space-9);
}

.space-9-margin {
	margin: var(--space-9);
}

.space-9-margin-inline {
	margin-inline: var(--space-9);
}

.space-9-margin-block {
	margin-block: var(--space-9);
}

.space-9-margin-bottom {
	margin-bottom: var(--space-9);
}

.space-9-margin-top {
	margin-top: var(--space-9);
}

.space-9-margin-right {
	margin-right: var(--space-9);
}

.space-9-margin-left {
	margin-left: var(--space-9);
}

.space-10-padding {
	padding: var(--space-10);
}

.space-10-padding-inline {
	padding-inline: var(--space-10);
}

.space-10-padding-block {
	padding-block: var(--space-10);
}

.space-10-padding-bottom {
	padding-bottom: var(--space-10);
}

.space-10-padding-top {
	padding-top: var(--space-10);
}

.space-10-padding-right {
	padding-right: var(--space-10);
}

.space-10-padding-left {
	padding-left: var(--space-10);
}

.space-10-margin {
	margin: var(--space-10);
}

.space-10-margin-inline {
	margin-inline: var(--space-10);
}

.space-10-margin-block {
	margin-block: var(--space-10);
}

.space-10-margin-bottom {
	margin-bottom: var(--space-10);
}

.space-10-margin-top {
	margin-top: var(--space-10);
}

.space-10-margin-right {
	margin-right: var(--space-10);
}

.space-10-margin-left {
	margin-left: var(--space-10);
}

.space-11-padding {
	padding: var(--space-11);
}

.space-11-padding-inline {
	padding-inline: var(--space-11);
}

.space-11-padding-block {
	padding-block: var(--space-11);
}

.space-11-padding-bottom {
	padding-bottom: var(--space-11);
}

.space-11-padding-top {
	padding-top: var(--space-11);
}

.space-11-padding-right {
	padding-right: var(--space-11);
}

.space-11-padding-left {
	padding-left: var(--space-11);
}

.space-11-margin {
	margin: var(--space-11);
}

.space-11-margin-inline {
	margin-inline: var(--space-11);
}

.space-11-margin-block {
	margin-block: var(--space-11);
}

.space-11-margin-bottom {
	margin-bottom: var(--space-11);
}

.space-11-margin-top {
	margin-top: var(--space-11);
}

.space-11-margin-right {
	margin-right: var(--space-11);
}

.space-11-margin-left {
	margin-left: var(--space-11);
}

.page-margin-padding {
	padding: var(--page-margin);
}

.page-margin-padding-inline {
	padding-inline: var(--page-margin);
}

.page-margin-padding-block {
	padding-block: var(--page-margin);
}

.page-margin-padding-bottom {
	padding-bottom: var(--page-margin);
}

.page-margin-padding-top {
	padding-top: var(--page-margin);
}

.page-margin-padding-right {
	padding-right: var(--page-margin);
}

.page-margin-padding-left {
	padding-left: var(--page-margin);
}

.page-margin {
	margin: var(--page-margin);
}

.page-margin-inline {
	margin-inline: var(--page-margin);
}

.page-margin-block {
	margin-block: var(--page-margin);
}

.page-margin-bottom {
	margin-bottom: var(--page-margin);
}

.page-margin-top {
	margin-top: var(--page-margin);
}

.page-margin-right {
	margin-right: var(--page-margin);
}

.page-margin-left {
	margin-left: var(--page-margin);
}

.page-margin-sm-padding {
	padding: var(--page-margin-sm);
}

.page-margin-sm-padding-inline {
	padding-inline: var(--page-margin-sm);
}

.page-margin-sm-padding-block {
	padding-block: var(--page-margin-sm);
}

.page-margin-sm-padding-bottom {
	padding-bottom: var(--page-margin-sm);
}

.page-margin-sm-padding-top {
	padding-top: var(--page-margin-sm);
}

.page-margin-sm-padding-right {
	padding-right: var(--page-margin-sm);
}

.page-margin-sm-padding-left {
	padding-left: var(--page-margin-sm);
}

.page-margin-sm {
	margin: var(--page-margin-sm);
}

.page-margin-sm-inline {
	margin-inline: var(--page-margin-sm);
}

.page-margin-sm-block {
	margin-block: var(--page-margin-sm);
}

.page-margin-sm-bottom {
	margin-bottom: var(--page-margin-sm);
}

.page-margin-sm-top {
	margin-top: var(--page-margin-sm);
}

.page-margin-sm-right {
	margin-right: var(--page-margin-sm);
}

.page-margin-sm-left {
	margin-left: var(--page-margin-sm);
}

.container-padding {
	padding: var(--container-padding);
}

.container-padding-inline {
	padding-inline: var(--container-padding);
}

.container-padding-block {
	padding-block: var(--container-padding);
}

.container-padding-bottom {
	padding-bottom: var(--container-padding);
}

.container-padding-top {
	padding-top: var(--container-padding);
}

.container-padding-right {
	padding-right: var(--container-padding);
}

.container-padding-left {
	padding-left: var(--container-padding);
}

.container-padding-margin {
	margin: var(--container-padding);
}

.container-padding-margin-inline {
	margin-inline: var(--container-padding);
}

.container-padding-margin-block {
	margin-block: var(--container-padding);
}

.container-padding-margin-bottom {
	margin-bottom: var(--container-padding);
}

.container-padding-margin-top {
	margin-top: var(--container-padding);
}

.container-padding-margin-right {
	margin-right: var(--container-padding);
}

.container-padding-margin-left {
	margin-left: var(--container-padding);
}

.container-padding-sm {
	padding: var(--container-padding-sm);
}

.container-padding-sm-inline {
	padding-inline: var(--container-padding-sm);
}

.container-padding-sm-block {
	padding-block: var(--container-padding-sm);
}

.container-padding-sm-bottom {
	padding-bottom: var(--container-padding-sm);
}

.container-padding-sm-top {
	padding-top: var(--container-padding-sm);
}

.container-padding-sm-right {
	padding-right: var(--container-padding-sm);
}

.container-padding-sm-left {
	padding-left: var(--container-padding-sm);
}

.container-padding-sm-margin {
	margin: var(--container-padding-sm);
}

.container-padding-sm-margin-inline {
	margin-inline: var(--container-padding-sm);
}

.container-padding-sm-margin-block {
	margin-block: var(--container-padding-sm);
}

.container-padding-sm-margin-bottom {
	margin-bottom: var(--container-padding-sm);
}

.container-padding-sm-margin-top {
	margin-top: var(--container-padding-sm);
}

.container-padding-sm-margin-right {
	margin-right: var(--container-padding-sm);
}

.container-padding-sm-margin-left {
	margin-left: var(--container-padding-sm);
}

.item-margin-padding {
	padding: var(--item-margin);
}

.item-margin-padding-inline {
	padding-inline: var(--item-margin);
}

.item-margin-padding-block {
	padding-block: var(--item-margin);
}

.item-margin-padding-bottom {
	padding-bottom: var(--item-margin);
}

.item-margin-padding-top {
	padding-top: var(--item-margin);
}

.item-margin-padding-right {
	padding-right: var(--item-margin);
}

.item-margin-padding-left {
	padding-left: var(--item-margin);
}

.item-margin {
	margin: var(--item-margin);
}

.item-margin-inline {
	margin-inline: var(--item-margin);
}

.item-margin-block {
	margin-block: var(--item-margin);
}

.item-margin-bottom {
	margin-bottom: var(--item-margin);
}

.item-margin-top {
	margin-top: var(--item-margin);
}

.item-margin-right {
	margin-right: var(--item-margin);
}

.item-margin-left {
	margin-left: var(--item-margin);
}

.item-margin-sm-padding {
	padding: var(--item-margin-sm);
}

.item-margin-sm-padding-inline {
	padding-inline: var(--item-margin-sm);
}

.item-margin-sm-padding-block {
	padding-block: var(--item-margin-sm);
}

.item-margin-sm-padding-bottom {
	padding-bottom: var(--item-margin-sm);
}

.item-margin-sm-padding-top {
	padding-top: var(--item-margin-sm);
}

.item-margin-sm-padding-right {
	padding-right: var(--item-margin-sm);
}

.item-margin-sm-padding-left {
	padding-left: var(--item-margin-sm);
}

.item-margin-sm {
	margin: var(--item-margin-sm);
}

.item-margin-sm-inline {
	margin-inline: var(--item-margin-sm);
}

.item-margin-sm-block {
	margin-block: var(--item-margin-sm);
}

.item-margin-sm-bottom {
	margin-bottom: var(--item-margin-sm);
}

.item-margin-sm-top {
	margin-top: var(--item-margin-sm);
}

.item-margin-sm-right {
	margin-right: var(--item-margin-sm);
}

.item-margin-sm-left {
	margin-left: var(--item-margin-sm);
}

/* padding-inline  |  padding-block | padding-bottom | padding-top | padding-right | padding-left | margin-inline | margin-block | margin-bottom | margin-top | margin-right | margin-left */

.safe-area-top-padding-inline {
	padding-inline: var(--safe-area-top);
}

.safe-area-top-padding-block {
	padding-block: var(--safe-area-top);
}

.safe-area-top-padding-bottom {
	padding-bottom: var(--safe-area-top);
}

.safe-area-top-padding {
	padding-top: var(--safe-area-top);
}

.safe-area-top-padding-right {
	padding-right: var(--safe-area-top);
}

.safe-area-top-padding-left {
	padding-left: var(--safe-area-top);
}

.safe-area-top-margin-inline {
	margin-inline: var(--safe-area-top);
}

.safe-area-top-margin-block {
	margin-block: var(--safe-area-top);
}

.safe-area-top-margin-bottom {
	margin-bottom: var(--safe-area-top);
}

.safe-area-top-margin {
	margin-top: var(--safe-area-top);
}

.safe-area-top-margin-right {
	margin-right: var(--safe-area-top);
}

.safe-area-top-margin-left {
	margin-left: var(--safe-area-top);
}

.safe-area-bottom-padding-inline {
	padding-inline: var(--safe-area-bottom);
}

.safe-area-bottom-padding-block {
	padding-block: var(--safe-area-bottom);
}

.safe-area-bottom-padding {
	padding-bottom: var(--safe-area-bottom);
}

.safe-area-bottom-padding-top {
	padding-top: var(--safe-area-bottom);
}

.safe-area-bottom-padding-right {
	padding-right: var(--safe-area-bottom);
}

.safe-area-bottom-padding-left {
	padding-left: var(--safe-area-bottom);
}

.safe-area-bottom-margin-inline {
	margin-inline: var(--safe-area-bottom);
}

.safe-area-bottom-margin-block {
	margin-block: var(--safe-area-bottom);
}

.safe-area-bottom-margin {
	margin-bottom: var(--safe-area-bottom);
}

.safe-area-bottom-margin-top {
	margin-top: var(--safe-area-bottom);
}

.safe-area-bottom-margin-right {
	margin-right: var(--safe-area-bottom);
}

.safe-area-bottom-margin-left {
	margin-left: var(--safe-area-bottom);
}

.safe-area-left-padding-inline {
	padding-inline: var(--safe-area-left);
}

.safe-area-left-padding-block {
	padding-block: var(--safe-area-left);
}

.safe-area-left-padding-bottom {
	padding-bottom: var(--safe-area-left);
}

.safe-area-left-padding-top {
	padding-top: var(--safe-area-left);
}

.safe-area-left-padding-right {
	padding-right: var(--safe-area-left);
}

.safe-area-left-padding {
	padding-left: var(--safe-area-left);
}

.safe-area-left-margin-inline {
	margin-inline: var(--safe-area-left);
}

.safe-area-left-margin-block {
	margin-block: var(--safe-area-left);
}

.safe-area-left-margin-bottom {
	margin-bottom: var(--safe-area-left);
}

.safe-area-left-margin-top {
	margin-top: var(--safe-area-left);
}

.safe-area-left-margin-right {
	margin-right: var(--safe-area-left);
}

.safe-area-left-margin {
	margin-left: var(--safe-area-left);
}

.safe-area-right-padding-inline {
	padding-inline: var(--safe-area-right);
}

.safe-area-right-padding-block {
	padding-block: var(--safe-area-right);
}

.safe-area-right-padding-bottom {
	padding-bottom: var(--safe-area-right);
}

.safe-area-right-padding-top {
	padding-top: var(--safe-area-right);
}

.safe-area-right-padding {
	padding-right: var(--safe-area-right);
}

.safe-area-right-padding-left {
	padding-left: var(--safe-area-right);
}

.safe-area-right-margin-inline {
	margin-inline: var(--safe-area-right);
}

.safe-area-right-margin-block {
	margin-block: var(--safe-area-right);
}

.safe-area-right-margin-bottom {
	margin-bottom: var(--safe-area-right);
}

.safe-area-right-margin-top {
	margin-top: var(--safe-area-right);
}

.safe-area-right-margin {
	margin-right: var(--safe-area-right);
}

.safe-area-right-margin-left {
	margin-left: var(--safe-area-right);
}

/* color */

.divider-color {
	color: var(--divider-color);
}

.divider-color-soft {
	color: var(--divider-color-soft);
}

.divider-color-strong {
	color: var(--divider-color-strong);
}

/* border-radius */

.border-primary-rounded-radius {
	border-radius: var(--border-primary-rounded);
}

.border-primary-rounded-0-radius {
	border-radius: var(--border-primary-rounded-0);
}

.border-primary-rounded-2-5-radius {
	border-radius: var(--border-primary-rounded-2-5);
}

.border-primary-rounded-4-radius {
	border-radius: var(--border-primary-rounded-4);
}

.border-primary-rounded-40-radius {
	border-radius: var(--border-primary-rounded-40);
}

/* font-family */

.font-family {
	font-family: var(--font-family);
}

/* background-color */

.background-primary-color {
	background-color: var(--background-primary);
}

.background-secondary-color {
	background-color: var(--background-secondary);
}

.background-tertiary-color {
	background-color: var(--background-tertiary);
}

.background-quaternary-color {
	background-color: var(--background-quaternary);
}

.background-modal-color {
	background-color: var(--background-modal);
}

/* color */

.color-500 {
	color: var(--color-primary);
}

.color-700 {
	color: var(--color-primary);
}

.error-color {
	color: var(--error-color);
}

.warning-color {
	color: var(--warning-color);
}

.success-color {
	color: var(--success-color);
}

.design-1-color {
	color: var(--color-link);
}

.design-2-color {
	color: var(--color-secondary);
}

.design-3-color {
	color: var(--color-primary-light);
}

.design-4-color {
	color: var(--color-text-primary);
}

.design-5-color {
	color: var(--color-text-secondary);
}

.promotion-info-color {
	color: var(--promotion-info);
}

/* color */

.color-grey-50 {
	color: var(--color-grey-50);
}

.color-grey-100 {
	color: var(--color-grey-100);
}

.color-grey-200 {
	color: var(--color-grey-200);
}

.color-grey-300 {
	color: var(--color-grey-300);
}

.color-grey-400 {
	color: var(--color-grey-400);
}

.color-grey-600 {
	color: var(--color-grey-600);
}

.color-grey-700 {
	color: var(--color-grey-700);
}

.color-grey-900 {
	color: var(--color-grey-900);
}

.color-grey-black {
	color: var(--color-grey-black);
}

.color-grey-white {
	color: var(--color-grey-white);
}