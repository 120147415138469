.parcel-mat-option {
  &.mat-mdc-option {
    @include font('body-s', 'bao');
    color: $color-bao-grey-900;
    min-height: $space-8;
    padding: 0 $space-4;

    &:hover, &:focus, &.mat-mdc-option-active, &.mdc-list-item--selected:not(.mat-mdc-option-multiple) {
      &:not(.mdc-list-item--disabled) {
        color: $color-bao-grey-900;
        background-color: $color-bao-grey-100;
      }
    }

    .mdc-list-item__primary-text {
      color: $color-bao-grey-900 !important;
    }
  }
}
