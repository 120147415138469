.parcel-mat-slider {
  &.mat-mdc-slider {
    .mdc-slider__track {
      // track right
      .mdc-slider__track--inactive {
        background: $color-stl-grey-300;
      }

      // track left
      .mdc-slider__track--active_fill {
        border-color: $color-stl-brand-500;
      }
    }

    // knob
    .mdc-slider__thumb {
      .mdc-slider__thumb-knob {
        background-color: $color-stl-brand-500;
        border-color: $color-stl-brand-500;
      }
    }

    // tooltip
    .mdc-slider__value-indicator {
      background: $color-stl-grey-900;
      color: $color-stl-grey-white;

      &:before {
        border-top-color: $color-stl-grey-900;
      }

      &-text {
        @include font('body-s', 'stl')
      }
    }

    // ticks
    .mdc-slider__tick-mark--active {
      background-color: $color-stl-brand-700;

    }
    .mdc-slider__tick-mark--inactive {
      background-color: $color-stl-grey-400;

    }

    // disabled
    &.mdc-slider--disabled {

      // knob
      .mdc-slider__thumb {
        .mdc-slider__thumb-knob {
          background-color: $color-stl-grey-400;
          border-color: $color-stl-grey-400;
        }
      }

      // track
      .mdc-slider__track {
        .mdc-slider__track--inactive {
          background: $color-stl-grey-300;
        }

        .mdc-slider__track--active_fill {
          border-color: $color-stl-grey-400;
        }
      }

      // ticks
      .mdc-slider__tick-mark--active {
        background-color: $color-stl-grey-600;
        opacity: 1;
      }
      .mdc-slider__tick-mark--inactive {
        background-color: $color-stl-grey-400;
        opacity: 1;
      }
    }
  }
}
