@mixin parcel-mat-slide-toggle {
  --mat-switch-with-icon-handle-size: 14px;
  --mat-switch-unselected-handle-size: 14px;
  --mat-switch-pressed-handle-size: 14px;

  &--wrapper {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  &__on {
    margin-left: $space-2;
  }
  &__off {
    margin-right: $space-2;
  }
  &__on, &__off {
    &.active {
    }

    &:hover {
      cursor: pointer;
    }
  }

  .mdc-switch {
    --mdc-switch-handle-width: 14px;
    --mdc-switch-handle-height: 14px;
    --mdc-switch-handle-shape: #{$space-2};
    --mdc-switch-track-height: #{$space-4};
    --mdc-switch-state-layer-size: #{$space-8};
    --mdc-switch-track-shape: #{$space-2};
    --mdc-switch-track-width: 36px;
  }

  &.mat-mdc-slide-toggle,
  &.mat-mdc-slide-toggle.mat-primary,
  &.mat-mdc-slide-toggle.mat-accent,
  &.mat-mdc-slide-toggle.mat-warn {
    width: 36px;
  }

  &.mat-mdc-slide-toggle .mdc-switch--disabled + label {
    color: rgba(0, 0, 0, .38)
  }

  .mdc-switch__icons, .mdc-switch__ripple, .mdc-switch__shadow {
    display: none;
  }

  .mdc-switch__handle {
    //left: 1px;
  }

  .mdc-switch__handle-track {
    transform: translateX(1px);
  }
  .mdc-switch--selected .mdc-switch__handle-track {
    transform: translateX(calc(100% - 1px));
  }

  &.parcel-mat-slide-toggle--label-left {
    > .mdc-form-field {
      flex-direction: row-reverse;

      .mdc-label {
        white-space: nowrap;
      }
    }
  }

  &.parcel-mat-slide-toggle--state-intermediate {
    > .mdc-form-field {
      flex-direction: row-reverse;

      .mdc-switch__handle-track {
        width: 100%;
        left: 0;
        transform: translate(0);
      }

      .mdc-switch__handle {
        width: $space-3;
        height: 3px;
        border-radius: 0;

        &:before {
          display: none;
        }

        &:after {
          left: calc(50% + #{$space-1});
          border-radius: 50%;
        }
      }

      .mdc-label {
        white-space: nowrap;
      }
    }
  }
}
