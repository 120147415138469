@mixin parcel-mat-badge {
  &.mat-badge {
    &-small {
      .mat-badge-content {
        width: auto;
        max-width: $space-7;
        min-width: $space-4 - 4px;
        height: $space-4;
        line-height: $space-4 !important;
        border-radius: $space-4;
        padding: 0 2px;
      }
    }
    &-medium {
      .mat-badge-content {
        width: auto;
        max-width: $space-9;
        min-width: $space-5 - 4px;
        height: $space-5;
        line-height: $space-5 !important;
        border-radius: $space-5;
        padding: 0 2px;
      }
    }
    &-large {
      .mat-badge-content {
        width: auto;
        max-width: $space-10;
        min-width: $space-6 - 4px;
        height: $space-6;
        line-height: $space-6 !important;
        border-radius: $space-6;
        padding: 0 2px;
      }
    }
  }
}
