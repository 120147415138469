@mixin parcel-notification-widget {
  width: 100%;
  display: flex;
  position: relative;

  &__footer {
    display: flex;
    height: $space-8;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    padding: 0 8px;

    &--left {
      flex: 1 1 auto;
      text-align: left;
    }

    &--right {
      flex: 1 1 auto;
      text-align: right;
    }
  }
}
