@mixin parcel-mat-paginator {
  &.parcel-mat-paginator {
    .mat-mdc-paginator-outer-container {
      height: 36px;

      .mat-mdc-paginator-container {
        min-height: 36px;
        flex-wrap: nowrap;

        .mat-mdc-paginator-page-size {
          //background: red;
          align-items: center;

          .mat-mdc-text-field-wrapper {
            max-height: 32px;
            padding: 0;

            .mdc-notched-outline {
              > * {
                border: none;
              }
            }

            &.mdc-text-field--focused {
              .mat-mdc-form-field-flex {
                background: red;
                border-radius: 5px;
              }
            }
          }
        }

        .mat-mdc-paginator-range-actions {
          //background: yellow;

          .mdc-icon-button__ripple {
            display: none;
          }

          > button {
            width: 32px !important;
            height: 32px !important;
            padding: 4px !important;
          }
        }
      }
    }

    .mat-mdc-form-field-infix {
      padding-top: $space-1 !important;
      min-height: 32px;
      height: 32px;

      .mat-mdc-select-arrow-wrapper {
        display: none;
      }
    }

    .mat-mdc-paginator-page-size-select {
      width: 70px;
    }

    .mat-mdc-select-value {
      margin: $no-space $space-2;
    }

    .mat-mdc-paginator-range-label {
      margin-left: 0;
    }
  }
}

.mdc-list-item--selected .mdc-list-item__primary-text,
.mdc-list-item--activated .mdc-list-item__primary-text {
  color: deeppink;
}

.mat-mdc-option:hover:not(.mdc-list-item--disabled),
.mat-mdc-option:focus:not(.mdc-list-item--disabled),
.mat-mdc-option.mat-mdc-option-active,
.mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
  background: yellow;
}

.mdc-list-item__primary-text {
  color: firebrick;
}
