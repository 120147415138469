
/* MATERIAL ICONS */
@import '@material-design-icons/font/filled.css';
@import '@material-design-icons/font/outlined.css';

/* CUSTOM */
@import '../styles/root-variables.scss';
@import '../styles/loading-skeleton';
@import '../styles/safe-area-inset';
@import '../styles/instant-class';
@import '../styles/variable-classes';
@import '../styles/angular-materials';

/* TAILWIND */
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	--lmh-grey-900: #22222;
	--lmh-grey-600: #6A6B6E
}

* {
	box-sizing: border-box;
	font-family: var(--font-family), serif;
}

body {
	font-family: Roboto, "Helvetica Neue", sans-serif;
	margin: 0;
	padding: 0;

	height: 100dvh; /* new browsers */
	overflow: hidden;
	//background: var(--color-primary);
	font-size: var(--font-body-s-font-size);
	color: var(--color-grey-black);
	color-scheme: light;
	position: relative;
	-webkit-font-smoothing: antialiased;

	&.menu-opened {
		overflow: hidden;

		&:before {
			cursor: pointer;
			background-color: var(--background-modal);
			z-index: 99999;
		}
	}

	.wrapper h2{
		margin: 0!important;
	}

	.side-content h2{
		margin: 0!important;
	}
}


.dialog-header {
	font-size: var(--font-title-m-font-size);
	color: var(--color-text-primary);
	font-weight: 700;
	margin-bottom: 24px;
	font-style: normal;
	line-height: normal;
}

.text-header {
	font-size: var(--font-title-m-font-size);
	color: var(--color-text-primary);
	font-weight: 700;
	margin-bottom: 10px;
	margin-top: 10px;
	font-style: normal;
	line-height: normal;
}

.text-sub-header {
	font-size: var(--font-title-s-font-size);
	color: var(--color-text-primary);
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.text-main-header {
	font-size: var(--font-title-xl-font-size);
	color: var(--color-text-primary);
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.text-main-sub-header {
	color: var(--color-text-primary);
	font-size: 32px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.default-padding {
	padding: var(--page-margin) var(--page-margin);
}

.default-padding-x {
	padding: 0 var(--page-margin);
}

.default-padding-y {
	padding: var(--page-margin) 0;
}

.default-padding-top {
	padding-top: var(--page-margin);
}

.default-padding-bottom {
	padding-bottom: var(--page-margin);
}

.default-padding-left {
	padding-left: var(--page-margin);
}

.default-padding-right {
	padding-right: var(--page-margin);
}


.theme-yellow{
	/* Change the white to any color */
	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active{
		-webkit-box-shadow: 0 0 0 30px var(--color-link-25) inset !important;
	}
}
.theme-white{
	/* Change the white to any color */
	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active{
		-webkit-box-shadow: 0 0 0 30px var(--color-link-25-grey) inset !important;
	}
}
.mat-horizontal-content-container {
	padding: 0!important;
}

span{
	font-size: var(--font-body-m-font-size);
}

/* width */
::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 2px grey;
	border-radius: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: var(--color-text-primary);
	border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: var(--color-text-primary);
}

.icon-extra-large {
	width: 180px!important;
	height: 180px!important;
	margin: 0;
	padding: 0;
	font-size: 180px;
	color: rgba(152, 152, 152, 0.20);
}

.mat-mdc-header-cell{
	border-bottom-color: var(--color-black)!important;
}

.right-align {
	text-align: right;
}

input.right-align::-webkit-outer-spin-button,
input.right-align::-webkit-inner-spin-button {
	display: none;
}

input.right-align {
	-moz-appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button {
	-webkit-appearance: none;
}

.toast-container{
	z-index:1000001;
}

.green-progress-bar .mdc-linear-progress__bar-inner{
	border-color: var(--success-color)!important;
}

.mdc-linear-progress__buffer-bar{
	background-color: var(--color-text-primary)!important;
}

.mdc-linear-progress__buffer-dots{
	background-color:  var(--color-text-primary)!important;
}


.body-text-bold {
	font-size: var(--font-body-m-font-size);
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.body-text-regular {
	font-size: var(--font-body-m-font-size);
	font-style: normal;
	line-height: normal;
}

.break-text {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}


.mat-mdc-row {
	cursor: pointer;
	background: transparent;
}

.mat-row-icon{
	color: var(--color-link);
}

.mat-mdc-row:hover {
	background-color: var(--color-link);
	color: var(--color-white);

	.mat-mdc-cell{
		.mat-icon {
			color: var(--color-white);
		}

	}

}

.timepicker__header{
	background-color: var(--color-link)!important;
}

.timepicker-button{
	color:var(--color-link)!important;
}

.clock-face__number > span.active{
	background-color: var(--color-link)!important;
}

.clock-face__clock-hand{
	background-color: var(--color-link)!important;
}

.clock-face__clock-hand_minute:before{
	background-color: var(--color-link)!important;
	border-color: var(--color-link)!important;
}

.timepicker{
	width:400px!important;
}

.title-s {
	font-size: 24px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%; /* 36px */
	color: var(--lmh-grey-900);
}

.title-m-bold{
	font-size: 32px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%; /* 48px */
	color: var(--lmh-grey-900);
}

.body-s {
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%; /* 21px */
	color: var(--lmh-grey-900);
}

.body-s-light {
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%; /* 21px */
	color: var(--lmh-grey-600);
}