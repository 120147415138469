.mat-badge {
  .mat-badge-content {
    background-color: $color-stl-brand-500;
    color: $color-stl-grey-white;
  }

  &-small {
    .mat-badge-content {
      @include font('body-xxs', 'stl');
    }
  }
  &-medium {
    .mat-badge-content {
      @include font('body-xs', 'stl');
    }
  }
  &-large {
    .mat-badge-content {
      @include font('body-m', 'stl');
    }
  }
}
