.parcel-notification-widget {
  //@include elevation('bao', 'l');
  border-radius: $border-primary-rounded-4;
  flex-direction: column-reverse;

  &__content {
    padding: 0;
    background: $color-bao-grey-white;
  }

  &__footer {
    background: $color-bao-grey-100;

    &--left, &--right {
      > .parcel-mat-button.type--transparent {
        font-weight: bold;
      }
    }

    &--right {
    }
  }
}
