/******************************************************
 *              ⚠️      COMMENTS      ⚠️              *
 *                                                    *
 *   typed: *name* -> generates typed interface,      *
 					  with given name, for variable   *
 *   *class-property* -> (e.g. text-color) helper to  *
 					   generate class property        *
 *   skip -> skip checking                            *
 ******************************************************/
:root {
	/* width */
	--menu-width: 200px;
	--border-width-primary: 1px;
	--border-width-secondary: 2px;
	/**/

	/* height */
	--header-height: 64px;
	--header-survey-height: 84px;
	--item-height: 64px;
	--connection-height: 40px;
	/**/

	/* typed: font-size */
	/* font-size */
	--icon-size: 24px;
	/**/

	/* transition-duration */
	--theme-duration-fast: 70ms;
	--theme-duration-standard: 150ms;
	--theme-duration-slow: 400ms;
	/**/

	/* padding | padding-inline | padding-block | padding-bottom | padding-top | padding-right | padding-left | margin | margin-inline | margin-block | margin-bottom | margin-top | margin-right | margin-left */
	--no-space: 0;
	--space-1: 4px;
	--space-2: 8px;
	--space-3: 12px;
	--space-4: 16px;
	--space-5: 24px;
	--space-6: 32px;
	--space-7: 40px;
	--space-8: 48px;
	--space-9: 64px;
	--space-10: 80px;
	--space-11: 100px;
	--page-margin: var(--space-5);
	--page-margin-sm: var(--space-4);
	--container-padding: var(--space-4);
	--container-padding-sm: var(--space-2);
	--item-margin: var(--space-3);
	--item-margin-sm: var(--space-1);
	/**/

	/* padding-inline | padding-block | padding-bottom | padding-top | padding-right | padding-left | margin-inline | margin-block | margin-bottom | margin-top | margin-right | margin-left */
	--safe-area-top: 0px;
	--safe-area-bottom: 0px;
	--safe-area-left: 0px;
	--safe-area-right: 0px;
	/**/

	/* color */
	--divider-color: var(--color-grey-300);
	--divider-color-soft: var(--color-grey-200);
	--divider-color-strong: var(--color-grey-400);
	/**/

	/* border-radius */
	--border-primary-rounded: 3px;
	--border-primary-rounded-0: 0;
	--border-primary-rounded-2-5: 2.5px;
	--border-primary-rounded-4: 4px;
	--border-primary-rounded-40: 40px;
	/**/

	/* skip */
	--loading-background: rgba(0, 0, 0, 0.04);
	--loading-background-strong: rgba(0, 0, 0, 0.08);
	--mat-table-background-color: rgba(255, 255, 255, 1);
	--mat-table-accent-background-color: #f8f8f8;
	/**/

	/* font-family */
	--font-family: 'Arial';
	/**/

	/* skip */
	--font-title-xxl-font-size: 54px;
	--font-title-xl-font-size: 48px;
	--font-title-l-font-size: 40px;
	--font-title-m-font-size: 32px;
	--font-title-s-font-size: 24px;
	--font-title-xs-font-size: 21px;
	--font-body-l-font-size: 18px;
	--font-body-m-font-size: 16px;
	--font-body-s-font-size: 14px;
	--font-body-xs-font-size: 13px;
	--font-body-xxs-font-size: 10px;
	/**/

	/* typed: background-color */
	/* background-color */
	--background-primary: #ffffff;
	--background-secondary: #f9f9f9;
	--background-tertiary: #eeeff3;
	--background-quaternary: #94001d;
	--background-modal: rgba(0, 0, 0, 0.7);
	/**/

	/* typed: color */
	/* color */
	--color-primary: #FDD431;
	--color-link: #F48F31;
	--color-link-disabled: rgba(244,143,49,0.2);
	--color-link-text-disabled: rgba(255,255,255,0.3);
	--color-secondary: #545F71;
	--color-primary-light: #FFF6D6;
	--color-text-primary: #465050;
	--color-text-secondary: #9c9c9c;
	--color-white: #FFFFFF;
	--color-black: #000000;
	--grey: #989898;

	--color-link-25: #FBC331;
	--color-link-25-grey: #F5F5F5;

	--color-info: #1DAFEC;




	--error-color: #ff0000;
	--warning-color: #ffad00;
	--success-color: #35cd05;

	--info-color: #71a4d1;
	--in-progress-color: #ffc72b;
	--completed-color: #93d559;

	--promotion-info: #55e9f4;
	/**/

	/* typed: text-color */
	/* color */
	--color-grey-50: #f8f8f8;
	--color-grey-100: #f0f0f0;
	--color-grey-200: #dbdbdb;
	--color-grey-300: #c2c2c2;
	--color-grey-400: #9c9c9c;
	--color-grey-600: #808080;
	--color-grey-700: #505050;
	--color-grey-900: #2a2a2a;
	--color-grey-black: #000000;
	--color-grey-white: #ffffff;
	/**/

	--default-button-height: 48px;
}

.is-mobile {
	/* skip */
	--page-margin: var(--space-4);
	--page-margin-sm: var(--space-3);
	/**/
}