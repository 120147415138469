.parcel-notification-widget {
  border-radius: $border-primary-rounded-2-5;
  flex-direction: column-reverse;

  &__content {
    padding: 0;
    background: $color-stl-grey-white;
  }

  &__footer {
    background: $color-stl-grey-100;
    border: 1px solid $color-stl-grey-white;
    border-bottom: none;

    &--left {
    }

    &--right {
    }
  }
}
