@mixin parcel-mat-button-toggle {
  min-width: 100%;
  .mat-button-toggle {
    flex: 1 1 auto;
    .mat-button-toggle-button {
      height: 100%;
    }
    .mat-button-toggle-label-content {
      line-height: 100%;
      padding: 0 $space-3;
      mat-icon + span { margin-left: $space-2 }
      span + mat-icon { margin-left: $space-2 }
    }
    &.mat-button-toggle-checked {
      .mat-button-toggle-focus-overlay {
        border-bottom: none;
      }
    }
  }
}

