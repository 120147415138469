.parcel-mat-button-toggle {
  &.mat-button-toggle-group {
    @include border('$border-primary-rounded-2-5', 'primary', $color-stl-grey-300);
  }

  .mat-button-toggle {
    @include font('body-m', 'stl');
    color: $color-stl-grey-900;
    background: transparent;

    + .mat-button-toggle {
      border-left: solid 1px $color-stl-grey-300;
    }

    &:hover {
      color: $color-stl-brand-500;
      background: $color-stl-grey-50;
    }

    &:focus:not(.cdk-mouse-focused) {
      color: $color-stl-grey-900;
      background: transparent;
    }

    &:active, &.mat-button-toggle-checked {
      color: $color-stl-brand-500;
      background: $color-stl-grey-100;
    }
  }
}
