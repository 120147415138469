$ink-bar-height: 3px;

.parcel-mat-tabs {
  .mat-mdc-tab,
  .mat-mdc-tab-link {
    .mdc-tab__content {
      .mdc-tab__text-label {
        @include font('body-m', 'lmh');
        color: $color-lmh-grey-900;
        opacity: 1;
      }
    }

    &.cdk-keyboard-focused {
      @include outlineInset(2px, $color-lmh-feedback-focused);
    }
  }

  .mat-mdc-tab-header, &.mat-mdc-tab-nav-bar {
    border-bottom: 1px solid $color-lmh-grey-300;
  }

  .mdc-tab-indicator {
    .mdc-tab-indicator__content--underline {
      display: none;
    }
  }

  .mat-mdc-tab-header-pagination {
    background-color: $color-lmh-grey-50;

    .mat-mdc-tab-header-pagination-chevron {
      border-color: $color-lmh-brand-500;
    }
  }

  .mat-mdc-tab-header-pagination-disabled {
    .mat-mdc-tab-header-pagination-chevron {
      border-color: rgba($color-lmh-brand-500, .5);
    }
  }

  .mat-mdc-tab-header-pagination-before {
    &:not(.mat-mdc-tab-header-pagination-disabled) {
      overflow: visible;

      &:after {
        pointer-events: none;
        content: '';
        position: absolute;
        background: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
        width: 24px;
        height: 100%;
        right: -24px;
      }
    }
  }

  .mat-mdc-tab-header-pagination-after {
    &:not(.mat-mdc-tab-header-pagination-disabled) {
      overflow: visible;

      &:after {
        pointer-events: none;
        content: '';
        position: absolute;
        background: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
        width: 24px;
        height: 100%;
        left: -24px;
        transform: rotate(-180deg);
      }
    }
  }

  .mdc-tab__ripple {
    display: none !important;
  }

  &.style--white {
    .mat-mdc-tab,
    .mat-mdc-tab-link {
      background: $color-lmh-grey-white;

      &:hover {
        background: $color-lmh-grey-50;
      }

      &.mdc-tab--active {
        background: $color-lmh-grey-100;

        .mdc-tab__content {
          .mdc-tab__text-label {
            color: $color-lmh-brand-500;
          }
        }
      }

      .mdc-tab__content {
        .mdc-tab__text-label {
          @include font('body-m', 'lmh');
          color: $color-lmh-grey-900;
          opacity: 1;
        }
      }
    }

    &.type--underline {
      .mat-mdc-tab,
      .mat-mdc-tab-link {
        &.mdc-tab--active {
          background: $color-lmh-grey-white;

          .mdc-tab__content {
            .mdc-tab__text-label {
              color: $color-lmh-grey-900;
            }
          }
        }
      }
    }
  }
  &.style--grey {
    .mat-mdc-tab,
    .mat-mdc-tab-link {
      background: $color-lmh-grey-100;

      &:hover {
        background: $color-lmh-grey-50;
      }

      &.mdc-tab--active {
        background: $color-lmh-grey-white;

        .mdc-tab__content {
          .mdc-tab__text-label {
            color: $color-lmh-brand-500;
          }
        }
      }

      .mdc-tab__content {
        .mdc-tab__text-label {
          @include font('body-m', 'lmh');
          color: $color-lmh-grey-900;
          opacity: 1;
        }
      }
    }

    &.type--underline {
      .mat-mdc-tab,
      .mat-mdc-tab-link {
        &.mdc-tab--active {
          background: $color-lmh-grey-100;

          .mdc-tab__content {
            .mdc-tab__text-label {
              color: $color-lmh-grey-900;
            }
          }
        }
      }
    }
  }

  &.type--underline {
    .mat-mdc-tab,
    .mat-mdc-tab-link {
      .mdc-tab-indicator {
        .mdc-tab-indicator__content--underline {
          display: block;
          border-top-width: $ink-bar-height;
          border-color: $color-lmh-brand-500;
        }
      }
    }
  }
}

