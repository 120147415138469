$mainColor: $color-stl-brand-500;
$background: $color-stl-grey-300;
$neutralMainColor: $color-stl-grey-600;

.parcel-mat-progress-bar {
  &.neutral {
    .mdc-linear-progress__bar {
      .mdc-linear-progress__bar-inner {
        border-color: $neutralMainColor;
      }
    }
    .mdc-linear-progress__buffer {
      .mdc-linear-progress__buffer-bar {
        background-color: $background;
      }
      .mdc-linear-progress__buffer-dots {
      }
    }
    &[mode="buffer"] {
      .mdc-linear-progress__buffer {
        .mdc-linear-progress__buffer-bar {
          background-color: transparent;
        }
      }
      background-image:
        repeating-linear-gradient(
            90deg,
            transparent,
            transparent 4px,
            $neutralMainColor 4px,
            $neutralMainColor 8px /* determines size */
        );
    }
  }

  .mdc-linear-progress__bar {
    .mdc-linear-progress__bar-inner {
      border-color: $mainColor;
    }
  }
  .mdc-linear-progress__buffer {
    .mdc-linear-progress__buffer-bar {
      background-color: $background;
    }
    .mdc-linear-progress__buffer-dots {
    }
  }
  &[mode="buffer"] {
    .mdc-linear-progress__bar {
      .mdc-linear-progress__bar-inner {
        border-color: transparent;
      }
    }
    .mdc-linear-progress__buffer {
      .mdc-linear-progress__buffer-bar {
        background-color: transparent;
      }
    }
    background-image:
      repeating-linear-gradient(
          90deg,
          transparent,
          transparent 4px,
          $mainColor 4px,
          $mainColor 8px /* determines size */
      );
  }
}
