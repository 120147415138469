$button-icon-size: 20px;
$button-icon-size--small: $space-4;

$loading-indicator-size: $space-6;
$loading-indicator-size--small: $space-5;

$minwidth-button-default: $space-10;
$minwidth-button-small: $space-10;

@mixin buttonLoading($size: $loading-indicator-size, $stroke: $space-1) {
  content: '';
  width: $size;
  height: $size;
  position: relative;

  border-radius: 50%;
  display: inline-flex;
  background-color: transparent;
  border-top: $stroke solid transparent;
  border-left: $stroke solid currentColor;
  border-right: $stroke solid currentColor;
  border-bottom: $stroke solid currentColor;
  opacity: .5;
  animation: spin $transitions-speed-1250 linear infinite;
  box-sizing: border-box;
}

/*
button background
button border type (radius)
button border width
button border color
button color
button cursor
button outline
button outline color
*/

@mixin buttonStyle($background, $borderType, $borderWidth, $borderColor, $color, $cursor: null, $outlineWidth: null, $outlineColor:null, $outline-offset: false) {
  background: $background;
  color: $color;
  outline: none;

  @if ($cursor != null) {
    cursor: $cursor;
  }
  @if ($borderWidth == 'primary' or $borderWidth == 'secondary') {
    @include border($borderType, $borderWidth, $borderColor);
  }
  @if ($outlineWidth != null or $outlineColor != null) {
    @if ($outline-offset == true) {
      box-shadow: 0 0 0 1px white, 0 0 0 ($outlineWidth + 1) $outlineColor;
    } @else {
      @include outline($outlineWidth, $outlineColor);
    }
  }
}

@mixin buttonGlobals() {

  --mat-text-button-hover-state-layer-opacity: 0;
  --mat-text-button-focus-state-layer-opacity: 0;
  --mat-text-button-pressed-state-layer-opacity: 0;

  &.parcel-mat-button {
    border: none;
    padding: 0;
    border-radius: 0;
    background: yellow;
    min-width: auto;
    color: red;

    > .mdc-fab__ripple,
    > .mdc-button__ripple,
    > .mat-ripple {
      display: none !important;
    }

    > .mdc-button__label {
      white-space: nowrap;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
    }

    &.size--medium {
      height: $space-8;
      padding: 0 $space-4;

      > .mat-icon {
        margin-left: 0;
        margin-right: $space-2;
        display: inline-block;
        position: relative;
        vertical-align: top;
        font-size: 0;
      }

      &.state--loading {
        > .mat-icon {
          display: none;
        }

        .mdc-button__label {
          &:before {
            @include buttonLoading($size: $loading-indicator-size, $stroke: 3px);

            margin-right: $space-3;
          }
        }

        &.icon--right {
          .mdc-button__label {
            &:before {
              display: none;
            }

            &:after {
              @include buttonLoading($size: $loading-indicator-size, $stroke: 3px);

              margin-left: $space-3;
            }
          }
        }
      }
    }

    &.size--small {
      height: $space-6;
      padding: 0 $space-2;

      > .mat-icon {
        margin-left: 0;
        margin-right: $space-2;
        display: inline-block;
        position: relative;
        vertical-align: top;
        font-size: 0;
        height: $button-icon-size--small;
        width: $button-icon-size--small;
      }

      &.state--loading {
        > .mat-icon {
          display: none;
        }

        .mdc-button__label {
          &:before {
            @include buttonLoading($size: $loading-indicator-size--small, $stroke: 2px);

            margin-right: $space-2;
          }
        }

        &.icon--right {
          .mdc-button__label {
            &:before {
              display: none;
            }

            &:after {
              @include buttonLoading($size: $loading-indicator-size--small, $stroke: 2px);

              margin-left: $space-2;
            }
          }
        }
      }
    }

    &.icon--right {
      flex-direction: row-reverse;

      &.size--medium {
        > .mat-icon {
          margin-left: $space-2;
          margin-right: 0;
        }
      }

      &.size--small, &.type--transparent {
        > .mat-icon {
          margin-left: $space-2;
          margin-right: 0;
        }
      }
    }

    &.width--full {
      width: 100%;
    }

    // Custom Types
    &.type--transparent {
      // height: 35px;
    }

    &.type--image {
      padding: 0;
      height: $space-8;
      width: $space-8;

      > .mdc-button__label {
        width: 36px;
        height: 36px;

        > span {
          overflow: hidden;
          display: inline-block;
          box-sizing: border-box;
          border-radius: 50%;
          width: 36px;
          height: 36px;
        }
      }
    }

    // iconButton
    &-icon, &-icon:disabled {
      min-width: auto;

      &.size--medium {
        width: $space-8;
        height: $space-8;

        > .mat-icon {
          margin: 0;
          width: $space-6;
          height: $space-6;
          font-size: 0;
        }

        &.state--loading {
          > .mat-icon {
            display: none;
          }

          &:before {
            @include buttonLoading($size: $loading-indicator-size, $stroke: 3px);
          }
        }
      }
      &.size--small {
        width: $space-6;
        height: $space-6;

        > .mat-icon {
          margin: 0;
          width: $space-5;
          height: $space-5;
          font-size: 0;
        }

        &.state--loading {
          > .mat-icon {
            display: none;
          }

          &:before {
            @include buttonLoading($size: $loading-indicator-size--small, $stroke: 2px);
          }
        }
      }

    }

    &.type--carousel {
      height: 60px;
      width: $space-7;

      > .mat-icon {
        margin: 0;
        width: $space-6;
        height: $space-6;
      }
    }
  }

  &.parcel-mat-button-icon {
    &.type--fab, &.type--fab-mini {
      box-shadow: none;
      border-radius: 50%;
      transition: box-shadow $parcel-duration-fast;
    }

    &.type--fab {
      width: 56px;
      height: 56px;

      > .mat-icon {
        margin: 0;
        width: $space-6;
        height: $space-6;
      }
    }
    &.type--fab-mini {
      width: 40px;
      height: 40px;

      > .mat-icon {
        margin: 0;
        width: $space-5;
        height: $space-5;
      }
    }
  }
}
