.parcel-mat-label {
  @include font('body-s','stl');
  color: $color-stl-grey-900;

  &:has(+.mat-form-field-invalid) {
    color: $color-stl-feedback-danger !important;

    &:after {
      content: " *"
    }
  }
}
