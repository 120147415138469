@import "mixins/form-field.mixins.material";

.parcel-mat-form-field {
  --mdc-outlined-text-field-focus-outline-width: 1px !important;
  @include parcel-mat-form-field;
}

@import "themes/form-field-kion.material";

.theme-stl {
  @import "themes/form-field-stl.material";
}
.theme-lmh, .theme-fwk {
  @import "themes/form-field-lmh.material";
}
.theme-bao {
  @import "themes/form-field-bao.material";
}
.theme-kion {
  @import "themes/form-field-kion.material";
}





