$gutter-indicator-offset: $space-4;
$gutter-indicator-height: 100%;

@mixin parcel-mat-grid-list {
  height: 100%;
  width: 100%;

  @for $i from 1 through 25 {
    &[cols="#{$i}"]{
      mat-grid-tile {
        &:nth-child(#{$i}n) {
          &:after {
            opacity: 0;
          }
          &:before {
            width: 100%;
          }
        }
      }
    }
  }

  mat-grid-tile {
    overflow: visible;

    &:after {
      content: '';
      position: absolute;
      width: 1px;
      height: $gutter-indicator-height;
      min-height: $space-5;
      top: 0;
      bottom: 0;
      right: -#{$gutter-indicator-offset};
      margin: auto;
    }

    &:before {
      content: '';
      position: absolute;
      width: calc(100% + #{$gutter-indicator-offset * 2});
      height: 1px;
      bottom: -#{$gutter-indicator-offset};
    }

    > .mat-grid-tile-content {
        justify-content: normal;
        padding: 0 $space-1;
    }
  }



  &--wrapper {
    width: 100%;
    height: 100%;
    padding: $space-3 $space-4;
    position: relative;
    overflow: hidden;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      height: $space-3;
      width: calc(100% - #{$space-4 * 2});
    }
  }

}
