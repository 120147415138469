.cdk-overlay-dark-backdrop {
  background: $color-lmh-opacity-background-modal !important;
}

.parcel-mat-modal {
  .mat-mdc-dialog-container .mdc-dialog__surface {
    background: $color-lmh-grey-white;
    @include elevation('lmh', 'm');
    @include border('primary-rounded-0', 'primary', $color-lmh-grey-white);
  }

  .mdc-dialog {
    .mdc-dialog {
      &__title {
        @include font('title-xs', 'lmh');
        color: $color-lmh-grey-900;

        & h2 {
          @include font('title-xs', 'lmh');
          color: $color-lmh-grey-900;
        }
      }

      &__content {
        @include font('body-m', 'lmh');
        color: $color-lmh-grey-600;
      }

      &__actions {

      }
    }
  }
}
