$checkbox-size: $space-4;
$checkbox-top-space: 2.5px;

@mixin parcel-mat-checkbox {
  .mdc-form-field {
    .mdc-checkbox {
      width: $checkbox-size;
      height: $checkbox-size;
      padding: $checkbox-top-space $space-2 $checkbox-top-space $space-2;
      margin: 0;
      flex: 0 0 $checkbox-size;

      .mdc-checkbox__ripple {
        display: none
      }

      .mat-mdc-checkbox-touch-target {
        width: $space-5;
        height: $space-5;
      }

      .mdc-checkbox__native-control {
        position: absolute;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        opacity: 0;
        cursor: inherit;
      }

      .mdc-checkbox__background {
        left: calc(50% - (#{$checkbox-size} / 2));
        top: $checkbox-top-space;
        width: $checkbox-size;
        height: $checkbox-size;
      }
    }

    .mat-mdc-checkbox-1-input {
    }

    &.mdc-form-field--align-end > label {
      order: -1;
    }

    > label {
      margin-left: 0;
      margin-right: auto;
      padding-left: 0;
      padding-right: 0;
      order: 0;
    }
  }
}
