.mat-mdc-card {
  &.parcel-mat-card {
    background: $color-stl-grey-100;
    @include border('primary-rounded-2-5', 'primary', $color-stl-grey-100);

    &:hover {
      @include elevation('stl', 'm');
    }

    &:active {
      @include elevation('stl', 's');
    }

    &.cdk-focused:not(.parcel-mat-card--disabled), &:focus:not(.parcel-mat-card--disabled) {
      &.cdk-keyboard-focused {
        border-color: $color-stl-feedback-focused;
      }
    }

    // backgrounds
    &.background-grey {
      border-color: $color-stl-grey-white;
      background: $color-stl-grey-white;
    }

    &.background-dark {
      border-color: $color-stl-grey-white;
      background: $color-stl-grey-white;
      &:hover {
        @include elevation('stl', 'xl');
      }
    }
  }
}
