.parcel-notification-widget {
  //@include elevation('lmh', 'm');
  border-radius: $border-primary-rounded-0;
  flex-direction: column;

  &__content {
    padding: $space-2;
    padding-bottom: 0;
    background: $color-lmh-design-design-6;
  }

  &__footer {
    background: $color-lmh-design-design-6;

    &--left, &--right {
      > .parcel-mat-button.type--transparent {
        color: $color-lmh-grey-white !important;
        background: transparent !important;
        border-color: transparent !important;

        &:hover {
          color: $color-lmh-grey-100 !important;
        }

        &:active {
          color: $color-lmh-grey-300 !important;
        }
      }
    }

    &--right {
    }
  }
}
