@import "mixins/radio-button.mixin.material";

.parcel-mat-radio-button {
  @include parcel-mat-radio-button;
}

.mdc-form-field > label {
  padding: 0 $space-1 !important;
}

.mdc-form-field {
  @include font('body-s', 'wl');
}

@import "themes/radio-button-kion.material";

.theme-stl {
  @import "themes/radio-button-stl.material";

  .mdc-form-field {
    @include font('body-s', 'stl');
  }
}
.theme-lmh, .theme-fwk {
  @import "themes/radio-button-lmh.material";

  .mdc-form-field {
    @include font('body-s', 'lmh');
  }
}
.theme-bao {
  @import "themes/radio-button-bao.material";

  .mdc-form-field {
    @include font('body-s', 'bao');
  }
}
.theme-kion {
  @import "themes/radio-button-kion.material";

  .mdc-form-field {
    @include font('body-s', 'wl');
  }
}
