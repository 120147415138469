$border-radius: $border-primary-rounded-0;
$border-width: $border-width-primary;

$color: $color-lmh-grey-900;
$color-placeholder: $color-lmh-grey-400;

$border-color-regular: $color-lmh-grey-400;
$border-color-hover: $color-lmh-grey-400;
$border-color-focus: $color-lmh-grey-700;
$border-color-disabled: $color-lmh-grey-300;

$background-regular: $color-lmh-grey-white;
$background-hover: $color-lmh-grey-50;
$background-focus: $color-lmh-grey-white;
$background-disabled: $color-lmh-grey-white;

$error-red: $color-lmh-feedback-danger;

@mixin inputFont() {
  @include font('body-m', 'lmh');
}
@mixin errorFont() {
  @include font('body-xs', 'lmh');
}


.parcel-mat-form-field {
  &.mat-mdc-form-field {
    .mdc-text-field__input {
      color: $color;
      @include inputFont();

      &::placeholder {
        color: $color-placeholder;
        @include inputFont();
      }
    }

    .mat-mdc-select-placeholder {
      color: $color-placeholder;
      @include inputFont();
    }
    .mdc-text-field {
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
    }
    .mdc-notched-outline__leading {
      border-left: $border-width solid;
      border-right: none;
      width: 12px !important;
    }

    .mdc-text-field--outlined {
      .mdc-notched-outline {
        .mdc-notched-outline__leading {
          border-top-left-radius: $border-radius;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-bottom-left-radius: $border-radius;
        }
        .mdc-notched-outline__trailing {
          border-top-left-radius: 0;
          border-top-right-radius: $border-radius;
          border-bottom-right-radius: $border-radius;
          border-bottom-left-radius: 0;
        }
      }
    }
    .mdc-notched-outline__trailing {
      border-left: none;
      border-right: $border-width solid;
      flex-grow: 1;
    }

    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      box-sizing: border-box;
      height: 100%;
      border-top: $border-width solid;
      border-bottom: $border-width solid;
      pointer-events: none;
    }

    .mat-mdc-select-value {
      color: $color;
      @include inputFont();
    }

    // default
    .mdc-text-field--outlined {
      &:not(.mdc-text-field--disabled) {
        background: $background-regular;
        .mdc-notched-outline {
          &__leading, &__notch, &__trailing {
            border-color: $border-color-regular;
          }
        }
        // hover
        &:not(.mdc-text-field--focused) {
          &:hover {
            background: $background-hover;
            .mdc-notched-outline {
              &__leading, &__notch, &__trailing {
                border-color: $border-color-hover;
              }
            }
          }
        }
      }

      // disabled
      &.mdc-text-field--disabled {
        background: $background-disabled;
        .loading-indicator {
          color: $border-color-disabled;
        }
        .mdc-notched-outline {
          &__leading, &__notch, &__trailing {
            border-color: $border-color-disabled;

          }
        }
        .mat-icon, span, .mdc-text-field__input, input::placeholder {
          color: $border-color-disabled;
        }
      }
    }

    // focused
    .mdc-text-field--focused {
      background: $background-focus;
      .mdc-notched-outline {
        &__leading, &__notch, &__trailing {
          border-width: $border-width;
          border-color: $border-color-focus !important;
        }
      }
    }

    // error
    &.mat-form-field-invalid {
      .mdc-text-field--outlined {
        &:not(.mdc-text-field--disabled) {
          .mdc-notched-outline {
            &__leading, &__notch, &__trailing {
              border-color: $error-red !important;
            }
          }
        }
      }
    }
    .mat-mdc-form-field-error {
      @include errorFont();
      color: $error-red;
    }

    &.mat-focused.mat-primary .mat-mdc-select-arrow {
      color: $color-lmh-brand-500;
    }
  }

  mat-progress-bar {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal:after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal:after {
  color: $color-lmh-brand-500;
}
