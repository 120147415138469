@mixin parcel-mat-stepper {
  &.mat-stepper-vertical,
  &.mat-stepper-horizontal {
    .mat-step-label {
      min-width: 0;
    }
    .mat-horizontal-stepper-header:has(.mat-step-label:empty) {
      padding-right: $space-3 + 2;
    }
    .mat-stepper-horizontal-line {
      margin: 0 -16px;
    }
    .mat-horizontal-stepper-header .mat-step-icon {
      margin-right: 10px;
    }
  }

  &--active-label {
    &.mat-stepper-vertical, &.mat-stepper-horizontal {
      .mat-horizontal-stepper-header:has(.mat-step-label) {
        padding-right: $space-3 + 2;
      }
      .mat-horizontal-stepper-header:has(.mat-step-label-selected) {
        padding-right: $space-5;
      }

      .mat-step-label:not(.mat-step-label-selected) {
        display: none;
      }
    }
  }
}
