$list-item-height: $space-8;
$list-item-sidemenu-height: $space-7;

@mixin parcel-mat-list {

  --mdc-list-list-item-focus-state-layer-color: #{transparent};
  --mdc-list-list-item-focus-state-layer-opacity: #{0};

  &.mat-mdc-list,
  &.mat-mdc-selection-list {

    &.mat-mdc-list-base,
    &.mat-mdc-list {
      margin: 0;
      padding: 0;
      width: 100%;
      min-width: $space-11;

      .mdc-list-group__subheader {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        margin: 0;
        height: $list-item-height;
        padding: 0 $space-4;
      }
    }

    .mat-mdc-list-item {
      padding: 0 $space-4;

      .fav-icon {
        order: 2;
        visibility: hidden;
        margin-right: 0px !important;

        > mat-icon {
          flex-shrink: 0;
        }

        &.is-toggled {
          visibility: visible;
        }

        &:hover {
          background-color: transparent;
          border: none;
        }
      }

      &:hover {
        .fav-icon {
          visibility: visible;
        }
      }

      &:active {
        .fav-icon {
          visibility: visible;
        }
      }

      &.mdc-list-item--with-one-line {
        height: $list-item-height;
      }
    }

    .mdc-list-item--with-trailing-checkbox, .mdc-list-item--with-trailing-radio {
      .mdc-list-item__end {
        margin-right: 0;
      }
    }

    .mdc-list-item--disabled .mdc-list-item__start,
    .mdc-list-item--disabled .mdc-list-item__content,
    .mdc-list-item--disabled .mdc-list-item__end {
      opacity: 1;
    }

    .mdc-list-item--with-leading-icon {
      .mdc-list-item__start {
        margin: auto $space-2 auto 0;
      }
    }

    &.parcel-mat-list--sidemenu {
      &.mat-mdc-list {
        .mdc-list-group__subheader,
        .mat-mdc-list-item-title {
          user-select: none;
          display: block;
          padding: 0 $space-6 0 $space-6;
          height: $list-item-sidemenu-height;
          line-height: $list-item-sidemenu-height !important;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .mat-mdc-list-item-title {
          @include font('body-s-bold', 'wl');
          line-height: $list-item-sidemenu-height !important;
          color: $color-wl-grey-700;

          > span {
            @include font('body-s', 'wl');
          }
        }

        .mdc-list-group__subheader {
          @include font('body-s-bold', 'wl');
          color: $color-wl-grey-400;
        }
      }

      .mat-mdc-list-item {
        user-select: none;
        padding: 0 $space-4 0 $space-6;

        &:hover {
          cursor: pointer;
          background: $color-wl-grey-200;
        }

        &:active {
          background: $color-wl-grey-300;
        }

        &.active {
          background: transparent;

          .mdc-list-item__primary-text {
            color: $color-wl-brand-500;
            font-weight: bold;
          }

          mat-icon {
            color: $color-wl-brand-500;
          }
        }

        &.mdc-list-item--with-one-line {
          height: $list-item-sidemenu-height;
        }
      }
    }


    &.parcel-mat-list--mega-menu {
      &.mat-mdc-list {
        .mdc-list-group__subheader {
          display: block;
          @include font('body-s-bold', 'wl');
          color: $color-wl-grey-50;
          user-select: none;
          padding: 0 $space-6 0 $space-6;
          height: $list-item-sidemenu-height;
          line-height: $list-item-sidemenu-height !important;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      .mat-mdc-list-item {
        user-select: none;
        padding: 0 $space-4 0 $space-6;

        .mdc-list-item__primary-text {
          color: $color-wl-grey-white;
        }

        mat-icon {
          color: $color-wl-grey-white;
        }

        &:hover {
          cursor: pointer;
          background: rgba($color-wl-grey-50, .4) !important;
          color: $color-wl-grey-white;
        }

        &:active {
          background: rgba($color-wl-grey-50, .2) !important;
          color: $color-wl-grey-white;
        }

        &.active {
          background: transparent;

          .mdc-list-item__primary-text {
            @include font('body-s-bold', 'wl');
            //color: $color-wl-brand-500;
          }

          mat-icon {
            //color: $color-wl-brand-500;
          }
        }

        &.mdc-list-item--with-one-line {
          height: $list-item-sidemenu-height;
        }
      }

      .parcel-mat-list {
        //&:not(:last-of-type) {
        //  padding-bottom: $space-2;
        //
        //  &:after {
        //    content: '';
        //    padding-bottom: $space-2;
        //    position: relative;
        //    display: block;
        //    border-bottom: 1px solid $color-wl-grey-300;
        //  }
        //}
      }
    }
  }
}
