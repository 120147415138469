.mdc-button,
.mdc-fab {
  &.parcel-mat-button,
  &.parcel-mat-button-icon {

    // types
    &.type--primary {

      // styles
      &.style--solid,
      & {
        @include buttonStyle($color-wl-brand-500, 'primary-rounded', 'primary', $color-wl-brand-500, $color-wl-grey-white);

        &:hover,
        &.hover {
          @include buttonStyle($color-wl-brand-700, 'primary-rounded', 'primary', $color-wl-brand-700, $color-wl-grey-white, pointer);
        }

        &:focus:not(.cdk-mouse-focused),
        &.focus {
          @include buttonStyle($color-wl-brand-500, 'primary-rounded', 'primary', $color-wl-grey-white, $color-wl-grey-white, pointer, 2px, $color-wl-feedback-focused);
        }

        &:active,
        &.active {
          @include buttonStyle($color-wl-brand-900, 'primary-rounded', 'primary', $color-wl-brand-900, $color-wl-grey-white);
        }

        &:disabled,
        &.disabled,
        &.state--loading {
          pointer-events: none;
          @include buttonStyle($color-wl-grey-100, 'primary-rounded', 'primary', $color-wl-grey-100, $color-wl-grey-400);
        }

        // backgrounds
        &.background-grey {

          &:disabled,
          &.disabled,
          &.state--loading {
            pointer-events: none;
            @include buttonStyle($color-wl-grey-200, 'primary-rounded', 'primary', $color-wl-grey-200, $color-wl-grey-400);
          }
        }

        &.background-dark {

          &:disabled,
          &.disabled,
          &.state--loading {
            pointer-events: none;
            @include buttonStyle($color-wl-grey-200, 'primary-rounded', 'primary', $color-wl-grey-200, $color-wl-grey-400);
          }
        }
      }

      &.style--outline {
        @include buttonStyle(transparent, 'primary-rounded', 'primary', $color-wl-brand-500, $color-wl-brand-500);

        &:hover,
        &.hover {
          @include buttonStyle($color-wl-brand-700, 'primary-rounded', 'primary', $color-wl-brand-700, $color-wl-grey-white, pointer);
        }

        &:focus:not(.cdk-mouse-focused),
        &.focus {
          @include buttonStyle(transparent, 'primary-rounded', 'primary', $color-wl-brand-500, $color-wl-brand-500, pointer, 2px, $color-wl-feedback-focused, true);
        }

        &:active,
        &.active {
          @include buttonStyle($color-wl-brand-900, 'primary-rounded', 'primary', $color-wl-brand-900, $color-wl-grey-white);
        }

        &:disabled,
        &.disabled,
        &.state--loading {
          pointer-events: none;
          @include buttonStyle(transparent, 'primary-rounded', 'primary', $color-wl-grey-300, $color-wl-grey-400);
        }
      }
    }

    &.type--secondary {

      // styles
      &.style--solid,
      & {
        @include buttonStyle($color-wl-grey-100, 'primary-rounded', 'primary', $color-wl-grey-100, $color-wl-grey-900);

        &:hover,
        &.hover {
          @include buttonStyle($color-wl-brand-700, 'primary-rounded', 'primary', $color-wl-brand-700, $color-wl-grey-white, pointer);
        }

        &:focus:not(.cdk-mouse-focused),
        &.focus {
          @include buttonStyle($color-wl-grey-100, 'primary-rounded', 'primary', $color-wl-grey-100, $color-wl-grey-900, pointer, 2px, $color-wl-feedback-focused, true);
        }

        &:active,
        &.active {
          @include buttonStyle($color-wl-brand-900, 'primary-rounded', 'primary', $color-wl-brand-900, $color-wl-grey-white);
        }

        &:disabled,
        &.disabled,
        &.state--loading {
          pointer-events: none;
          @include buttonStyle($color-wl-grey-100, 'primary-rounded', 'primary', $color-wl-grey-100, $color-wl-grey-400);
        }


        // backgrounds
        &.background-grey {
          @include buttonStyle($color-wl-grey-white, 'primary-rounded', 'primary', $color-wl-grey-white, $color-wl-grey-900);

          &:hover,
          &.hover {
            @include buttonStyle($color-wl-brand-700, 'primary-rounded', 'primary', $color-wl-brand-700, $color-wl-grey-white, pointer);
          }

          &:active,
          &.active {
            @include buttonStyle($color-wl-brand-900, 'primary-rounded', 'primary', $color-wl-brand-900, $color-wl-grey-white);
          }

          &:focus:not(.cdk-mouse-focused),
          &.focus {
            @include buttonStyle($color-wl-grey-200, 'primary-rounded', 'primary', $color-wl-grey-200, $color-wl-grey-900, pointer, 2px, $color-wl-feedback-focused, true);
          }

          &:disabled,
          &.disabled,
          &.state--loading {
            pointer-events: none;
            @include buttonStyle($color-wl-grey-200, 'primary-rounded', 'primary', $color-wl-grey-200, $color-wl-grey-400);
          }
        }

        &.background-dark {
          @include buttonStyle($color-wl-grey-50, 'primary-rounded', 'primary', $color-wl-grey-50, $color-wl-grey-900);

          &:hover,
          &.hover {
            @include buttonStyle($color-wl-brand-700, 'primary-rounded', 'primary', $color-wl-brand-700, $color-wl-grey-50, pointer);
          }

          &:active,
          &.active {
            @include buttonStyle($color-wl-brand-900, 'primary-rounded', 'primary', $color-wl-brand-900, $color-wl-grey-50);
          }

          &:focus:not(.cdk-mouse-focused),
          &.focus {
            @include buttonStyle($color-wl-grey-50, 'primary-rounded', 'primary', $color-wl-grey-50, $color-wl-grey-900, pointer, 2px, $color-wl-feedback-focused, true);
          }

          &:disabled,
          &.disabled,
          &.state--loading {
            pointer-events: none;
            @include buttonStyle($color-wl-grey-200, 'primary-rounded', 'primary', $color-wl-grey-200, $color-wl-grey-400);
          }
        }
      }

      &.style--outline {
        @include buttonStyle(transparent, 'primary-rounded', 'primary', $color-wl-grey-900, $color-wl-grey-900);

        &:hover,
        &.hover {
          @include buttonStyle($color-wl-brand-700, 'primary-rounded', 'primary', $color-wl-brand-700, $color-wl-grey-white, pointer);
        }

        &:focus:not(.cdk-mouse-focused),
        &.focus {
          @include buttonStyle(transparent, 'primary-rounded', 'primary', $color-wl-grey-900, $color-wl-grey-900, pointer, 2px, $color-wl-feedback-focused, true);
        }

        &:active,
        &.active {
          @include buttonStyle($color-wl-brand-900, 'primary-rounded', 'primary', $color-wl-brand-900, $color-wl-grey-white);
        }

        &:disabled,
        &.disabled,
        &.state--loading {
          pointer-events: none;
          @include buttonStyle(transparent, 'primary-rounded', 'primary', $color-wl-grey-100, $color-wl-grey-400);
        }
      }
    }

    &.type--tertiary {
      @include buttonStyle(transparent, 'primary-rounded', 'primary', $color-wl-grey-900, $color-wl-grey-900);

      &:hover,
      &.hover {
        @include buttonStyle($color-wl-brand-700, 'primary-rounded', 'primary', $color-wl-brand-700, $color-wl-grey-white, pointer);
      }

      &:focus:not(.cdk-mouse-focused),
      &.focus {
        @include buttonStyle(transparent, 'primary-rounded', 'primary', $color-wl-grey-900, $color-wl-grey-900, pointer, 2px, $color-wl-feedback-focused);
      }

      &:active,
      &.active {
        @include buttonStyle($color-wl-brand-900, 'primary-rounded', 'primary', $color-wl-brand-900, $color-wl-grey-white, pointer);
      }

      &:disabled,
      &.disabled,
      &.state--loading {
        pointer-events: none;
        @include buttonStyle(transparent, 'primary-rounded', 'primary', $color-wl-grey-300, $color-wl-grey-300);
      }

      // backgrounds
      &.background-grey {
        @include buttonStyle(transparent, 'primary-rounded', 'primary', $color-wl-grey-900, $color-wl-grey-900);

        &:hover,
        &.hover {
          @include buttonStyle($color-wl-brand-700, 'primary-rounded', 'primary', $color-wl-brand-700, $color-wl-grey-white, pointer);
        }

        &:focus:not(.cdk-mouse-focused),
        &.focus {
          @include buttonStyle(transparent, 'primary-rounded', 'primary', $color-wl-grey-900, $color-wl-grey-900, pointer, 2px, $color-wl-feedback-focused);
        }

        &:active,
        &.active {
          @include buttonStyle($color-wl-brand-900, 'primary-rounded', 'primary', $color-wl-brand-900, $color-wl-grey-white, pointer);
        }

        &:disabled,
        &.disabled,
        &.state--loading {
          pointer-events: none;
          @include buttonStyle(transparent, 'primary-rounded', 'primary', $color-wl-grey-400, $color-wl-grey-400);
        }
      }

      &.background-dark {
        @include buttonStyle(transparent, 'primary-rounded', 'primary', $color-wl-grey-50, $color-wl-grey-50);

        &:hover,
        &.hover {
          @include buttonStyle($color-wl-brand-700, 'primary-rounded', 'primary', $color-wl-brand-700, $color-wl-grey-50, pointer);
        }

        &:focus:not(.cdk-mouse-focused),
        &.focus {
          @include buttonStyle(transparent, 'primary-rounded', 'primary', $color-wl-grey-50, $color-wl-grey-50, pointer, 2px, $color-wl-feedback-focused);
        }

        &:active,
        &.active {
          @include buttonStyle($color-wl-brand-900, 'primary-rounded', 'primary', $color-wl-brand-900, $color-wl-grey-50, pointer);
        }

        &:disabled,
        &.disabled,
        &.state--loading {
          pointer-events: none;
          @include buttonStyle(transparent, 'primary-rounded', 'primary', $color-wl-grey-600, $color-wl-grey-600);
        }
      }
    }

    &.type--transparent {
      // @include font('body-s', 'wl');
      @include buttonStyle(transparent, 'primary-rounded', 'primary', transparent, $color-wl-grey-700);

      &:hover,
      &.hover {
        @include buttonStyle($color-wl-grey-50, 'primary-rounded', 'primary', $color-wl-grey-50, $color-wl-brand-500, pointer);
      }

      &:focus:not(.cdk-mouse-focused),
      &.focus {
        @include buttonStyle(transparent, 'primary-rounded', 'primary', transparent, $color-wl-grey-700, pointer, 2px, $color-wl-feedback-focused);
      }

      &:active,
      &.active {
        @include buttonStyle($color-wl-grey-100, 'primary-rounded', 'primary', $color-wl-grey-100, $color-wl-brand-500);
      }

      &:disabled,
      &.disabled,
      &.state--loading {
        pointer-events: none;
        @include buttonStyle(transparent, 'primary-rounded', 'primary', transparent, $color-wl-grey-300);
      }

      // backgrounds
      &.background-grey {
        @include buttonStyle(transparent, 'primary-rounded', 'primary', transparent, $color-wl-grey-700);

        &:hover,
        &.hover {
          @include buttonStyle($color-wl-grey-50, 'primary-rounded', 'primary', $color-wl-grey-50, $color-wl-brand-500, pointer);
        }

        &:focus:not(.cdk-mouse-focused),
        &.focus {
          @include buttonStyle(transparent, 'primary-rounded', 'primary', transparent, $color-wl-grey-700, pointer, 2px, $color-wl-feedback-focused);
        }

        &:active,
        &.active {
          @include buttonStyle($color-wl-grey-200, 'primary-rounded', 'primary', $color-wl-grey-200, $color-wl-brand-500);
        }

        &:disabled,
        &.disabled,
        &.state--loading {
          pointer-events: none;
          @include buttonStyle(transparent, 'primary-rounded', 'primary', transparent, $color-wl-grey-400);
        }
      }

      &.background-dark {
        @include buttonStyle(transparent, 'primary-rounded', 'primary', transparent, $color-wl-grey-50);

        &:hover,
        &.hover {
          @include buttonStyle($color-wl-grey-700, 'primary-rounded', 'primary', transparent, $color-wl-grey-50, pointer);
        }

        &:focus:not(.cdk-mouse-focused),
        &.focus {
          @include buttonStyle(transparent, 'primary-rounded', 'primary', transparent, $color-wl-grey-50, pointer, 2px, $color-wl-feedback-focused);
        }

        &:active,
        &.active {
          @include buttonStyle($color-wl-grey-600, 'primary-rounded', 'primary', transparent, $color-wl-grey-50, pointer);
        }

        &:disabled,
        &.disabled,
        &.state--loading {
          pointer-events: none;
          @include buttonStyle(transparent, 'primary-rounded', 'primary', transparent, $color-wl-grey-600);
        }
      }
    }

    &.type--icon,
    &.type--image {
      @include buttonStyle(transparent, 'primary-rounded', 'primary', transparent, $color-wl-grey-900);

      &:hover,
      &.hover {
        @include buttonStyle($color-wl-grey-50, 'primary-rounded', 'primary', $color-wl-grey-50, $color-wl-brand-500, pointer);
      }

      &:focus:not(.cdk-mouse-focused),
      &.focus {
        @include buttonStyle(transparent, 'primary-rounded', 'primary', transparent, $color-wl-grey-900, pointer, 2px, $color-wl-feedback-focused, true);
      }

      &:active,
      &.active {
        @include buttonStyle($color-wl-grey-100, 'primary-rounded', 'primary', $color-wl-grey-100, $color-wl-brand-500);
      }

      &:disabled,
      &.disabled,
      &.state--loading {
        pointer-events: none;
        @include buttonStyle(transparent, 'primary-rounded', 'primary', transparent, $color-wl-grey-300);

        > .mdc-button__label {
          > span {
            opacity: .6;
          }
        }
      }
    }

    &.type--image {
      > .mdc-button__label {
        > span {
          @include font('body-m', 'wl');
          color: $color-wl-grey-white;
          background: $color-wl-grey-400;
        }
      }
    }

    &.type--carousel {
      @include buttonStyle($color-wl-grey-50, 'primary-rounded', 'primary', $color-wl-grey-50, $color-wl-grey-600);

      &:hover,
      &.hover {
        @include buttonStyle($color-wl-brand-500, 'primary-rounded', 'primary', $color-wl-brand-500, $color-wl-grey-white, pointer);
      }

      &:focus:not(.cdk-mouse-focused),
      &.focus {
        @include buttonStyle($color-wl-grey-50, 'primary-rounded', 'primary', $color-wl-grey-50, $color-wl-grey-600, pointer, 2px, $color-wl-brand-700, true);
      }

      &:active,
      &.active {
        @include buttonStyle($color-wl-brand-900, 'primary-rounded', 'primary', $color-wl-brand-900, $color-wl-grey-white);
      }

      &:disabled,
      &.disabled,
      &.state--loading {
        pointer-events: none;
        @include buttonStyle(transparent, 'primary-rounded', 'primary', transparent, $color-wl-grey-300);
      }
    }

    &.type--fab,
    &.type--fab-mini {
      background: $color-wl-brand-500;
      color: $color-wl-grey-white;
      @include elevation('wl', 's');

      &:hover,
      &.hover {
        background: $color-wl-brand-700;
        color: $color-wl-grey-white;
      }

      &:focus:not(.cdk-mouse-focused),
      &.focus {
        background: $color-wl-brand-500;
        color: $color-wl-grey-white;

        @include outline(2px, $color-wl-brand-700);
      }

      &:active,
      &.active,
      &:focus:active {
        background: $color-wl-brand-700;
        color: $color-wl-grey-white;

        @include elevation('wl', 'm');
      }

      &:disabled,
      &.disabled,
      &.state--loading {
        pointer-events: none;
        background: $color-wl-grey-100;
        color: $color-wl-grey-400;
      }
    }

    // sizes
    &.size--medium {
      @include font('body-m', 'wl');

      > .mat-icon {
        height: $space-6;
        width: $space-6;
      }
    }

    &.size--small {
      @include font('body-s', 'wl');

      > .mat-icon {
        height: $space-5;
        width: $space-5;
      }
    }
  }

  &.parcel-mat-button-icon {
    min-width: auto;
    padding: 0;

    &.size--medium {
      width: $space-8;
      height: $space-8;

      > .mat-icon {
        margin: 0;
        width: $space-6;
        height: $space-6;
        font-size: 0;
      }
    }

    &.size--small {
      width: $space-6;
      height: $space-6;

      > .mat-icon {
        margin: 0;
        width: $space-5;
        height: $space-5;
        font-size: 0;
      }
    }
  }

}
