.mat-mdc-chip {
  &.mat-mdc-chip {
    @include font('body-m', 'lmh');

    &.parcel-mat-chip--small {
      &.mat-mdc-standard-chip {
        --mdc-chip-label-text-font: #{$font-lmh-body-s-font-family};
        --mdc-chip-label-text-line-height: #{$font-lmh-body-s-line-height}px;
        --mdc-chip-label-text-size: #{$font-lmh-body-s-font-size}px;
        --mdc-chip-label-text-weight: #{$font-lmh-body-s-font-weight};
        --mdc-chip-label-text-tracking: #{$font-lmh-body-s-letter-spacing};
      }
    }
  }
  &.mat-mdc-standard-chip,
  &.mat-primary {
    --mdc-chip-label-text-font: #{$font-lmh-body-m-font-family};
    --mdc-chip-label-text-line-height: #{$font-lmh-body-m-line-height}px;
    --mdc-chip-label-text-size: #{$font-lmh-body-m-font-size}px;
    --mdc-chip-label-text-weight: #{$font-lmh-body-m-font-weight};
    --mdc-chip-label-text-tracking: #{$font-lmh-body-m-letter-spacing};

    --mdc-chip-elevated-container-color: #{$color-lmh-grey-50};
    --mdc-chip-elevated-disabled-container-color: #{$color-lmh-grey-100};
    --mdc-chip-elevated-selected-container-color: #{$color-lmh-brand-500};

    --mdc-chip-label-text-color: #{$color-lmh-brand-500};
    --mdc-chip-disabled-label-text-color: #{$color-lmh-grey-400};

    --mdc-chip-with-icon-icon-color: #{$color-lmh-brand-500};
    --mdc-chip-with-icon-disabled-icon-color: #{$color-lmh-grey-400};

    --mdc-chip-with-trailing-icon-trailing-icon-color: #{$color-lmh-brand-500};
    --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #{$color-lmh-grey-400};

    --mdc-chip-with-icon-selected-icon-color: #{$color-lmh-brand-500};

    .mat-mdc-chip-focus-overlay {
      background: $color-lmh-grey-100;
    }
    .mdc-evolution-chip__graphic, .mdc-evolution-chip__icon--primary  {
      height: $space-5;
      width: $space-5;
      font-size: $space-5;
    }


    &:hover {
      .mat-mdc-chip-focus-overlay {
        opacity: 1;
      }
    }
    &:active {
      .mat-mdc-chip-focus-overlay {
        background: $color-lmh-grey-300;
      }
    }
    &.cdk-keyboard-focused {
      .mat-mdc-chip-focus-overlay {
        opacity: 1;
        border: 2px solid $color-lmh-brand-700;
      }
    }

    &.mat-mdc-chip-selected,
    &.mat-mdc-chip-highlighted {
      --mdc-chip-elevated-container-color: #{$color-lmh-brand-500};
      --mdc-chip-elevated-disabled-container-color: #{$color-lmh-brand-100};

      --mdc-chip-label-text-color: #{$color-lmh-grey-white};
      --mdc-chip-disabled-label-text-color: #{$color-lmh-grey-white};

      --mdc-chip-with-icon-icon-color: #{$color-lmh-grey-white};
      --mdc-chip-with-icon-disabled-icon-color: #{$color-lmh-grey-white};

      --mdc-chip-with-trailing-icon-trailing-icon-color: #{$color-lmh-grey-white};
      --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #{$color-lmh-grey-white};

      --mdc-chip-with-icon-selected-icon-color: #{$color-lmh-grey-white};

      .mat-mdc-chip-focus-overlay {
        background: $color-lmh-brand-700;
      }
      &:active {
        .mat-mdc-chip-focus-overlay {
          background: $color-lmh-brand-900;
        }
      }
    }


    &.parcel-mat-chip--rectangle {
      border-radius: $border-primary-rounded;
    }
  }

  &.parcel-mat-chip--dark {
    &.mat-mdc-chip {
      @include font('body-s', 'lmh');
    }
    &.mat-mdc-standard-chip {
      --mdc-chip-elevated-container-color: #{$color-lmh-grey-900};
      --mdc-chip-elevated-disabled-container-color: #{$color-lmh-grey-300};

      --mdc-chip-label-text-color: #{$color-lmh-grey-white};
      --mdc-chip-disabled-label-text-color: #{$color-lmh-grey-white};

      --mdc-chip-with-icon-icon-color: #{$color-lmh-grey-white};
      --mdc-chip-with-icon-disabled-icon-color: #{$color-lmh-grey-white};

      --mdc-chip-with-trailing-icon-trailing-icon-color: #{$color-lmh-grey-white};
      --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #{$color-lmh-grey-white};

      --mdc-chip-with-icon-selected-icon-color: #{$color-lmh-grey-white};

      .mat-mdc-chip-focus-overlay {
        background: $color-lmh-grey-700;
      }

      &:hover {
        .mat-mdc-chip-focus-overlay {
          opacity: 1;
        }
      }
      &:active {
        .mat-mdc-chip-focus-overlay {
          background: $color-lmh-grey-600;
        }
      }
      &.cdk-keyboard-focused {
        .mat-mdc-chip-focus-overlay {
          opacity: 1;
          border: 2px solid $color-lmh-brand-700;
        }
      }

      &.mat-mdc-chip-selected,
      &.mat-mdc-chip-highlighted {
        --mdc-chip-elevated-container-color: #{$color-lmh-brand-500};
        --mdc-chip-elevated-disabled-container-color: #{$color-lmh-brand-100};

        --mdc-chip-label-text-color: #{$color-lmh-grey-white};
        --mdc-chip-disabled-label-text-color: #{$color-lmh-grey-white};

        --mdc-chip-with-icon-icon-color: #{$color-lmh-grey-white};
        --mdc-chip-with-icon-disabled-icon-color: #{$color-lmh-grey-white};

        --mdc-chip-with-trailing-icon-trailing-icon-color: #{$color-lmh-grey-white};
        --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #{$color-lmh-grey-white};

        --mdc-chip-with-icon-selected-icon-color: #{$color-lmh-grey-white};

        .mat-mdc-chip-focus-overlay {
          background: $color-lmh-brand-700;
        }
        &:active {
          .mat-mdc-chip-focus-overlay {
            background: $color-lmh-brand-900;
          }
        }
      }


      &.parcel-mat-chip--rectangle {
        border-radius: $border-primary-rounded-0;
      }
    }
  }
}
