.parcel-mat-radio-button {
  .mat-mdc-radio-button {
    .mdc-radio {
      .mdc-radio__native-control {
        &:enabled {
          &:not(:checked) {
            + .mdc-radio__background {
              // default
              .mdc-radio__outer-circle {
                border-color: $color-lmh-grey-400;
                background-color: $color-lmh-grey-white;
              }
            }
          }

          &:checked {
            + .mdc-radio__background {
              .mdc-radio__outer-circle,
              .mdc-radio__inner-circle {
                border-color: $color-lmh-brand-500;
              }
              .mdc-radio__outer-circle {
                background-color: $color-lmh-grey-white;
              }
            }
          }
        }

        &:focus {
          &:enabled {
            &:not(:checked) {
              ~ .mdc-radio__background {
                .mdc-radio__outer-circle {
                  border-color: $color-lmh-brand-700;
                }
              }
            }
          }
        }

        &:disabled {
          &:not(:checked),
          &:checked {
            + .mdc-radio__background {
              .mdc-radio__outer-circle, .mdc-radio__inner-circle {
                opacity: 1;
                border-color: $color-lmh-grey-300;
              }
            }
          }
        }
      }
      &:not(:disabled) {
        &:active {
          .mdc-radio__native-control {
            &:enabled {
              &:checked {
                + .mdc-radio__background {
                  .mdc-radio__outer-circle {
                    border-color: $color-lmh-brand-700;
                  }
                }
              }

              &:not(:checked) {
                + .mdc-radio__background {
                  .mdc-radio__outer-circle {
                    border-color: $color-lmh-brand-700;
                  }
                }
              }
            }

            &:focus {
              &:enabled {
                &:not(:checked) {
                  ~ .mdc-radio__background {
                    .mdc-radio__outer-circle {
                      border-color: $color-lmh-brand-700;
                    }
                  }
                }
              }
            }
          }
        }
      }
      &:hover {
        .mdc-radio__native-control {
          &:enabled {
            &:not(:checked), &:checked {
              + .mdc-radio__background {
                .mdc-radio__outer-circle, .mdc-radio__inner-circle {
                  border-color: $color-lmh-brand-700;
                }
              }
            }
          }
        }
      }
      [aria-disabled=true] {
        .mdc-radio__native-control {
          &:not(:checked),
          &:checked {
            + .mdc-radio__background {
              .mdc-radio__outer-circle, .mdc-radio__inner-circle {
                opacity: 1;
                border-color: $color-lmh-grey-300;
              }
            }
          }
        }
      }
    }

    .mdc-form-field {
      color: $color-lmh-grey-700;
    }
    .mdc-radio--disabled + label {
      color: $color-lmh-grey-300;
    }

    &.cdk-keyboard-focused {
      .mdc-radio {
        > .mdc-radio__background {
          .mdc-radio__outer-circle {
            outline-offset: 1px;
            outline: 2px solid $color-lmh-feedback-focused;
          }
        }
      }
    }

    &:active {
      .mdc-radio__native-control {
        &:enabled {
          &:not(:checked), &:checked {
            + .mdc-radio__background {
              .mdc-radio__outer-circle {
                border-color: $color-lmh-brand-900;
              }
            }
          }
        }
      }
    }
    .mdc-radio__native-control:active {
      &:enabled {
        &:not(:checked), &:checked {
          + .mdc-radio__background {
            .mdc-radio__outer-circle {
              border-color: $color-lmh-brand-900 !important;
            }
          }
        }
      }
    }
  }

}
