.parcel-mat-button-toggle {
  &.mat-button-toggle-group {
    @include border('$border-primary-rounded-4', 'primary', $color-bao-grey-300);
  }

  .mat-button-toggle {
    @include font('body-m', 'bao');
    color: $color-bao-grey-900;
    background: transparent;

    + .mat-button-toggle {
      border-left: solid 1px $color-bao-grey-300;
    }

    &:hover {
      color: $color-bao-brand-500;
      background: $color-bao-grey-50;
    }

    &:focus:not(.cdk-mouse-focused) {
      color: $color-bao-grey-900;
      background: transparent;
    }

    &:active, &.mat-button-toggle-checked {
      color: $color-bao-brand-500;
      background: $color-bao-grey-100;
    }
  }
}
