.parcel-mat-stepper {
  &.mat-stepper-vertical, &.mat-stepper-horizontal {
    .mat-step-icon-state-number {
      @include font('body-xs', 'stl');
    }
    .mat-step-label {
      @include font('body-s', 'stl');
    }

    .mat-step-header {
      .mat-step-icon-state-error.mat-step-icon {
        background: $color-stl-feedback-danger;
        color: $color-stl-grey-white;

        .mat-icon {
          line-height: 20px;
          width: 20px;
          height: 20px;
          //margin-top: -2px;
        }

        .mat-step-text-label {
          color: $color-stl-feedback-danger;
        }
      }
    }

    .mat-step-header {
      .mat-step-label.mat-step-label-error {
        .mat-step-icon {
          color: $color-stl-grey-white;
        }
      }
      .mat-step-icon {
        background: $color-stl-grey-400;
      }
    }

    .mat-horizontal-stepper-header::before,
    .mat-horizontal-stepper-header::after,
    .mat-stepper-horizontal-line {
      border-top-color: $color-stl-grey-300;
    }

    .mat-step-header .mat-step-icon-selected,
    .mat-step-header .mat-step-icon-state-done,
    .mat-step-header .mat-step-icon-state-edit {
      background: $color-stl-brand-500;
      color: $color-stl-grey-white;
    }

    .mat-step-header.cdk-keyboard-focused,
    .mat-step-header.cdk-program-focused,
    .mat-step-header:hover:not([aria-disabled]),
    .mat-step-header:hover[aria-disabled=false] {
      background-color: transparent;
    }
  }
}
