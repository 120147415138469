@import "variables";

@mixin elevation($brand, $size) {
  @if ($brand == 'lmh') {
    @if ($size == 's') {
      box-shadow: #{$effect-lmh-elevation-s-offset-x}px #{$effect-lmh-elevation-s-offset-y}px #{$effect-lmh-elevation-s-radius}px #{$effect-lmh-elevation-s-spread}px $effect-lmh-elevation-s-color;
    } @else if ($size == 'm') {
      box-shadow: #{$effect-lmh-elevation-m-offset-x}px #{$effect-lmh-elevation-m-offset-y}px #{$effect-lmh-elevation-m-radius}px #{$effect-lmh-elevation-m-spread}px $effect-lmh-elevation-m-color;
    }
    @else if ($size == 'l') {
      box-shadow: #{$effect-lmh-elevation-l-offset-x}px #{$effect-lmh-elevation-l-offset-y}px #{$effect-lmh-elevation-l-radius}px #{$effect-lmh-elevation-l-spread}px $effect-lmh-elevation-l-color;
    }
    @else if ($size == 'xl') {
      box-shadow: #{$effect-lmh-elevation-xl-offset-x}px #{$effect-lmh-elevation-xl-offset-y}px #{$effect-lmh-elevation-xl-radius}px #{$effect-lmh-elevation-xl-spread}px $effect-lmh-elevation-xl-color;
    }
  }
  @else if ($brand == 'stl') {
    @if ($size == 's') {
      box-shadow: #{$effect-stl-elevation-s-offset-x}px #{$effect-stl-elevation-s-offset-y}px #{$effect-stl-elevation-s-radius}px #{$effect-stl-elevation-s-spread}px $effect-stl-elevation-s-color;
    } @else if ($size == 'm') {
      box-shadow: #{$effect-stl-elevation-m-offset-x}px #{$effect-stl-elevation-m-offset-y}px #{$effect-stl-elevation-m-radius}px #{$effect-stl-elevation-m-spread}px $effect-stl-elevation-m-color;
    }
    @else if ($size == 'l') {
      box-shadow: #{$effect-stl-elevation-l-offset-x}px #{$effect-stl-elevation-l-offset-y}px #{$effect-stl-elevation-l-radius}px #{$effect-stl-elevation-l-spread}px $effect-stl-elevation-l-color;
    }
    @else if ($size == 'xl') {
      box-shadow: #{$effect-stl-elevation-xl-offset-x}px #{$effect-stl-elevation-xl-offset-y}px #{$effect-stl-elevation-xl-radius}px #{$effect-stl-elevation-xl-spread}px $effect-stl-elevation-xl-color;
    }
  }
  @else if ($brand == 'wl') {
    @if ($size == 's') {
      box-shadow: #{$effect-wl-elevation-s-offset-x}px #{$effect-wl-elevation-s-offset-y}px #{$effect-wl-elevation-s-radius}px #{$effect-wl-elevation-s-spread}px $effect-wl-elevation-s-color;
    } @else if ($size == 'm') {
      box-shadow: #{$effect-wl-elevation-m-offset-x}px #{$effect-wl-elevation-m-offset-y}px #{$effect-wl-elevation-m-radius}px #{$effect-wl-elevation-m-spread}px $effect-wl-elevation-m-color;
    }
    @else if ($size == 'l') {
      box-shadow: #{$effect-wl-elevation-l-offset-x}px #{$effect-wl-elevation-l-offset-y}px #{$effect-wl-elevation-l-radius}px #{$effect-wl-elevation-l-spread}px $effect-wl-elevation-l-color;
    }
    @else if ($size == 'xl') {
      box-shadow: #{$effect-wl-elevation-xl-offset-x}px #{$effect-wl-elevation-xl-offset-y}px #{$effect-wl-elevation-xl-radius}px #{$effect-wl-elevation-xl-spread}px $effect-wl-elevation-xl-color;
    }
  }
  @else if ($brand == 'bao') {
    @if ($size == 's') {
      box-shadow: #{$effect-bao-elevation-s-offset-x}px #{$effect-bao-elevation-s-offset-y}px #{$effect-bao-elevation-s-radius}px #{$effect-bao-elevation-s-spread}px $effect-bao-elevation-s-color;
    } @else if ($size == 'm') {
      box-shadow: #{$effect-bao-elevation-m-offset-x}px #{$effect-bao-elevation-m-offset-y}px #{$effect-bao-elevation-m-radius}px #{$effect-bao-elevation-m-spread}px $effect-bao-elevation-m-color;
    }
    @else if ($size == 'l') {
      box-shadow: #{$effect-bao-elevation-l-offset-x}px #{$effect-bao-elevation-l-offset-y}px #{$effect-bao-elevation-l-radius}px #{$effect-bao-elevation-l-spread}px $effect-bao-elevation-l-color;
    }
    @else if ($size == 'xl') {
      box-shadow: #{$effect-bao-elevation-xl-offset-x}px #{$effect-bao-elevation-xl-offset-y}px #{$effect-bao-elevation-xl-radius}px #{$effect-bao-elevation-xl-spread}px $effect-bao-elevation-xl-color;
    }
  }
}
