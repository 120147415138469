.parcel-mat-grid-list {
  mat-grid-tile {
    &:after {
      background: $color-bao-grey-300;
    }

    &:before {
      background: $color-bao-grey-300;
    }
  }

  &--wrapper {
    background: $color-bao-grey-white;
    @include border('primary-rounded-4', 'primary', $color-bao-grey-300);

    &:after {
      background: $color-bao-grey-white;
    }
  }
}
