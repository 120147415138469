@import "mixins/anchor.mixin.material";

.parcel-anchor {
  @include parcel-anchor;
}

@import "themes/anchor-kion.material";

.theme-stl {
  @import "themes/anchor-stl.material";
}
.theme-lmh, .theme-fwk {
  @import "themes/anchor-lmh.material";
}
.theme-bao {
  @import "themes/anchor-bao.material";
}
.theme-kion {
  @import "themes/anchor-kion.material";
}
