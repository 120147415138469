:root {

}

.mat-mdc-menu-item-text {
	display: flex;
	flex-direction: row;
	align-items: start;
	place-content: center flex-start;
}

.cdk-overlay-container {
	z-index: 900000 !important;
}

.placeholder-value {
	.mat-mdc-select-placeholder {
		color: var(--color-grey-black) !important;
	}
}

.mat-mdc-select-panel {
	padding: 0 !important;
}

.panel-alert-class {
	margin-bottom: calc(var(--safe-area-bottom) + var(--page-margin)) !important;
}

.parcel-mat-label {
	&.required {
		&::after {
			content: '*';
		}
	}
}


.mat-horizontal-stepper-header-container{
	height: 50px!important;
}