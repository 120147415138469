@mixin parcel-mat-chips {
  &.mat-mdc-chip {
    &.mat-mdc-standard-chip {
      padding: 0;
      border-radius: $space-4;
      align-items: center;
      cursor: default;
      min-height: $space-6;
      height: 1px;
      user-select: none;

      &.mdc-evolution-chip--with-primary-graphic {
        &.mdc-evolution-chip--with-trailing-action {
          .mdc-evolution-chip__action--trailing {
            padding: 0 $space-2;
          }
        }
      }

      &:not(.mdc-evolution-chip--disabled) {
        .mdc-evolution-chip__icon--trailing {
          height: $space-5;
          width: $space-5;
          font-size: $space-5;
        }
      }
      .mat-mdc-chip-remove {
        opacity: 1;
      }

      .mdc-evolution-chip__cell {
        .mat-mdc-chip-action {
          .mdc-evolution-chip__graphic {
            width: 0;
            > * {
              display: none;
            }
          }
        }
      }
    }

    &.parcel-mat-chip--small {
      &.mat-mdc-standard-chip {
        min-height: $space-5;
      }
    }
  }
}


