.cdk-overlay-dark-backdrop {
  background: $color-bao-opacity-background-modal !important;
}

.parcel-mat-modal {
  .mat-mdc-dialog-container .mdc-dialog__surface {
    background: $color-bao-grey-white;
    @include elevation('bao', 'm');
    @include border('primary-rounded', 'primary', $color-bao-grey-white);
  }

  .mdc-dialog {
    .mdc-dialog {
      &__title {
        @include font('title-xs', 'bao');
        color: $color-bao-grey-900;

        & h2 {
          @include font('title-xs', 'bao');
          color: $color-bao-grey-900;
        }
      }

      &__content {
        @include font('body-m', 'bao');
        color: $color-bao-grey-600;
      }

      &__actions {

      }
    }
  }
}
