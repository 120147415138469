$list-item-height: $space-8;

.parcel-mat-list {
  &.mat-mdc-list,
  &.mat-mdc-selection-list {
    .mat-mdc-list-item {
      &:hover {
        &:not(.mdc-list-item--disabled) {
          background: $color-lmh-grey-100;
        }
      }

      &:active {
        &:not(.mdc-list-item--disabled) {
          background: $color-lmh-grey-300;
        }
      }

      &.mdc-list-item--disabled {
        opacity: .6;
      }
    }

    .mat-mdc-list-option {
      .mdc-checkbox {
        .mdc-checkbox__native-control {
          &[disabled] {
            &:not(:checked) {
              &:not(:indeterminate) {
                &:not([data-indeterminate=true]) {
                  ~ .mdc-checkbox__background {
                    @include border('primary-rounded-0', 'primary', $color-lmh-grey-400);
                  }
                }
              }
            }

            &:checked {
              ~ .mdc-checkbox__background {
                background: $color-lmh-brand-500;
                @include border('primary-rounded-0', 'primary', $color-lmh-brand-500);
              }
            }
          }

          &:enabled {
            &:checked {
              ~ .mdc-checkbox__background {
                background: $color-lmh-brand-500;
                @include border('primary-rounded-0', 'primary', $color-lmh-brand-500);
              }
            }

            &:not(:checked) {
              &:not(:indeterminate) {
                &:not([data-indeterminate=true]) {
                  ~ .mdc-checkbox__background {
                    @include border('primary-rounded-0', 'primary', $color-lmh-grey-400);
                  }
                }
              }
            }
          }
        }
      }

      .mdc-radio {
        .mdc-radio__native-control {
          &:enabled {
            + .mdc-radio__background {
              .mdc-radio__outer-circle {
                border-color: $color-lmh-brand-500;
                border-width: $border-width-primary;
              }
              .mdc-radio__inner-circle {
                border-color: $color-lmh-brand-500;
              }
            }

            &:not(:checked) {
              + .mdc-radio__background {
                .mdc-radio__outer-circle {
                  border-color: $color-lmh-grey-400;
                  border-width: $border-width-primary;
                }
              }
            }
          }

          &:disabled {
            &:checked {
              + .mdc-radio__background {
                .mdc-radio__outer-circle {
                  border-color: $color-lmh-brand-500;
                  border-width: $border-width-primary;
                }

                .mdc-radio__inner-circle {
                  border-color: $color-lmh-brand-500;
                }
              }
            }

            &:not(:checked) {
              + .mdc-radio__background {
                .mdc-radio__outer-circle {
                  border-color: $color-lmh-grey-400;
                  border-width: $border-width-primary;
                }
              }
            }
          }
        }
      }
    }


    .mdc-list-item--with-leading-icon {
      .mdc-list-item__start {
        color: $color-lmh-grey-900;
      }
    }

    .mat-mdc-list-item {
      .mdc-list-item__primary-text {
        @include font('body-s', 'lmh');
        color: $color-lmh-grey-900;
      }

      .mdc-list-item__secondary-text {
        @include font('body-xs', 'lmh');
        color: $color-lmh-grey-900;
      }

      &[aria-selected="true"] {
        .mdc-list-item__primary-text, .mdc-list-item__secondary-text {
          color: $color-lmh-brand-500;
        }

        .mdc-list-item__primary-text {
          font-weight: bold;

        }
      }
    }

    .mat-mdc-subheader {
      @include font('body-s-bold', 'lmh');
      color: $color-lmh-grey-400;
    }

    .mat-divider {
      border-color: $color-lmh-grey-300;
    }

    &.parcel-mat-list--sidemenu {
      &.mat-mdc-list {
        .mdc-list-group__subheader,
        .mat-mdc-list-item-title {
        }

        .mat-mdc-list-item-title {
          @include font('body-s-bold', 'lmh');
          color: $color-lmh-grey-700;

          > span {
            @include font('body-s', 'lmh');
          }
        }
        .mdc-list-group__subheader {
          @include font('body-s-bold', 'lmh');
          color: $color-lmh-grey-400;
        }
      }

      .mat-mdc-list-item {
        &:hover {
          cursor: pointer;
          background: $color-lmh-grey-200;
        }

        &:active {
          background: $color-lmh-grey-300;
        }

        &.active {
          .mdc-list-item__primary-text {
            color: $color-lmh-brand-500;
          }

          mat-icon {
            color: $color-lmh-brand-500;
          }
        }
      }
    }
    &.parcel-mat-list--mega-menu {
      &.mat-mdc-list {
        .mdc-list-group__subheader {
          @include font('body-s-bold', 'lmh');
          color: $color-lmh-grey-50;
          user-select: none;
          padding: 0 $space-6 0 $space-6;
          height: $list-item-sidemenu-height;
        }
      }

      .mat-mdc-list-item {
        user-select: none;
        padding: 0 $space-4 0 $space-6;

        .mdc-list-item__primary-text {
          color: $color-lmh-grey-white;
        }

        mat-icon {
          color: $color-lmh-grey-white;
        }

        &:hover {
          cursor: pointer;
          background: rgba($color-lmh-grey-50, .4) !important;
          color: $color-lmh-grey-white;
        }

        &:active {
          background: rgba($color-lmh-grey-50, .2) !important;
          color: $color-lmh-grey-white;
        }

        &.active {
          background: transparent;
          .mdc-list-item__primary-text {
            @include font('body-s-bold', 'lmh');
          }
          mat-icon {
          }
        }

        &.mdc-list-item--with-one-line {
          height: $list-item-sidemenu-height;
        }
      }
    }
  }





  .mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines,
  .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines,
  .mat-mdc-list-item.mdc-list-item--with-leading-thumbnail.mdc-list-item--with-two-lines,
  .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines,
  .mat-mdc-list-item.mdc-list-item--with-leading-radio.mdc-list-item--with-two-lines,
  .mat-mdc-list-item.mdc-list-item--with-leading-switch.mdc-list-item--with-two-lines,
  .mat-mdc-list-item.mdc-list-item--with-leading-image.mdc-list-item--with-two-lines,
  .mat-mdc-list-item.mdc-list-item--with-leading-video.mdc-list-item--with-two-lines,
  .mdc-list-item.mdc-list-item--with-two-lines {
    height: auto;
    padding: 6px $space-4;
  }

  .mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines .mdc-list-item__primary-text {
    display: flex;
  }
  .mdc-list-item--with-two-lines .mdc-list-item__primary-text, .mdc-list-item--with-three-lines .mdc-list-item__primary-text {
    display: flex;
  }

  &.mdc-list, .mat-mdc-list-item .mdc-list-item__primary-text {
    @include font('body-s', 'lmh');
    color: $color-lmh-grey-900;
  }

  .parcel-mat-list.mat-mdc-list .mat-mdc-list-item,
  .parcel-mat-list.mat-mdc-selection-list .mat-mdc-list-item {

  }

  &--category {
    padding: $space-2;
    padding-bottom: 0;
    display: inline-block;

    @include font('body-s', 'lmh');
    color: $color-lmh-grey-900;
    font-weight: bold;
  }
}
