@mixin parcel-anchor {
  display: inline-block;

  > * {
    vertical-align: middle;
  }

  mat-icon + span, span + mat-icon {
    margin-left: $space-3;
  }

  mat-icon {
    height: 20px;
    width: 20px;
  }

  &:focus-visible {
    outline: none;
  }

  &:hover {
    cursor: pointer;
  }
}
