$border-primary-rounded: 3px;
$border-primary-rounded-0: 0;
$border-primary-rounded-2-5: 2.5px;
$border-primary-rounded-4: 4px;
$border-primary-rounded-40: 40px;

$border-width-primary: 1px;
$border-width-secondary: 2px;

@mixin border($type, $borderWidth, $color: transparent) {

  @if($borderWidth == 'primary') {
    border: $border-width-primary solid $color;
  }
  @else if($borderWidth =='secondary') {
    border: $border-width-secondary solid $color;
  }

  @if ($type == 'primary-rounded') {
    border-radius: $border-primary-rounded;
  }
  @else if($type == 'primary-rounded-0') {
    border-radius: $border-primary-rounded-0;
  }
  @else if($type == 'primary-rounded-2-5') {
    border-radius: $border-primary-rounded-2-5;
  }
  @else if($type == 'primary-rounded-4') {
    border-radius: $border-primary-rounded-4;
  }
  @else if($type == 'primary-rounded-40') {
    border-radius: $border-primary-rounded-40;
  }
}
@mixin outline($size, $color) {
  box-shadow: 0 0 0 $size $color;
}

@mixin outlineInset($size, $color) {
  //box-shadow: inset 0 $size $color;
  box-shadow: inset $size $size $color, inset -#{$size} -#{$size} $color

}
