.parcel-notification-widget {
  //@include elevation('wl', 'l');
  border-radius: $border-primary-rounded;
  flex-direction: column;
  &__content {
    .parcel-mat-notification-list {
      max-height: 288px;
      overflow: auto;
    }
  }
  &__footer {
    background: $color-wl-grey-100;

    &--left {
    }

    &--right {
    }
  }
}
