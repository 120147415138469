@import "variables";

@mixin font($style, $brand) {
  @if($brand == 'lmh') {
    @if($style == 'title-xxl') {
      font-family: $font-lmh-title-xxl-font-family;
      font-size: $font-lmh-title-xxl-font-size + px;
      font-weight: $font-lmh-title-xxl-font-weight;
      text-decoration: $font-lmh-title-xxl-text-decoration;
      font-style: $font-lmh-title-xxl-font-style;
      font-stretch: $font-lmh-title-xxl-font-stretch;
      letter-spacing: $font-lmh-title-xxl-letter-spacing + px;
      line-height: $font-lmh-title-xxl-line-height + px;
    }
    @else if($style == 'title-xl') {
      font-family: $font-lmh-title-xl-font-family;
      font-size: $font-lmh-title-xl-font-size + px;
      font-weight: $font-lmh-title-xl-font-weight;
      text-decoration: $font-lmh-title-xl-text-decoration;
      font-style: $font-lmh-title-xl-font-style;
      font-stretch: $font-lmh-title-xl-font-stretch;
      letter-spacing: $font-lmh-title-xl-letter-spacing + px;
      line-height: $font-lmh-title-xl-line-height + px;
    }
    @else if($style == 'title-l') {
      font-family: $font-lmh-title-l-font-family;
      font-size: $font-lmh-title-l-font-size + px;
      font-weight: $font-lmh-title-l-font-weight;
      text-decoration: $font-lmh-title-l-text-decoration;
      font-style: $font-lmh-title-l-font-style;
      font-stretch: $font-lmh-title-l-font-stretch;
      letter-spacing: $font-lmh-title-l-letter-spacing + px;
      line-height: $font-lmh-title-l-line-height + px;
    }
    @else if($style == 'title-m') {
      font-family: $font-lmh-title-m-font-family;
      font-size: $font-lmh-title-m-font-size + px;
      font-weight: $font-lmh-title-m-font-weight;
      text-decoration: $font-lmh-title-m-text-decoration;
      font-style: $font-lmh-title-m-font-style;
      font-stretch: $font-lmh-title-m-font-stretch;
      letter-spacing: $font-lmh-title-m-letter-spacing + px;
      line-height: $font-lmh-title-m-line-height + px;
    }
    @else if($style == 'title-s') {
      font-family: $font-lmh-title-s-font-family;
      font-size: $font-lmh-title-s-font-size + px;
      font-weight: $font-lmh-title-s-font-weight;
      text-decoration: $font-lmh-title-s-text-decoration;
      font-style: $font-lmh-title-s-font-style;
      font-stretch: $font-lmh-title-s-font-stretch;
      letter-spacing: $font-lmh-title-s-letter-spacing + px;
      line-height: $font-lmh-title-s-line-height + px;
    }
    @else if($style == 'title-xs') {
      font-family: $font-lmh-title-xs-font-family;
      font-size: $font-lmh-title-xs-font-size + px;
      font-weight: $font-lmh-title-xs-font-weight;
      text-decoration: $font-lmh-title-xs-text-decoration;
      font-style: $font-lmh-title-xs-font-style;
      font-stretch: $font-lmh-title-xs-font-stretch;
      letter-spacing: $font-lmh-title-xs-letter-spacing + px;
      line-height: $font-lmh-title-xs-line-height + px;
    }
    @else if($style == 'body-l') {
      font-family: $font-lmh-body-l-font-family;
      font-size: $font-lmh-body-l-font-size + px;
      font-weight: $font-lmh-body-l-font-weight;
      text-decoration: $font-lmh-body-l-text-decoration;
      font-style: $font-lmh-body-l-font-style;
      font-stretch: $font-lmh-body-l-font-stretch;
      letter-spacing: $font-lmh-body-l-letter-spacing + px;
      line-height: $font-lmh-body-l-line-height + px;
    }
    @else if($style == 'body-m') {
      font-family: $font-lmh-body-m-font-family;
      font-size: $font-lmh-body-m-font-size + px;
      font-weight: $font-lmh-body-m-font-weight;
      text-decoration: $font-lmh-body-m-text-decoration;
      font-style: $font-lmh-body-m-font-style;
      font-stretch: $font-lmh-body-m-font-stretch;
      letter-spacing: $font-lmh-body-m-letter-spacing + px;
      line-height: $font-lmh-body-m-line-height + px;
    }
    @else if($style == 'body-s') {
      font-family: $font-lmh-body-s-font-family;
      font-size: $font-lmh-body-s-font-size + px;
      font-weight: $font-lmh-body-s-font-weight;
      text-decoration: $font-lmh-body-s-text-decoration;
      font-style: $font-lmh-body-s-font-style;
      font-stretch: $font-lmh-body-s-font-stretch;
      letter-spacing: $font-lmh-body-s-letter-spacing + px;
      line-height: $font-lmh-body-s-line-height + px;
    }
    @else if($style == 'body-xs') {
      font-family: $font-lmh-body-xs-font-family;
      font-size: $font-lmh-body-xs-font-size + px;
      font-weight: $font-lmh-body-xs-font-weight;
      text-decoration: $font-lmh-body-xs-text-decoration;
      font-style: $font-lmh-body-xs-font-style;
      font-stretch: $font-lmh-body-xs-font-stretch;
      letter-spacing: $font-lmh-body-xs-letter-spacing + px;
      line-height: $font-lmh-body-xs-line-height + px;
    }
    @else if($style == 'body-xxs') {
      font-family: $font-lmh-body-xxs-font-family;
      font-size: $font-lmh-body-xxs-font-size + px;
      font-weight: $font-lmh-body-xxs-font-weight;
      text-decoration: $font-lmh-body-xxs-text-decoration;
      font-style: $font-lmh-body-xxs-font-style;
      font-stretch: $font-lmh-body-xxs-font-stretch;
      letter-spacing: $font-lmh-body-xxs-letter-spacing + px;
      line-height: $font-lmh-body-xxs-line-height + px;
    }
      // underline
    @else if($style == 'title-xxl-underline') {
      font-family: $font-lmh-underline-title-xxl-underline-font-family;
      font-size: $font-lmh-underline-title-xxl-underline-font-size + px;
      font-weight: $font-lmh-underline-title-xxl-underline-font-weight;
      text-decoration: $font-lmh-underline-title-xxl-underline-text-decoration;
      font-style: $font-lmh-underline-title-xxl-underline-font-style;
      font-stretch: $font-lmh-underline-title-xxl-underline-font-stretch;
      letter-spacing: $font-lmh-underline-title-xxl-underline-letter-spacing + px;
      line-height: $font-lmh-underline-title-xxl-underline-line-height + px;
    }
    @else if($style == 'title-xl-underline') {
      font-family: $font-lmh-underline-title-xl-underline-font-family;
      font-size: $font-lmh-underline-title-xl-underline-font-size + px;
      font-weight: $font-lmh-underline-title-xl-underline-font-weight;
      text-decoration: $font-lmh-underline-title-xl-underline-text-decoration;
      font-style: $font-lmh-underline-title-xl-underline-font-style;
      font-stretch: $font-lmh-underline-title-xl-underline-font-stretch;
      letter-spacing: $font-lmh-underline-title-xl-underline-letter-spacing + px;
      line-height: $font-lmh-underline-title-xl-underline-line-height + px;
    }
    @else if($style == 'title-l-underline') {
      font-family: $font-lmh-underline-title-l-underline-font-family;
      font-size: $font-lmh-underline-title-l-underline-font-size + px;
      font-weight: $font-lmh-underline-title-l-underline-font-weight;
      text-decoration: $font-lmh-underline-title-l-underline-text-decoration;
      font-style: $font-lmh-underline-title-l-underline-font-style;
      font-stretch: $font-lmh-underline-title-l-underline-font-stretch;
      letter-spacing: $font-lmh-underline-title-l-underline-letter-spacing + px;
      line-height: $font-lmh-underline-title-l-underline-line-height + px;
    }
    @else if($style == 'title-m-underline') {
      font-family: $font-lmh-underline-title-m-underline-font-family;
      font-size: $font-lmh-underline-title-m-underline-font-size + px;
      font-weight: $font-lmh-underline-title-m-underline-font-weight;
      text-decoration: $font-lmh-underline-title-m-underline-text-decoration;
      font-style: $font-lmh-underline-title-m-underline-font-style;
      font-stretch: $font-lmh-underline-title-m-underline-font-stretch;
      letter-spacing: $font-lmh-underline-title-m-underline-letter-spacing + px;
      line-height: $font-lmh-underline-title-m-underline-line-height + px;
    }
    @else if($style == 'title-s-underline') {
      font-family: $font-lmh-underline-title-s-underline-font-family;
      font-size: $font-lmh-underline-title-s-underline-font-size + px;
      font-weight: $font-lmh-underline-title-s-underline-font-weight;
      text-decoration: $font-lmh-underline-title-s-underline-text-decoration;
      font-style: $font-lmh-underline-title-s-underline-font-style;
      font-stretch: $font-lmh-underline-title-s-underline-font-stretch;
      letter-spacing: $font-lmh-underline-title-s-underline-letter-spacing + px;
      line-height: $font-lmh-underline-title-s-underline-line-height + px;
    }
    @else if($style == 'title-xs-underline') {
      font-family: $font-lmh-underline-title-xs-underline-font-family;
      font-size: $font-lmh-underline-title-xs-underline-font-size + px;
      font-weight: $font-lmh-underline-title-xs-underline-font-weight;
      text-decoration: $font-lmh-underline-title-xs-underline-text-decoration;
      font-style: $font-lmh-underline-title-xs-underline-font-style;
      font-stretch: $font-lmh-underline-title-xs-underline-font-stretch;
      letter-spacing: $font-lmh-underline-title-xs-underline-letter-spacing + px;
      line-height: $font-lmh-underline-title-xs-underline-line-height + px;
    }
    @else if($style == 'body-l-underline') {
      font-family: $font-lmh-underline-body-l-underline-font-family;
      font-size: $font-lmh-underline-body-l-underline-font-size + px;
      font-weight: $font-lmh-underline-body-l-underline-font-weight;
      text-decoration: $font-lmh-underline-body-l-underline-text-decoration;
      font-style: $font-lmh-underline-body-l-underline-font-style;
      font-stretch: $font-lmh-underline-body-l-underline-font-stretch;
      letter-spacing: $font-lmh-underline-body-l-underline-letter-spacing + px;
      line-height: $font-lmh-underline-body-l-underline-line-height + px;
    }
    @else if($style == 'body-m-underline') {
      font-family: $font-lmh-underline-body-m-underline-font-family;
      font-size: $font-lmh-underline-body-m-underline-font-size + px;
      font-weight: $font-lmh-underline-body-m-underline-font-weight;
      text-decoration: $font-lmh-underline-body-m-underline-text-decoration;
      font-style: $font-lmh-underline-body-m-underline-font-style;
      font-stretch: $font-lmh-underline-body-m-underline-font-stretch;
      letter-spacing: $font-lmh-underline-body-m-underline-letter-spacing + px;
      line-height: $font-lmh-underline-body-m-underline-line-height + px;
    }
    @else if($style == 'body-s-underline') {
      font-family: $font-lmh-underline-body-s-underline-font-family;
      font-size: $font-lmh-underline-body-s-underline-font-size + px;
      font-weight: $font-lmh-underline-body-s-underline-font-weight;
      text-decoration: $font-lmh-underline-body-s-underline-text-decoration;
      font-style: $font-lmh-underline-body-s-underline-font-style;
      font-stretch: $font-lmh-underline-body-s-underline-font-stretch;
      letter-spacing: $font-lmh-underline-body-s-underline-letter-spacing + px;
      line-height: $font-lmh-underline-body-s-underline-line-height + px;
    }
    @else if($style == 'body-xs-underline') {
      font-family: $font-lmh-underline-body-xs-underline-font-family;
      font-size: $font-lmh-underline-body-xs-underline-font-size + px;
      font-weight: $font-lmh-underline-body-xs-underline-font-weight;
      text-decoration: $font-lmh-underline-body-xs-underline-text-decoration;
      font-style: $font-lmh-underline-body-xs-underline-font-style;
      font-stretch: $font-lmh-underline-body-xs-underline-font-stretch;
      letter-spacing: $font-lmh-underline-body-xs-underline-letter-spacing + px;
      line-height: $font-lmh-underline-body-xs-underline-line-height + px;
    }
    @else if($style == 'body-xxs-underline') {
      font-family: $font-lmh-underline-body-xxs-underline-font-family;
      font-size: $font-lmh-underline-body-xxs-underline-font-size + px;
      font-weight: $font-lmh-underline-body-xxs-underline-font-weight;
      text-decoration: $font-lmh-underline-body-xxs-underline-text-decoration;
      font-style: $font-lmh-underline-body-xxs-underline-font-style;
      font-stretch: $font-lmh-underline-body-xxs-underline-font-stretch;
      letter-spacing: $font-lmh-underline-body-xxs-underline-letter-spacing + px;
      line-height: $font-lmh-underline-body-xxs-underline-line-height + px;
    }
      // bold
    @else if($style == 'title-xxl-bold') {
      font-family: $font-lmh-bold-title-xxl-bold-font-family;
      font-size: $font-lmh-bold-title-xxl-bold-font-size + px;
      font-weight: $font-lmh-bold-title-xxl-bold-font-weight;
      text-decoration: $font-lmh-bold-title-xxl-bold-text-decoration;
      font-style: $font-lmh-bold-title-xxl-bold-font-style;
      font-stretch: $font-lmh-bold-title-xxl-bold-font-stretch;
      letter-spacing: $font-lmh-bold-title-xxl-bold-letter-spacing + px;
      line-height: $font-lmh-bold-title-xxl-bold-line-height + px;
    }
    @else if($style == 'title-xl-bold') {
      font-family: $font-lmh-bold-title-xl-bold-font-family;
      font-size: $font-lmh-bold-title-xl-bold-font-size + px;
      font-weight: $font-lmh-bold-title-xl-bold-font-weight;
      text-decoration: $font-lmh-bold-title-xl-bold-text-decoration;
      font-style: $font-lmh-bold-title-xl-bold-font-style;
      font-stretch: $font-lmh-bold-title-xl-bold-font-stretch;
      letter-spacing: $font-lmh-bold-title-xl-bold-letter-spacing + px;
      line-height: $font-lmh-bold-title-xl-bold-line-height + px;
    }
    @else if($style == 'title-l-bold') {
      font-family: $font-lmh-bold-title-l-bold-font-family;
      font-size: $font-lmh-bold-title-l-bold-font-size + px;
      font-weight: $font-lmh-bold-title-l-bold-font-weight;
      text-decoration: $font-lmh-bold-title-l-bold-text-decoration;
      font-style: $font-lmh-bold-title-l-bold-font-style;
      font-stretch: $font-lmh-bold-title-l-bold-font-stretch;
      letter-spacing: $font-lmh-bold-title-l-bold-letter-spacing + px;
      line-height: $font-lmh-bold-title-l-bold-line-height + px;
    }
    @else if($style == 'title-m-bold') {
      font-family: $font-lmh-bold-title-m-bold-font-family;
      font-size: $font-lmh-bold-title-m-bold-font-size + px;
      font-weight: $font-lmh-bold-title-m-bold-font-weight;
      text-decoration: $font-lmh-bold-title-m-bold-text-decoration;
      font-style: $font-lmh-bold-title-m-bold-font-style;
      font-stretch: $font-lmh-bold-title-m-bold-font-stretch;
      letter-spacing: $font-lmh-bold-title-m-bold-letter-spacing + px;
      line-height: $font-lmh-bold-title-m-bold-line-height + px;
    }
    @else if($style == 'title-s-bold') {
      font-family: $font-lmh-bold-title-s-bold-font-family;
      font-size: $font-lmh-bold-title-s-bold-font-size + px;
      font-weight: $font-lmh-bold-title-s-bold-font-weight;
      text-decoration: $font-lmh-bold-title-s-bold-text-decoration;
      font-style: $font-lmh-bold-title-s-bold-font-style;
      font-stretch: $font-lmh-bold-title-s-bold-font-stretch;
      letter-spacing: $font-lmh-bold-title-s-bold-letter-spacing + px;
      line-height: $font-lmh-bold-title-s-bold-line-height + px;
    }
    @else if($style == 'title-xs-bold') {
      font-family: $font-lmh-bold-title-xs-bold-font-family;
      font-size: $font-lmh-bold-title-xs-bold-font-size + px;
      font-weight: $font-lmh-bold-title-xs-bold-font-weight;
      text-decoration: $font-lmh-bold-title-xs-bold-text-decoration;
      font-style: $font-lmh-bold-title-xs-bold-font-style;
      font-stretch: $font-lmh-bold-title-xs-bold-font-stretch;
      letter-spacing: $font-lmh-bold-title-xs-bold-letter-spacing + px;
      line-height: $font-lmh-bold-title-xs-bold-line-height + px;
    }
    @else if($style == 'body-l-bold') {
      font-family: $font-lmh-bold-body-l-bold-font-family;
      font-size: $font-lmh-bold-body-l-bold-font-size + px;
      font-weight: $font-lmh-bold-body-l-bold-font-weight;
      text-decoration: $font-lmh-bold-body-l-bold-text-decoration;
      font-style: $font-lmh-bold-body-l-bold-font-style;
      font-stretch: $font-lmh-bold-body-l-bold-font-stretch;
      letter-spacing: $font-lmh-bold-body-l-bold-letter-spacing + px;
      line-height: $font-lmh-bold-body-l-bold-line-height + px;
    }
    @else if($style == 'body-m-bold') {
      font-family: $font-lmh-bold-body-m-bold-font-family;
      font-size: $font-lmh-bold-body-m-bold-font-size + px;
      font-weight: $font-lmh-bold-body-m-bold-font-weight;
      text-decoration: $font-lmh-bold-body-m-bold-text-decoration;
      font-style: $font-lmh-bold-body-m-bold-font-style;
      font-stretch: $font-lmh-bold-body-m-bold-font-stretch;
      letter-spacing: $font-lmh-bold-body-m-bold-letter-spacing + px;
      line-height: $font-lmh-bold-body-m-bold-line-height + px;
    }
    @else if($style == 'body-s-bold') {
      font-family: $font-lmh-bold-body-s-bold-font-family;
      font-size: $font-lmh-bold-body-s-bold-font-size + px;
      font-weight: $font-lmh-bold-body-s-bold-font-weight;
      text-decoration: $font-lmh-bold-body-s-bold-text-decoration;
      font-style: $font-lmh-bold-body-s-bold-font-style;
      font-stretch: $font-lmh-bold-body-s-bold-font-stretch;
      letter-spacing: $font-lmh-bold-body-s-bold-letter-spacing + px;
      line-height: $font-lmh-bold-body-s-bold-line-height + px;
    }
    @else if($style == 'body-xs-bold') {
      font-family: $font-lmh-bold-body-xs-bold-font-family;
      font-size: $font-lmh-bold-body-xs-bold-font-size + px;
      font-weight: $font-lmh-bold-body-xs-bold-font-weight;
      text-decoration: $font-lmh-bold-body-xs-bold-text-decoration;
      font-style: $font-lmh-bold-body-xs-bold-font-style;
      font-stretch: $font-lmh-bold-body-xs-bold-font-stretch;
      letter-spacing: $font-lmh-bold-body-xs-bold-letter-spacing + px;
      line-height: $font-lmh-bold-body-xs-bold-line-height + px;
    }
    @else if($style == 'body-xxs-bold') {
      font-family: $font-lmh-bold-body-xxs-bold-font-family;
      font-size: $font-lmh-bold-body-xxs-bold-font-size + px;
      font-weight: $font-lmh-bold-body-xxs-bold-font-weight;
      text-decoration: $font-lmh-bold-body-xxs-bold-text-decoration;
      font-style: $font-lmh-bold-body-xxs-bold-font-style;
      font-stretch: $font-lmh-bold-body-xxs-bold-font-stretch;
      letter-spacing: $font-lmh-bold-body-xxs-bold-letter-spacing + px;
      line-height: $font-lmh-bold-body-xxs-bold-line-height + px;
    }
      // bold & underline
    @else if($style == 'title-xxl-bold-underline') {
      font-family: $font-lmh-bold-underline-title-xxl-bu-font-family;
      font-size: $font-lmh-bold-underline-title-xxl-bu-font-size + px;
      font-weight: $font-lmh-bold-underline-title-xxl-bu-font-weight;
      text-decoration: $font-lmh-bold-underline-title-xxl-bu-text-decoration;
      font-style: $font-lmh-bold-underline-title-xxl-bu-font-style;
      font-stretch: $font-lmh-bold-underline-title-xxl-bu-font-stretch;
      letter-spacing: $font-lmh-bold-underline-title-xxl-bu-letter-spacing + px;
      line-height: $font-lmh-bold-underline-title-xxl-bu-line-height + px;
    }
    @else if($style == 'title-xl-bold-underline') {
      font-family: $font-lmh-bold-underline-title-xl-bu-font-family;
      font-size: $font-lmh-bold-underline-title-xl-bu-font-size + px;
      font-weight: $font-lmh-bold-underline-title-xl-bu-font-weight;
      text-decoration: $font-lmh-bold-underline-title-xl-bu-text-decoration;
      font-style: $font-lmh-bold-underline-title-xl-bu-font-style;
      font-stretch: $font-lmh-bold-underline-title-xl-bu-font-stretch;
      letter-spacing: $font-lmh-bold-underline-title-xl-bu-letter-spacing + px;
      line-height: $font-lmh-bold-underline-title-xl-bu-line-height + px;
    }
    @else if($style == 'title-l-bold-underline') {
      font-family: $font-lmh-bold-underline-title-l-bu-font-family;
      font-size: $font-lmh-bold-underline-title-l-bu-font-size + px;
      font-weight: $font-lmh-bold-underline-title-l-bu-font-weight;
      text-decoration: $font-lmh-bold-underline-title-l-bu-text-decoration;
      font-style: $font-lmh-bold-underline-title-l-bu-font-style;
      font-stretch: $font-lmh-bold-underline-title-l-bu-font-stretch;
      letter-spacing: $font-lmh-bold-underline-title-l-bu-letter-spacing + px;
      line-height: $font-lmh-bold-underline-title-l-bu-line-height + px;
    }
    @else if($style == 'title-m-bold-underline') {
      font-family: $font-lmh-bold-underline-title-m-bu-font-family;
      font-size: $font-lmh-bold-underline-title-m-bu-font-size + px;
      font-weight: $font-lmh-bold-underline-title-m-bu-font-weight;
      text-decoration: $font-lmh-bold-underline-title-m-bu-text-decoration;
      font-style: $font-lmh-bold-underline-title-m-bu-font-style;
      font-stretch: $font-lmh-bold-underline-title-m-bu-font-stretch;
      letter-spacing: $font-lmh-bold-underline-title-m-bu-letter-spacing + px;
      line-height: $font-lmh-bold-underline-title-m-bu-line-height + px;
    }
    @else if($style == 'title-s-bold-underline') {
      font-family: $font-lmh-bold-underline-title-s-bu-font-family;
      font-size: $font-lmh-bold-underline-title-s-bu-font-size + px;
      font-weight: $font-lmh-bold-underline-title-s-bu-font-weight;
      text-decoration: $font-lmh-bold-underline-title-s-bu-text-decoration;
      font-style: $font-lmh-bold-underline-title-s-bu-font-style;
      font-stretch: $font-lmh-bold-underline-title-s-bu-font-stretch;
      letter-spacing: $font-lmh-bold-underline-title-s-bu-letter-spacing + px;
      line-height: $font-lmh-bold-underline-title-s-bu-line-height + px;
    }
    @else if($style == 'title-xs-bold-underline') {
      font-family: $font-lmh-bold-underline-title-xs-bu-font-family;
      font-size: $font-lmh-bold-underline-title-xs-bu-font-size + px;
      font-weight: $font-lmh-bold-underline-title-xs-bu-font-weight;
      text-decoration: $font-lmh-bold-underline-title-xs-bu-text-decoration;
      font-style: $font-lmh-bold-underline-title-xs-bu-font-style;
      font-stretch: $font-lmh-bold-underline-title-xs-bu-font-stretch;
      letter-spacing: $font-lmh-bold-underline-title-xs-bu-letter-spacing + px;
      line-height: $font-lmh-bold-underline-title-xs-bu-line-height + px;
    }
    @else if($style == 'body-l-bold-underline') {
      font-family: $font-lmh-bold-underline-body-l-bu-font-family;
      font-size: $font-lmh-bold-underline-body-l-bu-font-size + px;
      font-weight: $font-lmh-bold-underline-body-l-bu-font-weight;
      text-decoration: $font-lmh-bold-underline-body-l-bu-text-decoration;
      font-style: $font-lmh-bold-underline-body-l-bu-font-style;
      font-stretch: $font-lmh-bold-underline-body-l-bu-font-stretch;
      letter-spacing: $font-lmh-bold-underline-body-l-bu-letter-spacing + px;
      line-height: $font-lmh-bold-underline-body-l-bu-line-height + px;
    }
    @else if($style == 'body-m-bold-underline') {
      font-family: $font-lmh-bold-underline-body-m-bu-font-family;
      font-size: $font-lmh-bold-underline-body-m-bu-font-size + px;
      font-weight: $font-lmh-bold-underline-body-m-bu-font-weight;
      text-decoration: $font-lmh-bold-underline-body-m-bu-text-decoration;
      font-style: $font-lmh-bold-underline-body-m-bu-font-style;
      font-stretch: $font-lmh-bold-underline-body-m-bu-font-stretch;
      letter-spacing: $font-lmh-bold-underline-body-m-bu-letter-spacing + px;
      line-height: $font-lmh-bold-underline-body-m-bu-line-height + px;
    }
    @else if($style == 'body-s-bold-underline') {
      font-family: $font-lmh-bold-underline-body-s-bu-font-family;
      font-size: $font-lmh-bold-underline-body-s-bu-font-size + px;
      font-weight: $font-lmh-bold-underline-body-s-bu-font-weight;
      text-decoration: $font-lmh-bold-underline-body-s-bu-text-decoration;
      font-style: $font-lmh-bold-underline-body-s-bu-font-style;
      font-stretch: $font-lmh-bold-underline-body-s-bu-font-stretch;
      letter-spacing: $font-lmh-bold-underline-body-s-bu-letter-spacing + px;
      line-height: $font-lmh-bold-underline-body-s-bu-line-height + px;
    }
    @else if($style == 'body-xs-bold-underline') {
      font-family: $font-lmh-bold-underline-body-xs-bu-font-family;
      font-size: $font-lmh-bold-underline-body-xs-bu-font-size + px;
      font-weight: $font-lmh-bold-underline-body-xs-bu-font-weight;
      text-decoration: $font-lmh-bold-underline-body-xs-bu-text-decoration;
      font-style: $font-lmh-bold-underline-body-xs-bu-font-style;
      font-stretch: $font-lmh-bold-underline-body-xs-bu-font-stretch;
      letter-spacing: $font-lmh-bold-underline-body-xs-bu-letter-spacing + px;
      line-height: $font-lmh-bold-underline-body-xs-bu-line-height + px;
    }
    @else if($style == 'body-xxs-bold-underline') {
      font-family: $font-lmh-bold-underline-body-xxs-bu-font-family;
      font-size: $font-lmh-bold-underline-body-xxs-bu-font-size + px;
      font-weight: $font-lmh-bold-underline-body-xxs-bu-font-weight;
      text-decoration: $font-lmh-bold-underline-body-xxs-bu-text-decoration;
      font-style: $font-lmh-bold-underline-body-xxs-bu-font-style;
      font-stretch: $font-lmh-bold-underline-body-xxs-bu-font-stretch;
      letter-spacing: $font-lmh-bold-underline-body-xxs-bu-letter-spacing + px;
      line-height: $font-lmh-bold-underline-body-xxs-bu-line-height + px;
    }
  }
  @else if($brand == 'stl') {
    @if($style == 'title-xxl') {
      font-family: $font-stl-title-xxl-font-family;
      font-size: $font-stl-title-xxl-font-size + px;
      font-weight: $font-stl-title-xxl-font-weight;
      text-decoration: $font-stl-title-xxl-text-decoration;
      font-style: $font-stl-title-xxl-font-style;
      font-stretch: $font-stl-title-xxl-font-stretch;
      letter-spacing: $font-stl-title-xxl-letter-spacing + px;
      line-height: $font-stl-title-xxl-line-height + px;
    }
    @else if($style == 'title-xl') {
      font-family: $font-stl-title-xl-font-family;
      font-size: $font-stl-title-xl-font-size + px;
      font-weight: $font-stl-title-xl-font-weight;
      text-decoration: $font-stl-title-xl-text-decoration;
      font-style: $font-stl-title-xl-font-style;
      font-stretch: $font-stl-title-xl-font-stretch;
      letter-spacing: $font-stl-title-xl-letter-spacing + px;
      line-height: $font-stl-title-xl-line-height + px;
    }
    @else if($style == 'title-l') {
      font-family: $font-stl-title-l-font-family;
      font-size: $font-stl-title-l-font-size + px;
      font-weight: $font-stl-title-l-font-weight;
      text-decoration: $font-stl-title-l-text-decoration;
      font-style: $font-stl-title-l-font-style;
      font-stretch: $font-stl-title-l-font-stretch;
      letter-spacing: $font-stl-title-l-letter-spacing + px;
      line-height: $font-stl-title-l-line-height + px;
    }
    @else if($style == 'title-m') {
      font-family: $font-stl-title-m-font-family;
      font-size: $font-stl-title-m-font-size + px;
      font-weight: $font-stl-title-m-font-weight;
      text-decoration: $font-stl-title-m-text-decoration;
      font-style: $font-stl-title-m-font-style;
      font-stretch: $font-stl-title-m-font-stretch;
      letter-spacing: $font-stl-title-m-letter-spacing + px;
      line-height: $font-stl-title-m-line-height + px;
    }
    @else if($style == 'title-s') {
      font-family: $font-stl-title-s-font-family;
      font-size: $font-stl-title-s-font-size + px;
      font-weight: $font-stl-title-s-font-weight;
      text-decoration: $font-stl-title-s-text-decoration;
      font-style: $font-stl-title-s-font-style;
      font-stretch: $font-stl-title-s-font-stretch;
      letter-spacing: $font-stl-title-s-letter-spacing + px;
      line-height: $font-stl-title-s-line-height + px;
    }
    @else if($style == 'title-xs') {
      font-family: $font-stl-title-xs-font-family;
      font-size: $font-stl-title-xs-font-size + px;
      font-weight: $font-stl-title-xs-font-weight;
      text-decoration: $font-stl-title-xs-text-decoration;
      font-style: $font-stl-title-xs-font-style;
      font-stretch: $font-stl-title-xs-font-stretch;
      letter-spacing: $font-stl-title-xs-letter-spacing + px;
      line-height: $font-stl-title-xs-line-height + px;
    }
    @else if($style == 'body-l') {
      font-family: $font-stl-body-l-font-family;
      font-size: $font-stl-body-l-font-size + px;
      font-weight: $font-stl-body-l-font-weight;
      text-decoration: $font-stl-body-l-text-decoration;
      font-style: $font-stl-body-l-font-style;
      font-stretch: $font-stl-body-l-font-stretch;
      letter-spacing: $font-stl-body-l-letter-spacing + px;
      line-height: $font-stl-body-l-line-height + px;
    }
    @else if($style == 'body-m') {
      font-family: $font-stl-body-m-font-family;
      font-size: $font-stl-body-m-font-size + px;
      font-weight: $font-stl-body-m-font-weight;
      text-decoration: $font-stl-body-m-text-decoration;
      font-style: $font-stl-body-m-font-style;
      font-stretch: $font-stl-body-m-font-stretch;
      letter-spacing: $font-stl-body-m-letter-spacing + px;
      line-height: $font-stl-body-m-line-height + px;
    }
    @else if($style == 'body-s') {
      font-family: $font-stl-body-s-font-family;
      font-size: $font-stl-body-s-font-size + px;
      font-weight: $font-stl-body-s-font-weight;
      text-decoration: $font-stl-body-s-text-decoration;
      font-style: $font-stl-body-s-font-style;
      font-stretch: $font-stl-body-s-font-stretch;
      letter-spacing: $font-stl-body-s-letter-spacing + px;
      line-height: $font-stl-body-s-line-height + px;
    }
    @else if($style == 'body-xs') {
      font-family: $font-stl-body-xs-font-family;
      font-size: $font-stl-body-xs-font-size + px;
      font-weight: $font-stl-body-xs-font-weight;
      text-decoration: $font-stl-body-xs-text-decoration;
      font-style: $font-stl-body-xs-font-style;
      font-stretch: $font-stl-body-xs-font-stretch;
      letter-spacing: $font-stl-body-xs-letter-spacing + px;
      line-height: $font-stl-body-xs-line-height + px;
    }
    @else if($style == 'body-xxs') {
      font-family: $font-stl-body-xxs-font-family;
      font-size: $font-stl-body-xxs-font-size + px;
      font-weight: $font-stl-body-xxs-font-weight;
      text-decoration: $font-stl-body-xxs-text-decoration;
      font-style: $font-stl-body-xxs-font-style;
      font-stretch: $font-stl-body-xxs-font-stretch;
      letter-spacing: $font-stl-body-xxs-letter-spacing + px;
      line-height: $font-stl-body-xxs-line-height + px;
    }
      // underline
    @else if($style == 'title-xxl-underline') {
      font-family: $font-stl-underline-title-xxl-underline-font-family;
      font-size: $font-stl-underline-title-xxl-underline-font-size + px;
      font-weight: $font-stl-underline-title-xxl-underline-font-weight;
      text-decoration: $font-stl-underline-title-xxl-underline-text-decoration;
      font-style: $font-stl-underline-title-xxl-underline-font-style;
      font-stretch: $font-stl-underline-title-xxl-underline-font-stretch;
      letter-spacing: $font-stl-underline-title-xxl-underline-letter-spacing + px;
      line-height: $font-stl-underline-title-xxl-underline-line-height + px;
    }
    @else if($style == 'title-xl-underline') {
      font-family: $font-stl-underline-title-xl-underline-font-family;
      font-size: $font-stl-underline-title-xl-underline-font-size + px;
      font-weight: $font-stl-underline-title-xl-underline-font-weight;
      text-decoration: $font-stl-underline-title-xl-underline-text-decoration;
      font-style: $font-stl-underline-title-xl-underline-font-style;
      font-stretch: $font-stl-underline-title-xl-underline-font-stretch;
      letter-spacing: $font-stl-underline-title-xl-underline-letter-spacing + px;
      line-height: $font-stl-underline-title-xl-underline-line-height + px;
    }
    @else if($style == 'title-l-underline') {
      font-family: $font-stl-underline-title-l-underline-font-family;
      font-size: $font-stl-underline-title-l-underline-font-size + px;
      font-weight: $font-stl-underline-title-l-underline-font-weight;
      text-decoration: $font-stl-underline-title-l-underline-text-decoration;
      font-style: $font-stl-underline-title-l-underline-font-style;
      font-stretch: $font-stl-underline-title-l-underline-font-stretch;
      letter-spacing: $font-stl-underline-title-l-underline-letter-spacing + px;
      line-height: $font-stl-underline-title-l-underline-line-height + px;
    }
    @else if($style == 'title-m-underline') {
      font-family: $font-stl-underline-title-m-underline-font-family;
      font-size: $font-stl-underline-title-m-underline-font-size + px;
      font-weight: $font-stl-underline-title-m-underline-font-weight;
      text-decoration: $font-stl-underline-title-m-underline-text-decoration;
      font-style: $font-stl-underline-title-m-underline-font-style;
      font-stretch: $font-stl-underline-title-m-underline-font-stretch;
      letter-spacing: $font-stl-underline-title-m-underline-letter-spacing + px;
      line-height: $font-stl-underline-title-m-underline-line-height + px;
    }
    @else if($style == 'title-s-underline') {
      font-family: $font-stl-underline-title-s-underline-font-family;
      font-size: $font-stl-underline-title-s-underline-font-size + px;
      font-weight: $font-stl-underline-title-s-underline-font-weight;
      text-decoration: $font-stl-underline-title-s-underline-text-decoration;
      font-style: $font-stl-underline-title-s-underline-font-style;
      font-stretch: $font-stl-underline-title-s-underline-font-stretch;
      letter-spacing: $font-stl-underline-title-s-underline-letter-spacing + px;
      line-height: $font-stl-underline-title-s-underline-line-height + px;
    }
    @else if($style == 'title-xs-underline') {
      font-family: $font-stl-underline-title-xs-underline-font-family;
      font-size: $font-stl-underline-title-xs-underline-font-size + px;
      font-weight: $font-stl-underline-title-xs-underline-font-weight;
      text-decoration: $font-stl-underline-title-xs-underline-text-decoration;
      font-style: $font-stl-underline-title-xs-underline-font-style;
      font-stretch: $font-stl-underline-title-xs-underline-font-stretch;
      letter-spacing: $font-stl-underline-title-xs-underline-letter-spacing + px;
      line-height: $font-stl-underline-title-xs-underline-line-height + px;
    }
    @else if($style == 'body-l-underline') {
      font-family: $font-stl-underline-body-l-underline-font-family;
      font-size: $font-stl-underline-body-l-underline-font-size + px;
      font-weight: $font-stl-underline-body-l-underline-font-weight;
      text-decoration: $font-stl-underline-body-l-underline-text-decoration;
      font-style: $font-stl-underline-body-l-underline-font-style;
      font-stretch: $font-stl-underline-body-l-underline-font-stretch;
      letter-spacing: $font-stl-underline-body-l-underline-letter-spacing + px;
      line-height: $font-stl-underline-body-l-underline-line-height + px;
    }
    @else if($style == 'body-m-underline') {
      font-family: $font-stl-underline-body-m-underline-font-family;
      font-size: $font-stl-underline-body-m-underline-font-size + px;
      font-weight: $font-stl-underline-body-m-underline-font-weight;
      text-decoration: $font-stl-underline-body-m-underline-text-decoration;
      font-style: $font-stl-underline-body-m-underline-font-style;
      font-stretch: $font-stl-underline-body-m-underline-font-stretch;
      letter-spacing: $font-stl-underline-body-m-underline-letter-spacing + px;
      line-height: $font-stl-underline-body-m-underline-line-height + px;
    }
    @else if($style == 'body-s-underline') {
      font-family: $font-stl-underline-body-s-underline-font-family;
      font-size: $font-stl-underline-body-s-underline-font-size + px;
      font-weight: $font-stl-underline-body-s-underline-font-weight;
      text-decoration: $font-stl-underline-body-s-underline-text-decoration;
      font-style: $font-stl-underline-body-s-underline-font-style;
      font-stretch: $font-stl-underline-body-s-underline-font-stretch;
      letter-spacing: $font-stl-underline-body-s-underline-letter-spacing + px;
      line-height: $font-stl-underline-body-s-underline-line-height + px;
    }
    @else if($style == 'body-xs-underline') {
      font-family: $font-stl-underline-body-xs-underline-font-family;
      font-size: $font-stl-underline-body-xs-underline-font-size + px;
      font-weight: $font-stl-underline-body-xs-underline-font-weight;
      text-decoration: $font-stl-underline-body-xs-underline-text-decoration;
      font-style: $font-stl-underline-body-xs-underline-font-style;
      font-stretch: $font-stl-underline-body-xs-underline-font-stretch;
      letter-spacing: $font-stl-underline-body-xs-underline-letter-spacing + px;
      line-height: $font-stl-underline-body-xs-underline-line-height + px;
    }
    @else if($style == 'body-xxs-underline') {
      font-family: $font-stl-underline-body-xxs-underline-font-family;
      font-size: $font-stl-underline-body-xxs-underline-font-size + px;
      font-weight: $font-stl-underline-body-xxs-underline-font-weight;
      text-decoration: $font-stl-underline-body-xxs-underline-text-decoration;
      font-style: $font-stl-underline-body-xxs-underline-font-style;
      font-stretch: $font-stl-underline-body-xxs-underline-font-stretch;
      letter-spacing: $font-stl-underline-body-xxs-underline-letter-spacing + px;
      line-height: $font-stl-underline-body-xxs-underline-line-height + px;
    }
      // bold
    @else if($style == 'title-xxl-bold') {
      font-family: $font-stl-bold-title-xxl-bold-font-family;
      font-size: $font-stl-bold-title-xxl-bold-font-size + px;
      font-weight: $font-stl-bold-title-xxl-bold-font-weight;
      text-decoration: $font-stl-bold-title-xxl-bold-text-decoration;
      font-style: $font-stl-bold-title-xxl-bold-font-style;
      font-stretch: $font-stl-bold-title-xxl-bold-font-stretch;
      letter-spacing: $font-stl-bold-title-xxl-bold-letter-spacing + px;
      line-height: $font-stl-bold-title-xxl-bold-line-height + px;
    }
    @else if($style == 'title-xl-bold') {
      font-family: $font-stl-bold-title-xl-bold-font-family;
      font-size: $font-stl-bold-title-xl-bold-font-size + px;
      font-weight: $font-stl-bold-title-xl-bold-font-weight;
      text-decoration: $font-stl-bold-title-xl-bold-text-decoration;
      font-style: $font-stl-bold-title-xl-bold-font-style;
      font-stretch: $font-stl-bold-title-xl-bold-font-stretch;
      letter-spacing: $font-stl-bold-title-xl-bold-letter-spacing + px;
      line-height: $font-stl-bold-title-xl-bold-line-height + px;
    }
    @else if($style == 'title-l-bold') {
      font-family: $font-stl-bold-title-l-bold-font-family;
      font-size: $font-stl-bold-title-l-bold-font-size + px;
      font-weight: $font-stl-bold-title-l-bold-font-weight;
      text-decoration: $font-stl-bold-title-l-bold-text-decoration;
      font-style: $font-stl-bold-title-l-bold-font-style;
      font-stretch: $font-stl-bold-title-l-bold-font-stretch;
      letter-spacing: $font-stl-bold-title-l-bold-letter-spacing + px;
      line-height: $font-stl-bold-title-l-bold-line-height + px;
    }
    @else if($style == 'title-m-bold') {
      font-family: $font-stl-bold-title-m-bold-font-family;
      font-size: $font-stl-bold-title-m-bold-font-size + px;
      font-weight: $font-stl-bold-title-m-bold-font-weight;
      text-decoration: $font-stl-bold-title-m-bold-text-decoration;
      font-style: $font-stl-bold-title-m-bold-font-style;
      font-stretch: $font-stl-bold-title-m-bold-font-stretch;
      letter-spacing: $font-stl-bold-title-m-bold-letter-spacing + px;
      line-height: $font-stl-bold-title-m-bold-line-height + px;
    }
    @else if($style == 'title-s-bold') {
      font-family: $font-stl-bold-title-s-bold-font-family;
      font-size: $font-stl-bold-title-s-bold-font-size + px;
      font-weight: $font-stl-bold-title-s-bold-font-weight;
      text-decoration: $font-stl-bold-title-s-bold-text-decoration;
      font-style: $font-stl-bold-title-s-bold-font-style;
      font-stretch: $font-stl-bold-title-s-bold-font-stretch;
      letter-spacing: $font-stl-bold-title-s-bold-letter-spacing + px;
      line-height: $font-stl-bold-title-s-bold-line-height + px;
    }
    @else if($style == 'title-xs-bold') {
      font-family: $font-stl-bold-title-xs-bold-font-family;
      font-size: $font-stl-bold-title-xs-bold-font-size + px;
      font-weight: $font-stl-bold-title-xs-bold-font-weight;
      text-decoration: $font-stl-bold-title-xs-bold-text-decoration;
      font-style: $font-stl-bold-title-xs-bold-font-style;
      font-stretch: $font-stl-bold-title-xs-bold-font-stretch;
      letter-spacing: $font-stl-bold-title-xs-bold-letter-spacing + px;
      line-height: $font-stl-bold-title-xs-bold-line-height + px;
    }
    @else if($style == 'body-l-bold') {
      font-family: $font-stl-bold-body-l-bold-font-family;
      font-size: $font-stl-bold-body-l-bold-font-size + px;
      font-weight: $font-stl-bold-body-l-bold-font-weight;
      text-decoration: $font-stl-bold-body-l-bold-text-decoration;
      font-style: $font-stl-bold-body-l-bold-font-style;
      font-stretch: $font-stl-bold-body-l-bold-font-stretch;
      letter-spacing: $font-stl-bold-body-l-bold-letter-spacing + px;
      line-height: $font-stl-bold-body-l-bold-line-height + px;
    }
    @else if($style == 'body-m-bold') {
      font-family: $font-stl-bold-body-m-bold-font-family;
      font-size: $font-stl-bold-body-m-bold-font-size + px;
      font-weight: $font-stl-bold-body-m-bold-font-weight;
      text-decoration: $font-stl-bold-body-m-bold-text-decoration;
      font-style: $font-stl-bold-body-m-bold-font-style;
      font-stretch: $font-stl-bold-body-m-bold-font-stretch;
      letter-spacing: $font-stl-bold-body-m-bold-letter-spacing + px;
      line-height: $font-stl-bold-body-m-bold-line-height + px;
    }
    @else if($style == 'body-s-bold') {
      font-family: $font-stl-bold-body-s-bold-font-family;
      font-size: $font-stl-bold-body-s-bold-font-size + px;
      font-weight: $font-stl-bold-body-s-bold-font-weight;
      text-decoration: $font-stl-bold-body-s-bold-text-decoration;
      font-style: $font-stl-bold-body-s-bold-font-style;
      font-stretch: $font-stl-bold-body-s-bold-font-stretch;
      letter-spacing: $font-stl-bold-body-s-bold-letter-spacing + px;
      line-height: $font-stl-bold-body-s-bold-line-height + px;
    }
    @else if($style == 'body-xs-bold') {
      font-family: $font-stl-bold-body-xs-bold-font-family;
      font-size: $font-stl-bold-body-xs-bold-font-size + px;
      font-weight: $font-stl-bold-body-xs-bold-font-weight;
      text-decoration: $font-stl-bold-body-xs-bold-text-decoration;
      font-style: $font-stl-bold-body-xs-bold-font-style;
      font-stretch: $font-stl-bold-body-xs-bold-font-stretch;
      letter-spacing: $font-stl-bold-body-xs-bold-letter-spacing + px;
      line-height: $font-stl-bold-body-xs-bold-line-height + px;
    }
    @else if($style == 'body-xxs-bold') {
      font-family: $font-stl-bold-body-xxs-bold-font-family;
      font-size: $font-stl-bold-body-xxs-bold-font-size + px;
      font-weight: $font-stl-bold-body-xxs-bold-font-weight;
      text-decoration: $font-stl-bold-body-xxs-bold-text-decoration;
      font-style: $font-stl-bold-body-xxs-bold-font-style;
      font-stretch: $font-stl-bold-body-xxs-bold-font-stretch;
      letter-spacing: $font-stl-bold-body-xxs-bold-letter-spacing + px;
      line-height: $font-stl-bold-body-xxs-bold-line-height + px;
    }
      // bold & underline
    @else if($style == 'title-xxl-bold-underline') {
      font-family: $font-stl-bold-underline-title-xxl-bu-font-family;
      font-size: $font-stl-bold-underline-title-xxl-bu-font-size + px;
      font-weight: $font-stl-bold-underline-title-xxl-bu-font-weight;
      text-decoration: $font-stl-bold-underline-title-xxl-bu-text-decoration;
      font-style: $font-stl-bold-underline-title-xxl-bu-font-style;
      font-stretch: $font-stl-bold-underline-title-xxl-bu-font-stretch;
      letter-spacing: $font-stl-bold-underline-title-xxl-bu-letter-spacing + px;
      line-height: $font-stl-bold-underline-title-xxl-bu-line-height + px;
    }
    @else if($style == 'title-xl-bold-underline') {
      font-family: $font-stl-bold-underline-title-xl-bu-font-family;
      font-size: $font-stl-bold-underline-title-xl-bu-font-size + px;
      font-weight: $font-stl-bold-underline-title-xl-bu-font-weight;
      text-decoration: $font-stl-bold-underline-title-xl-bu-text-decoration;
      font-style: $font-stl-bold-underline-title-xl-bu-font-style;
      font-stretch: $font-stl-bold-underline-title-xl-bu-font-stretch;
      letter-spacing: $font-stl-bold-underline-title-xl-bu-letter-spacing + px;
      line-height: $font-stl-bold-underline-title-xl-bu-line-height + px;
    }
    @else if($style == 'title-l-bold-underline') {
      font-family: $font-stl-bold-underline-title-l-bu-font-family;
      font-size: $font-stl-bold-underline-title-l-bu-font-size + px;
      font-weight: $font-stl-bold-underline-title-l-bu-font-weight;
      text-decoration: $font-stl-bold-underline-title-l-bu-text-decoration;
      font-style: $font-stl-bold-underline-title-l-bu-font-style;
      font-stretch: $font-stl-bold-underline-title-l-bu-font-stretch;
      letter-spacing: $font-stl-bold-underline-title-l-bu-letter-spacing + px;
      line-height: $font-stl-bold-underline-title-l-bu-line-height + px;
    }
    @else if($style == 'title-m-bold-underline') {
      font-family: $font-stl-bold-underline-title-m-bu-font-family;
      font-size: $font-stl-bold-underline-title-m-bu-font-size + px;
      font-weight: $font-stl-bold-underline-title-m-bu-font-weight;
      text-decoration: $font-stl-bold-underline-title-m-bu-text-decoration;
      font-style: $font-stl-bold-underline-title-m-bu-font-style;
      font-stretch: $font-stl-bold-underline-title-m-bu-font-stretch;
      letter-spacing: $font-stl-bold-underline-title-m-bu-letter-spacing + px;
      line-height: $font-stl-bold-underline-title-m-bu-line-height + px;
    }
    @else if($style == 'title-s-bold-underline') {
      font-family: $font-stl-bold-underline-title-s-bu-font-family;
      font-size: $font-stl-bold-underline-title-s-bu-font-size + px;
      font-weight: $font-stl-bold-underline-title-s-bu-font-weight;
      text-decoration: $font-stl-bold-underline-title-s-bu-text-decoration;
      font-style: $font-stl-bold-underline-title-s-bu-font-style;
      font-stretch: $font-stl-bold-underline-title-s-bu-font-stretch;
      letter-spacing: $font-stl-bold-underline-title-s-bu-letter-spacing + px;
      line-height: $font-stl-bold-underline-title-s-bu-line-height + px;
    }
    @else if($style == 'title-xs-bold-underline') {
      font-family: $font-stl-bold-underline-title-xs-bu-font-family;
      font-size: $font-stl-bold-underline-title-xs-bu-font-size + px;
      font-weight: $font-stl-bold-underline-title-xs-bu-font-weight;
      text-decoration: $font-stl-bold-underline-title-xs-bu-text-decoration;
      font-style: $font-stl-bold-underline-title-xs-bu-font-style;
      font-stretch: $font-stl-bold-underline-title-xs-bu-font-stretch;
      letter-spacing: $font-stl-bold-underline-title-xs-bu-letter-spacing + px;
      line-height: $font-stl-bold-underline-title-xs-bu-line-height + px;
    }
    @else if($style == 'body-l-bold-underline') {
      font-family: $font-stl-bold-underline-body-l-bu-font-family;
      font-size: $font-stl-bold-underline-body-l-bu-font-size + px;
      font-weight: $font-stl-bold-underline-body-l-bu-font-weight;
      text-decoration: $font-stl-bold-underline-body-l-bu-text-decoration;
      font-style: $font-stl-bold-underline-body-l-bu-font-style;
      font-stretch: $font-stl-bold-underline-body-l-bu-font-stretch;
      letter-spacing: $font-stl-bold-underline-body-l-bu-letter-spacing + px;
      line-height: $font-stl-bold-underline-body-l-bu-line-height + px;
    }
    @else if($style == 'body-m-bold-underline') {
      font-family: $font-stl-bold-underline-body-m-bu-font-family;
      font-size: $font-stl-bold-underline-body-m-bu-font-size + px;
      font-weight: $font-stl-bold-underline-body-m-bu-font-weight;
      text-decoration: $font-stl-bold-underline-body-m-bu-text-decoration;
      font-style: $font-stl-bold-underline-body-m-bu-font-style;
      font-stretch: $font-stl-bold-underline-body-m-bu-font-stretch;
      letter-spacing: $font-stl-bold-underline-body-m-bu-letter-spacing + px;
      line-height: $font-stl-bold-underline-body-m-bu-line-height + px;
    }
    @else if($style == 'body-s-bold-underline') {
      font-family: $font-stl-bold-underline-body-s-bu-font-family;
      font-size: $font-stl-bold-underline-body-s-bu-font-size + px;
      font-weight: $font-stl-bold-underline-body-s-bu-font-weight;
      text-decoration: $font-stl-bold-underline-body-s-bu-text-decoration;
      font-style: $font-stl-bold-underline-body-s-bu-font-style;
      font-stretch: $font-stl-bold-underline-body-s-bu-font-stretch;
      letter-spacing: $font-stl-bold-underline-body-s-bu-letter-spacing + px;
      line-height: $font-stl-bold-underline-body-s-bu-line-height + px;
    }
    @else if($style == 'body-xs-bold-underline') {
      font-family: $font-stl-bold-underline-body-xs-bu-font-family;
      font-size: $font-stl-bold-underline-body-xs-bu-font-size + px;
      font-weight: $font-stl-bold-underline-body-xs-bu-font-weight;
      text-decoration: $font-stl-bold-underline-body-xs-bu-text-decoration;
      font-style: $font-stl-bold-underline-body-xs-bu-font-style;
      font-stretch: $font-stl-bold-underline-body-xs-bu-font-stretch;
      letter-spacing: $font-stl-bold-underline-body-xs-bu-letter-spacing + px;
      line-height: $font-stl-bold-underline-body-xs-bu-line-height + px;
    }
    @else if($style == 'body-xxs-bold-underline') {
      font-family: $font-stl-bold-underline-body-xxs-bu-font-family;
      font-size: $font-stl-bold-underline-body-xxs-bu-font-size + px;
      font-weight: $font-stl-bold-underline-body-xxs-bu-font-weight;
      text-decoration: $font-stl-bold-underline-body-xxs-bu-text-decoration;
      font-style: $font-stl-bold-underline-body-xxs-bu-font-style;
      font-stretch: $font-stl-bold-underline-body-xxs-bu-font-stretch;
      letter-spacing: $font-stl-bold-underline-body-xxs-bu-letter-spacing + px;
      line-height: $font-stl-bold-underline-body-xxs-bu-line-height + px;
    }
  }
  @else if($brand == 'wl') {
    @if($style == 'title-xxl') {
      font-family: $font-wl-title-xxl-font-family;
      font-size: $font-wl-title-xxl-font-size + px;
      font-weight: $font-wl-title-xxl-font-weight;
      text-decoration: $font-wl-title-xxl-text-decoration;
      font-style: $font-wl-title-xxl-font-style;
      font-stretch: $font-wl-title-xxl-font-stretch;
      letter-spacing: $font-wl-title-xxl-letter-spacing + px;
      line-height: $font-wl-title-xxl-line-height + px;
    }
    @else if($style == 'title-xl') {
      font-family: $font-wl-title-xl-font-family;
      font-size: $font-wl-title-xl-font-size + px;
      font-weight: $font-wl-title-xl-font-weight;
      text-decoration: $font-wl-title-xl-text-decoration;
      font-style: $font-wl-title-xl-font-style;
      font-stretch: $font-wl-title-xl-font-stretch;
      letter-spacing: $font-wl-title-xl-letter-spacing + px;
      line-height: $font-wl-title-xl-line-height + px;
    }
    @else if($style == 'title-l') {
      font-family: $font-wl-title-l-font-family;
      font-size: $font-wl-title-l-font-size + px;
      font-weight: $font-wl-title-l-font-weight;
      text-decoration: $font-wl-title-l-text-decoration;
      font-style: $font-wl-title-l-font-style;
      font-stretch: $font-wl-title-l-font-stretch;
      letter-spacing: $font-wl-title-l-letter-spacing + px;
      line-height: $font-wl-title-l-line-height + px;
    }
    @else if($style == 'title-m') {
      font-family: $font-wl-title-m-font-family;
      font-size: $font-wl-title-m-font-size + px;
      font-weight: $font-wl-title-m-font-weight;
      text-decoration: $font-wl-title-m-text-decoration;
      font-style: $font-wl-title-m-font-style;
      font-stretch: $font-wl-title-m-font-stretch;
      letter-spacing: $font-wl-title-m-letter-spacing + px;
      line-height: $font-wl-title-m-line-height + px;
    }
    @else if($style == 'title-s') {
      font-family: $font-wl-title-s-font-family;
      font-size: $font-wl-title-s-font-size + px;
      font-weight: $font-wl-title-s-font-weight;
      text-decoration: $font-wl-title-s-text-decoration;
      font-style: $font-wl-title-s-font-style;
      font-stretch: $font-wl-title-s-font-stretch;
      letter-spacing: $font-wl-title-s-letter-spacing + px;
      line-height: $font-wl-title-s-line-height + px;
    }
    @else if($style == 'title-xs') {
      font-family: $font-wl-title-xs-font-family;
      font-size: $font-wl-title-xs-font-size + px;
      font-weight: $font-wl-title-xs-font-weight;
      text-decoration: $font-wl-title-xs-text-decoration;
      font-style: $font-wl-title-xs-font-style;
      font-stretch: $font-wl-title-xs-font-stretch;
      letter-spacing: $font-wl-title-xs-letter-spacing + px;
      line-height: $font-wl-title-xs-line-height + px;
    }
    @else if($style == 'body-l') {
      font-family: $font-wl-body-l-font-family;
      font-size: $font-wl-body-l-font-size + px;
      font-weight: $font-wl-body-l-font-weight;
      text-decoration: $font-wl-body-l-text-decoration;
      font-style: $font-wl-body-l-font-style;
      font-stretch: $font-wl-body-l-font-stretch;
      letter-spacing: $font-wl-body-l-letter-spacing + px;
      line-height: $font-wl-body-l-line-height + px;
    }
    @else if($style == 'body-m') {
      font-family: $font-wl-body-m-font-family;
      font-size: $font-wl-body-m-font-size + px;
      font-weight: $font-wl-body-m-font-weight;
      text-decoration: $font-wl-body-m-text-decoration;
      font-style: $font-wl-body-m-font-style;
      font-stretch: $font-wl-body-m-font-stretch;
      letter-spacing: $font-wl-body-m-letter-spacing + px;
      line-height: $font-wl-body-m-line-height + px;
    }
    @else if($style == 'body-s') {
      font-family: $font-wl-body-s-font-family;
      font-size: $font-wl-body-s-font-size + px;
      font-weight: $font-wl-body-s-font-weight;
      text-decoration: $font-wl-body-s-text-decoration;
      font-style: $font-wl-body-s-font-style;
      font-stretch: $font-wl-body-s-font-stretch;
      letter-spacing: $font-wl-body-s-letter-spacing + px;
      line-height: $font-wl-body-s-line-height + px;
    }
    @else if($style == 'body-xs') {
      font-family: $font-wl-body-xs-font-family;
      font-size: $font-wl-body-xs-font-size + px;
      font-weight: $font-wl-body-xs-font-weight;
      text-decoration: $font-wl-body-xs-text-decoration;
      font-style: $font-wl-body-xs-font-style;
      font-stretch: $font-wl-body-xs-font-stretch;
      letter-spacing: $font-wl-body-xs-letter-spacing + px;
      line-height: $font-wl-body-xs-line-height + px;
    }
    @else if($style == 'body-xxs') {
      font-family: $font-wl-body-xxs-font-family;
      font-size: $font-wl-body-xxs-font-size + px;
      font-weight: $font-wl-body-xxs-font-weight;
      text-decoration: $font-wl-body-xxs-text-decoration;
      font-style: $font-wl-body-xxs-font-style;
      font-stretch: $font-wl-body-xxs-font-stretch;
      letter-spacing: $font-wl-body-xxs-letter-spacing + px;
      line-height: $font-wl-body-xxs-line-height + px;
    }
    // underline
    @else if($style == 'title-xxl-underline') {
      font-family: $font-wl-underline-title-xxl-underline-font-family;
      font-size: $font-wl-underline-title-xxl-underline-font-size + px;
      font-weight: $font-wl-underline-title-xxl-underline-font-weight;
      text-decoration: $font-wl-underline-title-xxl-underline-text-decoration;
      font-style: $font-wl-underline-title-xxl-underline-font-style;
      font-stretch: $font-wl-underline-title-xxl-underline-font-stretch;
      letter-spacing: $font-wl-underline-title-xxl-underline-letter-spacing + px;
      line-height: $font-wl-underline-title-xxl-underline-line-height + px;
    }
    @else if($style == 'title-xl-underline') {
      font-family: $font-wl-underline-title-xl-underline-font-family;
      font-size: $font-wl-underline-title-xl-underline-font-size + px;
      font-weight: $font-wl-underline-title-xl-underline-font-weight;
      text-decoration: $font-wl-underline-title-xl-underline-text-decoration;
      font-style: $font-wl-underline-title-xl-underline-font-style;
      font-stretch: $font-wl-underline-title-xl-underline-font-stretch;
      letter-spacing: $font-wl-underline-title-xl-underline-letter-spacing + px;
      line-height: $font-wl-underline-title-xl-underline-line-height + px;
    }
    @else if($style == 'title-l-underline') {
      font-family: $font-wl-underline-title-l-underline-font-family;
      font-size: $font-wl-underline-title-l-underline-font-size + px;
      font-weight: $font-wl-underline-title-l-underline-font-weight;
      text-decoration: $font-wl-underline-title-l-underline-text-decoration;
      font-style: $font-wl-underline-title-l-underline-font-style;
      font-stretch: $font-wl-underline-title-l-underline-font-stretch;
      letter-spacing: $font-wl-underline-title-l-underline-letter-spacing + px;
      line-height: $font-wl-underline-title-l-underline-line-height + px;
    }
    @else if($style == 'title-m-underline') {
      font-family: $font-wl-underline-title-m-underline-font-family;
      font-size: $font-wl-underline-title-m-underline-font-size + px;
      font-weight: $font-wl-underline-title-m-underline-font-weight;
      text-decoration: $font-wl-underline-title-m-underline-text-decoration;
      font-style: $font-wl-underline-title-m-underline-font-style;
      font-stretch: $font-wl-underline-title-m-underline-font-stretch;
      letter-spacing: $font-wl-underline-title-m-underline-letter-spacing + px;
      line-height: $font-wl-underline-title-m-underline-line-height + px;
    }
    @else if($style == 'title-s-underline') {
      font-family: $font-wl-underline-title-s-underline-font-family;
      font-size: $font-wl-underline-title-s-underline-font-size + px;
      font-weight: $font-wl-underline-title-s-underline-font-weight;
      text-decoration: $font-wl-underline-title-s-underline-text-decoration;
      font-style: $font-wl-underline-title-s-underline-font-style;
      font-stretch: $font-wl-underline-title-s-underline-font-stretch;
      letter-spacing: $font-wl-underline-title-s-underline-letter-spacing + px;
      line-height: $font-wl-underline-title-s-underline-line-height + px;
    }
    @else if($style == 'title-xs-underline') {
      font-family: $font-wl-underline-title-xs-underline-font-family;
      font-size: $font-wl-underline-title-xs-underline-font-size + px;
      font-weight: $font-wl-underline-title-xs-underline-font-weight;
      text-decoration: $font-wl-underline-title-xs-underline-text-decoration;
      font-style: $font-wl-underline-title-xs-underline-font-style;
      font-stretch: $font-wl-underline-title-xs-underline-font-stretch;
      letter-spacing: $font-wl-underline-title-xs-underline-letter-spacing + px;
      line-height: $font-wl-underline-title-xs-underline-line-height + px;
    }
    @else if($style == 'body-l-underline') {
      font-family: $font-wl-underline-body-l-underline-font-family;
      font-size: $font-wl-underline-body-l-underline-font-size + px;
      font-weight: $font-wl-underline-body-l-underline-font-weight;
      text-decoration: $font-wl-underline-body-l-underline-text-decoration;
      font-style: $font-wl-underline-body-l-underline-font-style;
      font-stretch: $font-wl-underline-body-l-underline-font-stretch;
      letter-spacing: $font-wl-underline-body-l-underline-letter-spacing + px;
      line-height: $font-wl-underline-body-l-underline-line-height + px;
    }
    @else if($style == 'body-m-underline') {
      font-family: $font-wl-underline-body-m-underline-font-family;
      font-size: $font-wl-underline-body-m-underline-font-size + px;
      font-weight: $font-wl-underline-body-m-underline-font-weight;
      text-decoration: $font-wl-underline-body-m-underline-text-decoration;
      font-style: $font-wl-underline-body-m-underline-font-style;
      font-stretch: $font-wl-underline-body-m-underline-font-stretch;
      letter-spacing: $font-wl-underline-body-m-underline-letter-spacing + px;
      line-height: $font-wl-underline-body-m-underline-line-height + px;
    }
    @else if($style == 'body-s-underline') {
      font-family: $font-wl-underline-body-s-underline-font-family;
      font-size: $font-wl-underline-body-s-underline-font-size + px;
      font-weight: $font-wl-underline-body-s-underline-font-weight;
      text-decoration: $font-wl-underline-body-s-underline-text-decoration;
      font-style: $font-wl-underline-body-s-underline-font-style;
      font-stretch: $font-wl-underline-body-s-underline-font-stretch;
      letter-spacing: $font-wl-underline-body-s-underline-letter-spacing + px;
      line-height: $font-wl-underline-body-s-underline-line-height + px;
    }
    @else if($style == 'body-xs-underline') {
      font-family: $font-wl-underline-body-xs-underline-font-family;
      font-size: $font-wl-underline-body-xs-underline-font-size + px;
      font-weight: $font-wl-underline-body-xs-underline-font-weight;
      text-decoration: $font-wl-underline-body-xs-underline-text-decoration;
      font-style: $font-wl-underline-body-xs-underline-font-style;
      font-stretch: $font-wl-underline-body-xs-underline-font-stretch;
      letter-spacing: $font-wl-underline-body-xs-underline-letter-spacing + px;
      line-height: $font-wl-underline-body-xs-underline-line-height + px;
    }
    @else if($style == 'body-xxs-underline') {
      font-family: $font-wl-underline-body-xxs-underline-font-family;
      font-size: $font-wl-underline-body-xxs-underline-font-size + px;
      font-weight: $font-wl-underline-body-xxs-underline-font-weight;
      text-decoration: $font-wl-underline-body-xxs-underline-text-decoration;
      font-style: $font-wl-underline-body-xxs-underline-font-style;
      font-stretch: $font-wl-underline-body-xxs-underline-font-stretch;
      letter-spacing: $font-wl-underline-body-xxs-underline-letter-spacing + px;
      line-height: $font-wl-underline-body-xxs-underline-line-height + px;
    }
    // bold
    @else if($style == 'title-xxl-bold') {
      font-family: $font-wl-bold-title-xxl-bold-font-family;
      font-size: $font-wl-bold-title-xxl-bold-font-size + px;
      font-weight: $font-wl-bold-title-xxl-bold-font-weight;
      text-decoration: $font-wl-bold-title-xxl-bold-text-decoration;
      font-style: $font-wl-bold-title-xxl-bold-font-style;
      font-stretch: $font-wl-bold-title-xxl-bold-font-stretch;
      letter-spacing: $font-wl-bold-title-xxl-bold-letter-spacing + px;
      line-height: $font-wl-bold-title-xxl-bold-line-height + px;
    }
    @else if($style == 'title-xl-bold') {
      font-family: $font-wl-bold-title-xl-bold-font-family;
      font-size: $font-wl-bold-title-xl-bold-font-size + px;
      font-weight: $font-wl-bold-title-xl-bold-font-weight;
      text-decoration: $font-wl-bold-title-xl-bold-text-decoration;
      font-style: $font-wl-bold-title-xl-bold-font-style;
      font-stretch: $font-wl-bold-title-xl-bold-font-stretch;
      letter-spacing: $font-wl-bold-title-xl-bold-letter-spacing + px;
      line-height: $font-wl-bold-title-xl-bold-line-height + px;
    }
    @else if($style == 'title-l-bold') {
      font-family: $font-wl-bold-title-l-bold-font-family;
      font-size: $font-wl-bold-title-l-bold-font-size + px;
      font-weight: $font-wl-bold-title-l-bold-font-weight;
      text-decoration: $font-wl-bold-title-l-bold-text-decoration;
      font-style: $font-wl-bold-title-l-bold-font-style;
      font-stretch: $font-wl-bold-title-l-bold-font-stretch;
      letter-spacing: $font-wl-bold-title-l-bold-letter-spacing + px;
      line-height: $font-wl-bold-title-l-bold-line-height + px;
    }
    @else if($style == 'title-m-bold') {
      font-family: $font-wl-bold-title-m-bold-font-family;
      font-size: $font-wl-bold-title-m-bold-font-size + px;
      font-weight: $font-wl-bold-title-m-bold-font-weight;
      text-decoration: $font-wl-bold-title-m-bold-text-decoration;
      font-style: $font-wl-bold-title-m-bold-font-style;
      font-stretch: $font-wl-bold-title-m-bold-font-stretch;
      letter-spacing: $font-wl-bold-title-m-bold-letter-spacing + px;
      line-height: $font-wl-bold-title-m-bold-line-height + px;
    }
    @else if($style == 'title-s-bold') {
      font-family: $font-wl-bold-title-s-bold-font-family;
      font-size: $font-wl-bold-title-s-bold-font-size + px;
      font-weight: $font-wl-bold-title-s-bold-font-weight;
      text-decoration: $font-wl-bold-title-s-bold-text-decoration;
      font-style: $font-wl-bold-title-s-bold-font-style;
      font-stretch: $font-wl-bold-title-s-bold-font-stretch;
      letter-spacing: $font-wl-bold-title-s-bold-letter-spacing + px;
      line-height: $font-wl-bold-title-s-bold-line-height + px;
    }
    @else if($style == 'title-xs-bold') {
      font-family: $font-wl-bold-title-xs-bold-font-family;
      font-size: $font-wl-bold-title-xs-bold-font-size + px;
      font-weight: $font-wl-bold-title-xs-bold-font-weight;
      text-decoration: $font-wl-bold-title-xs-bold-text-decoration;
      font-style: $font-wl-bold-title-xs-bold-font-style;
      font-stretch: $font-wl-bold-title-xs-bold-font-stretch;
      letter-spacing: $font-wl-bold-title-xs-bold-letter-spacing + px;
      line-height: $font-wl-bold-title-xs-bold-line-height + px;
    }
    @else if($style == 'body-l-bold') {
      font-family: $font-wl-bold-body-l-bold-font-family;
      font-size: $font-wl-bold-body-l-bold-font-size + px;
      font-weight: $font-wl-bold-body-l-bold-font-weight;
      text-decoration: $font-wl-bold-body-l-bold-text-decoration;
      font-style: $font-wl-bold-body-l-bold-font-style;
      font-stretch: $font-wl-bold-body-l-bold-font-stretch;
      letter-spacing: $font-wl-bold-body-l-bold-letter-spacing + px;
      line-height: $font-wl-bold-body-l-bold-line-height + px;
    }
    @else if($style == 'body-m-bold') {
      font-family: $font-wl-bold-body-m-bold-font-family;
      font-size: $font-wl-bold-body-m-bold-font-size + px;
      font-weight: $font-wl-bold-body-m-bold-font-weight;
      text-decoration: $font-wl-bold-body-m-bold-text-decoration;
      font-style: $font-wl-bold-body-m-bold-font-style;
      font-stretch: $font-wl-bold-body-m-bold-font-stretch;
      letter-spacing: $font-wl-bold-body-m-bold-letter-spacing + px;
      line-height: $font-wl-bold-body-m-bold-line-height + px;
    }
    @else if($style == 'body-s-bold') {
      font-family: $font-wl-bold-body-s-bold-font-family;
      font-size: $font-wl-bold-body-s-bold-font-size + px;
      font-weight: $font-wl-bold-body-s-bold-font-weight;
      text-decoration: $font-wl-bold-body-s-bold-text-decoration;
      font-style: $font-wl-bold-body-s-bold-font-style;
      font-stretch: $font-wl-bold-body-s-bold-font-stretch;
      letter-spacing: $font-wl-bold-body-s-bold-letter-spacing + px;
      line-height: $font-wl-bold-body-s-bold-line-height + px;
    }
    @else if($style == 'body-xs-bold') {
      font-family: $font-wl-bold-body-xs-bold-font-family;
      font-size: $font-wl-bold-body-xs-bold-font-size + px;
      font-weight: $font-wl-bold-body-xs-bold-font-weight;
      text-decoration: $font-wl-bold-body-xs-bold-text-decoration;
      font-style: $font-wl-bold-body-xs-bold-font-style;
      font-stretch: $font-wl-bold-body-xs-bold-font-stretch;
      letter-spacing: $font-wl-bold-body-xs-bold-letter-spacing + px;
      line-height: $font-wl-bold-body-xs-bold-line-height + px;
    }
    @else if($style == 'body-xxs-bold') {
      font-family: $font-wl-bold-body-xxs-bold-font-family;
      font-size: $font-wl-bold-body-xxs-bold-font-size + px;
      font-weight: $font-wl-bold-body-xxs-bold-font-weight;
      text-decoration: $font-wl-bold-body-xxs-bold-text-decoration;
      font-style: $font-wl-bold-body-xxs-bold-font-style;
      font-stretch: $font-wl-bold-body-xxs-bold-font-stretch;
      letter-spacing: $font-wl-bold-body-xxs-bold-letter-spacing + px;
      line-height: $font-wl-bold-body-xxs-bold-line-height + px;
    }
    // bold & underline
    @else if($style == 'title-xxl-bold-underline') {
      font-family: $font-wl-bold-underline-title-xxl-bu-font-family;
      font-size: $font-wl-bold-underline-title-xxl-bu-font-size + px;
      font-weight: $font-wl-bold-underline-title-xxl-bu-font-weight;
      text-decoration: $font-wl-bold-underline-title-xxl-bu-text-decoration;
      font-style: $font-wl-bold-underline-title-xxl-bu-font-style;
      font-stretch: $font-wl-bold-underline-title-xxl-bu-font-stretch;
      letter-spacing: $font-wl-bold-underline-title-xxl-bu-letter-spacing + px;
      line-height: $font-wl-bold-underline-title-xxl-bu-line-height + px;
    }
    @else if($style == 'title-xl-bold-underline') {
      font-family: $font-wl-bold-underline-title-xl-bu-font-family;
      font-size: $font-wl-bold-underline-title-xl-bu-font-size + px;
      font-weight: $font-wl-bold-underline-title-xl-bu-font-weight;
      text-decoration: $font-wl-bold-underline-title-xl-bu-text-decoration;
      font-style: $font-wl-bold-underline-title-xl-bu-font-style;
      font-stretch: $font-wl-bold-underline-title-xl-bu-font-stretch;
      letter-spacing: $font-wl-bold-underline-title-xl-bu-letter-spacing + px;
      line-height: $font-wl-bold-underline-title-xl-bu-line-height + px;
    }
    @else if($style == 'title-l-bold-underline') {
      font-family: $font-wl-bold-underline-title-l-bu-font-family;
      font-size: $font-wl-bold-underline-title-l-bu-font-size + px;
      font-weight: $font-wl-bold-underline-title-l-bu-font-weight;
      text-decoration: $font-wl-bold-underline-title-l-bu-text-decoration;
      font-style: $font-wl-bold-underline-title-l-bu-font-style;
      font-stretch: $font-wl-bold-underline-title-l-bu-font-stretch;
      letter-spacing: $font-wl-bold-underline-title-l-bu-letter-spacing + px;
      line-height: $font-wl-bold-underline-title-l-bu-line-height + px;
    }
    @else if($style == 'title-m-bold-underline') {
      font-family: $font-wl-bold-underline-title-m-bu-font-family;
      font-size: $font-wl-bold-underline-title-m-bu-font-size + px;
      font-weight: $font-wl-bold-underline-title-m-bu-font-weight;
      text-decoration: $font-wl-bold-underline-title-m-bu-text-decoration;
      font-style: $font-wl-bold-underline-title-m-bu-font-style;
      font-stretch: $font-wl-bold-underline-title-m-bu-font-stretch;
      letter-spacing: $font-wl-bold-underline-title-m-bu-letter-spacing + px;
      line-height: $font-wl-bold-underline-title-m-bu-line-height + px;
    }
    @else if($style == 'title-s-bold-underline') {
      font-family: $font-wl-bold-underline-title-s-bu-font-family;
      font-size: $font-wl-bold-underline-title-s-bu-font-size + px;
      font-weight: $font-wl-bold-underline-title-s-bu-font-weight;
      text-decoration: $font-wl-bold-underline-title-s-bu-text-decoration;
      font-style: $font-wl-bold-underline-title-s-bu-font-style;
      font-stretch: $font-wl-bold-underline-title-s-bu-font-stretch;
      letter-spacing: $font-wl-bold-underline-title-s-bu-letter-spacing + px;
      line-height: $font-wl-bold-underline-title-s-bu-line-height + px;
    }
    @else if($style == 'title-xs-bold-underline') {
      font-family: $font-wl-bold-underline-title-xs-bu-font-family;
      font-size: $font-wl-bold-underline-title-xs-bu-font-size + px;
      font-weight: $font-wl-bold-underline-title-xs-bu-font-weight;
      text-decoration: $font-wl-bold-underline-title-xs-bu-text-decoration;
      font-style: $font-wl-bold-underline-title-xs-bu-font-style;
      font-stretch: $font-wl-bold-underline-title-xs-bu-font-stretch;
      letter-spacing: $font-wl-bold-underline-title-xs-bu-letter-spacing + px;
      line-height: $font-wl-bold-underline-title-xs-bu-line-height + px;
    }
    @else if($style == 'body-l-bold-underline') {
      font-family: $font-wl-bold-underline-body-l-bu-font-family;
      font-size: $font-wl-bold-underline-body-l-bu-font-size + px;
      font-weight: $font-wl-bold-underline-body-l-bu-font-weight;
      text-decoration: $font-wl-bold-underline-body-l-bu-text-decoration;
      font-style: $font-wl-bold-underline-body-l-bu-font-style;
      font-stretch: $font-wl-bold-underline-body-l-bu-font-stretch;
      letter-spacing: $font-wl-bold-underline-body-l-bu-letter-spacing + px;
      line-height: $font-wl-bold-underline-body-l-bu-line-height + px;
    }
    @else if($style == 'body-m-bold-underline') {
      font-family: $font-wl-bold-underline-body-m-bu-font-family;
      font-size: $font-wl-bold-underline-body-m-bu-font-size + px;
      font-weight: $font-wl-bold-underline-body-m-bu-font-weight;
      text-decoration: $font-wl-bold-underline-body-m-bu-text-decoration;
      font-style: $font-wl-bold-underline-body-m-bu-font-style;
      font-stretch: $font-wl-bold-underline-body-m-bu-font-stretch;
      letter-spacing: $font-wl-bold-underline-body-m-bu-letter-spacing + px;
      line-height: $font-wl-bold-underline-body-m-bu-line-height + px;
    }
    @else if($style == 'body-s-bold-underline') {
      font-family: $font-wl-bold-underline-body-s-bu-font-family;
      font-size: $font-wl-bold-underline-body-s-bu-font-size + px;
      font-weight: $font-wl-bold-underline-body-s-bu-font-weight;
      text-decoration: $font-wl-bold-underline-body-s-bu-text-decoration;
      font-style: $font-wl-bold-underline-body-s-bu-font-style;
      font-stretch: $font-wl-bold-underline-body-s-bu-font-stretch;
      letter-spacing: $font-wl-bold-underline-body-s-bu-letter-spacing + px;
      line-height: $font-wl-bold-underline-body-s-bu-line-height + px;
    }
    @else if($style == 'body-xs-bold-underline') {
      font-family: $font-wl-bold-underline-body-xs-bu-font-family;
      font-size: $font-wl-bold-underline-body-xs-bu-font-size + px;
      font-weight: $font-wl-bold-underline-body-xs-bu-font-weight;
      text-decoration: $font-wl-bold-underline-body-xs-bu-text-decoration;
      font-style: $font-wl-bold-underline-body-xs-bu-font-style;
      font-stretch: $font-wl-bold-underline-body-xs-bu-font-stretch;
      letter-spacing: $font-wl-bold-underline-body-xs-bu-letter-spacing + px;
      line-height: $font-wl-bold-underline-body-xs-bu-line-height + px;
    }
    @else if($style == 'body-xxs-bold-underline') {
      font-family: $font-wl-bold-underline-body-xxs-bu-font-family;
      font-size: $font-wl-bold-underline-body-xxs-bu-font-size + px;
      font-weight: $font-wl-bold-underline-body-xxs-bu-font-weight;
      text-decoration: $font-wl-bold-underline-body-xxs-bu-text-decoration;
      font-style: $font-wl-bold-underline-body-xxs-bu-font-style;
      font-stretch: $font-wl-bold-underline-body-xxs-bu-font-stretch;
      letter-spacing: $font-wl-bold-underline-body-xxs-bu-letter-spacing + px;
      line-height: $font-wl-bold-underline-body-xxs-bu-line-height + px;
    }
  }
  @else if($brand == 'bao') {
    @if($style == 'title-xxl') {
      font-family: $font-bao-title-xxl-font-family;
      font-size: $font-bao-title-xxl-font-size + px;
      font-weight: $font-bao-title-xxl-font-weight;
      text-decoration: $font-bao-title-xxl-text-decoration;
      font-style: $font-bao-title-xxl-font-style;
      font-stretch: $font-bao-title-xxl-font-stretch;
      letter-spacing: $font-bao-title-xxl-letter-spacing + px;
      line-height: $font-bao-title-xxl-line-height + px;
    }
    @else if($style == 'title-xl') {
      font-family: $font-bao-title-xl-font-family;
      font-size: $font-bao-title-xl-font-size + px;
      font-weight: $font-bao-title-xl-font-weight;
      text-decoration: $font-bao-title-xl-text-decoration;
      font-style: $font-bao-title-xl-font-style;
      font-stretch: $font-bao-title-xl-font-stretch;
      letter-spacing: $font-bao-title-xl-letter-spacing + px;
      line-height: $font-bao-title-xl-line-height + px;
    }
    @else if($style == 'title-l') {
      font-family: $font-bao-title-l-font-family;
      font-size: $font-bao-title-l-font-size + px;
      font-weight: $font-bao-title-l-font-weight;
      text-decoration: $font-bao-title-l-text-decoration;
      font-style: $font-bao-title-l-font-style;
      font-stretch: $font-bao-title-l-font-stretch;
      letter-spacing: $font-bao-title-l-letter-spacing + px;
      line-height: $font-bao-title-l-line-height + px;
    }
    @else if($style == 'title-m') {
      font-family: $font-bao-title-m-font-family;
      font-size: $font-bao-title-m-font-size + px;
      font-weight: $font-bao-title-m-font-weight;
      text-decoration: $font-bao-title-m-text-decoration;
      font-style: $font-bao-title-m-font-style;
      font-stretch: $font-bao-title-m-font-stretch;
      letter-spacing: $font-bao-title-m-letter-spacing + px;
      line-height: $font-bao-title-m-line-height + px;
    }
    @else if($style == 'title-s') {
      font-family: $font-bao-title-s-font-family;
      font-size: $font-bao-title-s-font-size + px;
      font-weight: $font-bao-title-s-font-weight;
      text-decoration: $font-bao-title-s-text-decoration;
      font-style: $font-bao-title-s-font-style;
      font-stretch: $font-bao-title-s-font-stretch;
      letter-spacing: $font-bao-title-s-letter-spacing + px;
      line-height: $font-bao-title-s-line-height + px;
    }
    @else if($style == 'title-xs') {
      font-family: $font-bao-title-xs-font-family;
      font-size: $font-bao-title-xs-font-size + px;
      font-weight: $font-bao-title-xs-font-weight;
      text-decoration: $font-bao-title-xs-text-decoration;
      font-style: $font-bao-title-xs-font-style;
      font-stretch: $font-bao-title-xs-font-stretch;
      letter-spacing: $font-bao-title-xs-letter-spacing + px;
      line-height: $font-bao-title-xs-line-height + px;
    }
    @else if($style == 'body-l') {
      font-family: $font-bao-body-l-font-family;
      font-size: $font-bao-body-l-font-size + px;
      font-weight: $font-bao-body-l-font-weight;
      text-decoration: $font-bao-body-l-text-decoration;
      font-style: $font-bao-body-l-font-style;
      font-stretch: $font-bao-body-l-font-stretch;
      letter-spacing: $font-bao-body-l-letter-spacing + px;
      line-height: $font-bao-body-l-line-height + px;
    }
    @else if($style == 'body-m') {
      font-family: $font-bao-body-m-font-family;
      font-size: $font-bao-body-m-font-size + px;
      font-weight: $font-bao-body-m-font-weight;
      text-decoration: $font-bao-body-m-text-decoration;
      font-style: $font-bao-body-m-font-style;
      font-stretch: $font-bao-body-m-font-stretch;
      letter-spacing: $font-bao-body-m-letter-spacing + px;
      line-height: $font-bao-body-m-line-height + px;
    }
    @else if($style == 'body-s') {
      font-family: $font-bao-body-s-font-family;
      font-size: $font-bao-body-s-font-size + px;
      font-weight: $font-bao-body-s-font-weight;
      text-decoration: $font-bao-body-s-text-decoration;
      font-style: $font-bao-body-s-font-style;
      font-stretch: $font-bao-body-s-font-stretch;
      letter-spacing: $font-bao-body-s-letter-spacing + px;
      line-height: $font-bao-body-s-line-height + px;
    }
    @else if($style == 'body-xs') {
      font-family: $font-bao-body-xs-font-family;
      font-size: $font-bao-body-xs-font-size + px;
      font-weight: $font-bao-body-xs-font-weight;
      text-decoration: $font-bao-body-xs-text-decoration;
      font-style: $font-bao-body-xs-font-style;
      font-stretch: $font-bao-body-xs-font-stretch;
      letter-spacing: $font-bao-body-xs-letter-spacing + px;
      line-height: $font-bao-body-xs-line-height + px;
    }
    @else if($style == 'body-xxs') {
      font-family: $font-bao-body-xxs-font-family;
      font-size: $font-bao-body-xxs-font-size + px;
      font-weight: $font-bao-body-xxs-font-weight;
      text-decoration: $font-bao-body-xxs-text-decoration;
      font-style: $font-bao-body-xxs-font-style;
      font-stretch: $font-bao-body-xxs-font-stretch;
      letter-spacing: $font-bao-body-xxs-letter-spacing + px;
      line-height: $font-bao-body-xxs-line-height + px;
    }
      // underline
    @else if($style == 'title-xxl-underline') {
      font-family: $font-bao-underline-title-xxl-underline-font-family;
      font-size: $font-bao-underline-title-xxl-underline-font-size + px;
      font-weight: $font-bao-underline-title-xxl-underline-font-weight;
      text-decoration: $font-bao-underline-title-xxl-underline-text-decoration;
      font-style: $font-bao-underline-title-xxl-underline-font-style;
      font-stretch: $font-bao-underline-title-xxl-underline-font-stretch;
      letter-spacing: $font-bao-underline-title-xxl-underline-letter-spacing + px;
      line-height: $font-bao-underline-title-xxl-underline-line-height + px;
    }
    @else if($style == 'title-xl-underline') {
      font-family: $font-bao-underline-title-xl-underline-font-family;
      font-size: $font-bao-underline-title-xl-underline-font-size + px;
      font-weight: $font-bao-underline-title-xl-underline-font-weight;
      text-decoration: $font-bao-underline-title-xl-underline-text-decoration;
      font-style: $font-bao-underline-title-xl-underline-font-style;
      font-stretch: $font-bao-underline-title-xl-underline-font-stretch;
      letter-spacing: $font-bao-underline-title-xl-underline-letter-spacing + px;
      line-height: $font-bao-underline-title-xl-underline-line-height + px;
    }
    @else if($style == 'title-l-underline') {
      font-family: $font-bao-underline-title-l-underline-font-family;
      font-size: $font-bao-underline-title-l-underline-font-size + px;
      font-weight: $font-bao-underline-title-l-underline-font-weight;
      text-decoration: $font-bao-underline-title-l-underline-text-decoration;
      font-style: $font-bao-underline-title-l-underline-font-style;
      font-stretch: $font-bao-underline-title-l-underline-font-stretch;
      letter-spacing: $font-bao-underline-title-l-underline-letter-spacing + px;
      line-height: $font-bao-underline-title-l-underline-line-height + px;
    }
    @else if($style == 'title-m-underline') {
      font-family: $font-bao-underline-title-m-underline-font-family;
      font-size: $font-bao-underline-title-m-underline-font-size + px;
      font-weight: $font-bao-underline-title-m-underline-font-weight;
      text-decoration: $font-bao-underline-title-m-underline-text-decoration;
      font-style: $font-bao-underline-title-m-underline-font-style;
      font-stretch: $font-bao-underline-title-m-underline-font-stretch;
      letter-spacing: $font-bao-underline-title-m-underline-letter-spacing + px;
      line-height: $font-bao-underline-title-m-underline-line-height + px;
    }
    @else if($style == 'title-s-underline') {
      font-family: $font-bao-underline-title-s-underline-font-family;
      font-size: $font-bao-underline-title-s-underline-font-size + px;
      font-weight: $font-bao-underline-title-s-underline-font-weight;
      text-decoration: $font-bao-underline-title-s-underline-text-decoration;
      font-style: $font-bao-underline-title-s-underline-font-style;
      font-stretch: $font-bao-underline-title-s-underline-font-stretch;
      letter-spacing: $font-bao-underline-title-s-underline-letter-spacing + px;
      line-height: $font-bao-underline-title-s-underline-line-height + px;
    }
    @else if($style == 'title-xs-underline') {
      font-family: $font-bao-underline-title-xs-underline-font-family;
      font-size: $font-bao-underline-title-xs-underline-font-size + px;
      font-weight: $font-bao-underline-title-xs-underline-font-weight;
      text-decoration: $font-bao-underline-title-xs-underline-text-decoration;
      font-style: $font-bao-underline-title-xs-underline-font-style;
      font-stretch: $font-bao-underline-title-xs-underline-font-stretch;
      letter-spacing: $font-bao-underline-title-xs-underline-letter-spacing + px;
      line-height: $font-bao-underline-title-xs-underline-line-height + px;
    }
    @else if($style == 'body-l-underline') {
      font-family: $font-bao-underline-body-l-underline-font-family;
      font-size: $font-bao-underline-body-l-underline-font-size + px;
      font-weight: $font-bao-underline-body-l-underline-font-weight;
      text-decoration: $font-bao-underline-body-l-underline-text-decoration;
      font-style: $font-bao-underline-body-l-underline-font-style;
      font-stretch: $font-bao-underline-body-l-underline-font-stretch;
      letter-spacing: $font-bao-underline-body-l-underline-letter-spacing + px;
      line-height: $font-bao-underline-body-l-underline-line-height + px;
    }
    @else if($style == 'body-m-underline') {
      font-family: $font-bao-underline-body-m-underline-font-family;
      font-size: $font-bao-underline-body-m-underline-font-size + px;
      font-weight: $font-bao-underline-body-m-underline-font-weight;
      text-decoration: $font-bao-underline-body-m-underline-text-decoration;
      font-style: $font-bao-underline-body-m-underline-font-style;
      font-stretch: $font-bao-underline-body-m-underline-font-stretch;
      letter-spacing: $font-bao-underline-body-m-underline-letter-spacing + px;
      line-height: $font-bao-underline-body-m-underline-line-height + px;
    }
    @else if($style == 'body-s-underline') {
      font-family: $font-bao-underline-body-s-underline-font-family;
      font-size: $font-bao-underline-body-s-underline-font-size + px;
      font-weight: $font-bao-underline-body-s-underline-font-weight;
      text-decoration: $font-bao-underline-body-s-underline-text-decoration;
      font-style: $font-bao-underline-body-s-underline-font-style;
      font-stretch: $font-bao-underline-body-s-underline-font-stretch;
      letter-spacing: $font-bao-underline-body-s-underline-letter-spacing + px;
      line-height: $font-bao-underline-body-s-underline-line-height + px;
    }
    @else if($style == 'body-xs-underline') {
      font-family: $font-bao-underline-body-xs-underline-font-family;
      font-size: $font-bao-underline-body-xs-underline-font-size + px;
      font-weight: $font-bao-underline-body-xs-underline-font-weight;
      text-decoration: $font-bao-underline-body-xs-underline-text-decoration;
      font-style: $font-bao-underline-body-xs-underline-font-style;
      font-stretch: $font-bao-underline-body-xs-underline-font-stretch;
      letter-spacing: $font-bao-underline-body-xs-underline-letter-spacing + px;
      line-height: $font-bao-underline-body-xs-underline-line-height + px;
    }
    @else if($style == 'body-xxs-underline') {
      font-family: $font-bao-underline-body-xxs-underline-font-family;
      font-size: $font-bao-underline-body-xxs-underline-font-size + px;
      font-weight: $font-bao-underline-body-xxs-underline-font-weight;
      text-decoration: $font-bao-underline-body-xxs-underline-text-decoration;
      font-style: $font-bao-underline-body-xxs-underline-font-style;
      font-stretch: $font-bao-underline-body-xxs-underline-font-stretch;
      letter-spacing: $font-bao-underline-body-xxs-underline-letter-spacing + px;
      line-height: $font-bao-underline-body-xxs-underline-line-height + px;
    }
      // bold
    @else if($style == 'title-xxl-bold') {
      font-family: $font-bao-bold-title-xxl-bold-font-family;
      font-size: $font-bao-bold-title-xxl-bold-font-size + px;
      font-weight: $font-bao-bold-title-xxl-bold-font-weight;
      text-decoration: $font-bao-bold-title-xxl-bold-text-decoration;
      font-style: $font-bao-bold-title-xxl-bold-font-style;
      font-stretch: $font-bao-bold-title-xxl-bold-font-stretch;
      letter-spacing: $font-bao-bold-title-xxl-bold-letter-spacing + px;
      line-height: $font-bao-bold-title-xxl-bold-line-height + px;
    }
    @else if($style == 'title-xl-bold') {
      font-family: $font-bao-bold-title-xl-bold-font-family;
      font-size: $font-bao-bold-title-xl-bold-font-size + px;
      font-weight: $font-bao-bold-title-xl-bold-font-weight;
      text-decoration: $font-bao-bold-title-xl-bold-text-decoration;
      font-style: $font-bao-bold-title-xl-bold-font-style;
      font-stretch: $font-bao-bold-title-xl-bold-font-stretch;
      letter-spacing: $font-bao-bold-title-xl-bold-letter-spacing + px;
      line-height: $font-bao-bold-title-xl-bold-line-height + px;
    }
    @else if($style == 'title-l-bold') {
      font-family: $font-bao-bold-title-l-bold-font-family;
      font-size: $font-bao-bold-title-l-bold-font-size + px;
      font-weight: $font-bao-bold-title-l-bold-font-weight;
      text-decoration: $font-bao-bold-title-l-bold-text-decoration;
      font-style: $font-bao-bold-title-l-bold-font-style;
      font-stretch: $font-bao-bold-title-l-bold-font-stretch;
      letter-spacing: $font-bao-bold-title-l-bold-letter-spacing + px;
      line-height: $font-bao-bold-title-l-bold-line-height + px;
    }
    @else if($style == 'title-m-bold') {
      font-family: $font-bao-bold-title-m-bold-font-family;
      font-size: $font-bao-bold-title-m-bold-font-size + px;
      font-weight: $font-bao-bold-title-m-bold-font-weight;
      text-decoration: $font-bao-bold-title-m-bold-text-decoration;
      font-style: $font-bao-bold-title-m-bold-font-style;
      font-stretch: $font-bao-bold-title-m-bold-font-stretch;
      letter-spacing: $font-bao-bold-title-m-bold-letter-spacing + px;
      line-height: $font-bao-bold-title-m-bold-line-height + px;
    }
    @else if($style == 'title-s-bold') {
      font-family: $font-bao-bold-title-s-bold-font-family;
      font-size: $font-bao-bold-title-s-bold-font-size + px;
      font-weight: $font-bao-bold-title-s-bold-font-weight;
      text-decoration: $font-bao-bold-title-s-bold-text-decoration;
      font-style: $font-bao-bold-title-s-bold-font-style;
      font-stretch: $font-bao-bold-title-s-bold-font-stretch;
      letter-spacing: $font-bao-bold-title-s-bold-letter-spacing + px;
      line-height: $font-bao-bold-title-s-bold-line-height + px;
    }
    @else if($style == 'title-xs-bold') {
      font-family: $font-bao-bold-title-xs-bold-font-family;
      font-size: $font-bao-bold-title-xs-bold-font-size + px;
      font-weight: $font-bao-bold-title-xs-bold-font-weight;
      text-decoration: $font-bao-bold-title-xs-bold-text-decoration;
      font-style: $font-bao-bold-title-xs-bold-font-style;
      font-stretch: $font-bao-bold-title-xs-bold-font-stretch;
      letter-spacing: $font-bao-bold-title-xs-bold-letter-spacing + px;
      line-height: $font-bao-bold-title-xs-bold-line-height + px;
    }
    @else if($style == 'body-l-bold') {
      font-family: $font-bao-bold-body-l-bold-font-family;
      font-size: $font-bao-bold-body-l-bold-font-size + px;
      font-weight: $font-bao-bold-body-l-bold-font-weight;
      text-decoration: $font-bao-bold-body-l-bold-text-decoration;
      font-style: $font-bao-bold-body-l-bold-font-style;
      font-stretch: $font-bao-bold-body-l-bold-font-stretch;
      letter-spacing: $font-bao-bold-body-l-bold-letter-spacing + px;
      line-height: $font-bao-bold-body-l-bold-line-height + px;
    }
    @else if($style == 'body-m-bold') {
      font-family: $font-bao-bold-body-m-bold-font-family;
      font-size: $font-bao-bold-body-m-bold-font-size + px;
      font-weight: $font-bao-bold-body-m-bold-font-weight;
      text-decoration: $font-bao-bold-body-m-bold-text-decoration;
      font-style: $font-bao-bold-body-m-bold-font-style;
      font-stretch: $font-bao-bold-body-m-bold-font-stretch;
      letter-spacing: $font-bao-bold-body-m-bold-letter-spacing + px;
      line-height: $font-bao-bold-body-m-bold-line-height + px;
    }
    @else if($style == 'body-s-bold') {
      font-family: $font-bao-bold-body-s-bold-font-family;
      font-size: $font-bao-bold-body-s-bold-font-size + px;
      font-weight: $font-bao-bold-body-s-bold-font-weight;
      text-decoration: $font-bao-bold-body-s-bold-text-decoration;
      font-style: $font-bao-bold-body-s-bold-font-style;
      font-stretch: $font-bao-bold-body-s-bold-font-stretch;
      letter-spacing: $font-bao-bold-body-s-bold-letter-spacing + px;
      line-height: $font-bao-bold-body-s-bold-line-height + px;
    }
    @else if($style == 'body-xs-bold') {
      font-family: $font-bao-bold-body-xs-bold-font-family;
      font-size: $font-bao-bold-body-xs-bold-font-size + px;
      font-weight: $font-bao-bold-body-xs-bold-font-weight;
      text-decoration: $font-bao-bold-body-xs-bold-text-decoration;
      font-style: $font-bao-bold-body-xs-bold-font-style;
      font-stretch: $font-bao-bold-body-xs-bold-font-stretch;
      letter-spacing: $font-bao-bold-body-xs-bold-letter-spacing + px;
      line-height: $font-bao-bold-body-xs-bold-line-height + px;
    }
    @else if($style == 'body-xxs-bold') {
      font-family: $font-bao-bold-body-xxs-bold-font-family;
      font-size: $font-bao-bold-body-xxs-bold-font-size + px;
      font-weight: $font-bao-bold-body-xxs-bold-font-weight;
      text-decoration: $font-bao-bold-body-xxs-bold-text-decoration;
      font-style: $font-bao-bold-body-xxs-bold-font-style;
      font-stretch: $font-bao-bold-body-xxs-bold-font-stretch;
      letter-spacing: $font-bao-bold-body-xxs-bold-letter-spacing + px;
      line-height: $font-bao-bold-body-xxs-bold-line-height + px;
    }
      // bold & underline
    @else if($style == 'title-xxl-bold-underline') {
      font-family: $font-bao-bold-underline-title-xxl-bu-font-family;
      font-size: $font-bao-bold-underline-title-xxl-bu-font-size + px;
      font-weight: $font-bao-bold-underline-title-xxl-bu-font-weight;
      text-decoration: $font-bao-bold-underline-title-xxl-bu-text-decoration;
      font-style: $font-bao-bold-underline-title-xxl-bu-font-style;
      font-stretch: $font-bao-bold-underline-title-xxl-bu-font-stretch;
      letter-spacing: $font-bao-bold-underline-title-xxl-bu-letter-spacing + px;
      line-height: $font-bao-bold-underline-title-xxl-bu-line-height + px;
    }
    @else if($style == 'title-xl-bold-underline') {
      font-family: $font-bao-bold-underline-title-xl-bu-font-family;
      font-size: $font-bao-bold-underline-title-xl-bu-font-size + px;
      font-weight: $font-bao-bold-underline-title-xl-bu-font-weight;
      text-decoration: $font-bao-bold-underline-title-xl-bu-text-decoration;
      font-style: $font-bao-bold-underline-title-xl-bu-font-style;
      font-stretch: $font-bao-bold-underline-title-xl-bu-font-stretch;
      letter-spacing: $font-bao-bold-underline-title-xl-bu-letter-spacing + px;
      line-height: $font-bao-bold-underline-title-xl-bu-line-height + px;
    }
    @else if($style == 'title-l-bold-underline') {
      font-family: $font-bao-bold-underline-title-l-bu-font-family;
      font-size: $font-bao-bold-underline-title-l-bu-font-size + px;
      font-weight: $font-bao-bold-underline-title-l-bu-font-weight;
      text-decoration: $font-bao-bold-underline-title-l-bu-text-decoration;
      font-style: $font-bao-bold-underline-title-l-bu-font-style;
      font-stretch: $font-bao-bold-underline-title-l-bu-font-stretch;
      letter-spacing: $font-bao-bold-underline-title-l-bu-letter-spacing + px;
      line-height: $font-bao-bold-underline-title-l-bu-line-height + px;
    }
    @else if($style == 'title-m-bold-underline') {
      font-family: $font-bao-bold-underline-title-m-bu-font-family;
      font-size: $font-bao-bold-underline-title-m-bu-font-size + px;
      font-weight: $font-bao-bold-underline-title-m-bu-font-weight;
      text-decoration: $font-bao-bold-underline-title-m-bu-text-decoration;
      font-style: $font-bao-bold-underline-title-m-bu-font-style;
      font-stretch: $font-bao-bold-underline-title-m-bu-font-stretch;
      letter-spacing: $font-bao-bold-underline-title-m-bu-letter-spacing + px;
      line-height: $font-bao-bold-underline-title-m-bu-line-height + px;
    }
    @else if($style == 'title-s-bold-underline') {
      font-family: $font-bao-bold-underline-title-s-bu-font-family;
      font-size: $font-bao-bold-underline-title-s-bu-font-size + px;
      font-weight: $font-bao-bold-underline-title-s-bu-font-weight;
      text-decoration: $font-bao-bold-underline-title-s-bu-text-decoration;
      font-style: $font-bao-bold-underline-title-s-bu-font-style;
      font-stretch: $font-bao-bold-underline-title-s-bu-font-stretch;
      letter-spacing: $font-bao-bold-underline-title-s-bu-letter-spacing + px;
      line-height: $font-bao-bold-underline-title-s-bu-line-height + px;
    }
    @else if($style == 'title-xs-bold-underline') {
      font-family: $font-bao-bold-underline-title-xs-bu-font-family;
      font-size: $font-bao-bold-underline-title-xs-bu-font-size + px;
      font-weight: $font-bao-bold-underline-title-xs-bu-font-weight;
      text-decoration: $font-bao-bold-underline-title-xs-bu-text-decoration;
      font-style: $font-bao-bold-underline-title-xs-bu-font-style;
      font-stretch: $font-bao-bold-underline-title-xs-bu-font-stretch;
      letter-spacing: $font-bao-bold-underline-title-xs-bu-letter-spacing + px;
      line-height: $font-bao-bold-underline-title-xs-bu-line-height + px;
    }
    @else if($style == 'body-l-bold-underline') {
      font-family: $font-bao-bold-underline-body-l-bu-font-family;
      font-size: $font-bao-bold-underline-body-l-bu-font-size + px;
      font-weight: $font-bao-bold-underline-body-l-bu-font-weight;
      text-decoration: $font-bao-bold-underline-body-l-bu-text-decoration;
      font-style: $font-bao-bold-underline-body-l-bu-font-style;
      font-stretch: $font-bao-bold-underline-body-l-bu-font-stretch;
      letter-spacing: $font-bao-bold-underline-body-l-bu-letter-spacing + px;
      line-height: $font-bao-bold-underline-body-l-bu-line-height + px;
    }
    @else if($style == 'body-m-bold-underline') {
      font-family: $font-bao-bold-underline-body-m-bu-font-family;
      font-size: $font-bao-bold-underline-body-m-bu-font-size + px;
      font-weight: $font-bao-bold-underline-body-m-bu-font-weight;
      text-decoration: $font-bao-bold-underline-body-m-bu-text-decoration;
      font-style: $font-bao-bold-underline-body-m-bu-font-style;
      font-stretch: $font-bao-bold-underline-body-m-bu-font-stretch;
      letter-spacing: $font-bao-bold-underline-body-m-bu-letter-spacing + px;
      line-height: $font-bao-bold-underline-body-m-bu-line-height + px;
    }
    @else if($style == 'body-s-bold-underline') {
      font-family: $font-bao-bold-underline-body-s-bu-font-family;
      font-size: $font-bao-bold-underline-body-s-bu-font-size + px;
      font-weight: $font-bao-bold-underline-body-s-bu-font-weight;
      text-decoration: $font-bao-bold-underline-body-s-bu-text-decoration;
      font-style: $font-bao-bold-underline-body-s-bu-font-style;
      font-stretch: $font-bao-bold-underline-body-s-bu-font-stretch;
      letter-spacing: $font-bao-bold-underline-body-s-bu-letter-spacing + px;
      line-height: $font-bao-bold-underline-body-s-bu-line-height + px;
    }
    @else if($style == 'body-xs-bold-underline') {
      font-family: $font-bao-bold-underline-body-xs-bu-font-family;
      font-size: $font-bao-bold-underline-body-xs-bu-font-size + px;
      font-weight: $font-bao-bold-underline-body-xs-bu-font-weight;
      text-decoration: $font-bao-bold-underline-body-xs-bu-text-decoration;
      font-style: $font-bao-bold-underline-body-xs-bu-font-style;
      font-stretch: $font-bao-bold-underline-body-xs-bu-font-stretch;
      letter-spacing: $font-bao-bold-underline-body-xs-bu-letter-spacing + px;
      line-height: $font-bao-bold-underline-body-xs-bu-line-height + px;
    }
    @else if($style == 'body-xxs-bold-underline') {
      font-family: $font-bao-bold-underline-body-xxs-bu-font-family;
      font-size: $font-bao-bold-underline-body-xxs-bu-font-size + px;
      font-weight: $font-bao-bold-underline-body-xxs-bu-font-weight;
      text-decoration: $font-bao-bold-underline-body-xxs-bu-text-decoration;
      font-style: $font-bao-bold-underline-body-xxs-bu-font-style;
      font-stretch: $font-bao-bold-underline-body-xxs-bu-font-stretch;
      letter-spacing: $font-bao-bold-underline-body-xxs-bu-letter-spacing + px;
      line-height: $font-bao-bold-underline-body-xxs-bu-line-height + px;
    }
  }
}
