$loading-indicator-size: $space-6;
$loading-indicator-size--small: $space-5;

@mixin parcel-mat-form-field {
  .mdc-text-field__input,
  .mat-mdc-select {
    margin: $space-3 0;
  }

  &.mat-mdc-form-field {
    .mat-mdc-form-field-icon-prefix,
    .mat-mdc-form-field-icon-suffix {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;

      > .mat-icon {
        padding: 0;
      }
    }

    .mat-mdc-form-field-hint-wrapper,
    .mat-mdc-form-field-error-wrapper {
      padding: 0;
    }

    .mat-mdc-form-field-subscript-wrapper {
      height: 0;

      &:has(.mat-mdc-form-field-hint-wrapper) {
        &:has(mat-hint) {
          height: auto;
        }
      }
    }
    .mdc-text-field--invalid {
      + .mat-mdc-form-field-subscript-wrapper {
        height: auto;
      }
    }

    .mat-mdc-form-field-infix {
      min-height: $space-8;
      padding: 0;
      position: static;
    }

    .mdc-text-field--no-label:not(.mdc-text-field--textarea),
    .mat-mdc-form-field-input-control.mdc-text-field__input,
    .mat-mdc-form-field-input-control {
    }

    .mdc-text-field,
    .mdc-text-field--outlined {
      padding: 0 $space-3;
    }

    mat-progress-bar {
      position: absolute;
      width: 100%;
      bottom: 1px;
      left: 0;
    }

    .loading-indicator {
      width: $loading-indicator-size;
      height: $loading-indicator-size;
      position: relative;

      border-radius: 50%;
      display: inline-flex;
      background-color: transparent;
      border-top: $space-1 solid transparent;
      border-left: $space-1 solid currentColor;
      border-right: $space-1 solid currentColor;
      border-bottom: $space-1 solid currentColor;
      opacity: .5;
      animation: spin $transitions-speed-1250 linear infinite;
      box-sizing: border-box;

      margin-left: $space-3;
    }
  }

  &--small {
    .mdc-text-field__input,
    .mat-mdc-select {
      margin: $space-1 0;
    }

    &.mat-mdc-form-field {
      .mat-mdc-form-field-infix {
        min-height: $space-6;
      }
      .mdc-text-field--no-label:not(.mdc-text-field--textarea),
      .mat-mdc-form-field-input-control.mdc-text-field__input,
      .mat-mdc-text-field-wrapper,
      .mat-mdc-form-field-input-control {

      }

      .mdc-text-field,
      .mdc-text-field--outlined {
        padding: 0 $space-3;
      }
    }

    .loading-indicator {
      width: $loading-indicator-size--small !important;
      height: $loading-indicator-size--small !important;
    }
  }

  &.text-right {
    .mdc-text-field__input,
    .mat-mdc-select {
      text-align: right;
    }
  }

  .mdc-evolution-chip-set .mdc-evolution-chip {
    margin-top: $space-2;
    margin-bottom: $space-2;
  }

  .mat-mdc-chip-input {
    margin-left: $space-2;
  }
}
