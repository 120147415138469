@import "mixins/menu.mixin.material";

.parcel-mat-menu {
  @include parcel-mat-menu;
}

@import "themes/menu-kion.material";

.theme-stl {
  @import "themes/menu-stl.material";
}
.theme-lmh, .theme-fwk {
  @import "themes/menu-lmh.material";
}
.theme-bao {
  @import "themes/menu-bao.material";
}
.theme-kion {
  @import "themes/menu-kion.material";
}
