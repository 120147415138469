@import "mixins/slide-toggle.mixin.material";

.parcel-mat-slide-toggle {
  @include parcel-mat-slide-toggle;
}

@import "themes/slide-toggle-kion.material";

.theme-stl {
  @import "themes/slide-toggle-stl.material";
}
.theme-lmh, .theme-fwk {
  @import "themes/slide-toggle-lmh.material";
}
.theme-bao {
  @import "themes/slide-toggle-bao.material";
}
.theme-kion {
  @import "themes/slide-toggle-kion.material";
}
