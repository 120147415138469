// old
$transitions-speed-default: 125ms;
$transitions-speed-1250: 1250ms;

// duration
$parcel-duration-fast: 70ms;
$parcel-duration-standard: 150ms;
$parcel-duration-slow: 400ms;

// easing
$parcel-timing-function-ease-in-out: cubic-bezier(.4 , 0, .6, .1);
$parcel-timing-function-ease-in: cubic-bezier(.4 , 0, 1, 1);
$parcel-timing-function-ease-out: cubic-bezier(0 , 0, .6, 1);
$parcel-timing-function-linear: linear;

// animations
@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
@keyframes skeletton-animation {
  0%{background-position:0 50%}
  50%{background-position:100% 50%}
  100%{background-position:0 50%}
}

// transition mixin
@mixin transition($element, $duration, $timing-function) {
  transition: $element $duration $timing-function;
}
