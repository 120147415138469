.mdc-button,
.mdc-fab {
  &.parcel-mat-button,
  &.parcel-mat-button-icon {

    // types
    &.type--primary {

      // styles
      &.style--solid,
      & {
        @include buttonStyle($color-stl-brand-500, 'primary-rounded-2-5', 'primary', $color-stl-brand-500, $color-stl-grey-white);

        &:hover,
        &.hover {
          @include buttonStyle($color-stl-brand-700, 'primary-rounded-2-5', 'primary', $color-stl-brand-700, $color-stl-grey-white, pointer);
        }

        &:focus:not(.cdk-mouse-focused),
        &.focus {
          @include buttonStyle($color-stl-brand-500, 'primary-rounded-2-5', 'primary', $color-stl-grey-white, $color-stl-grey-white, pointer, 2px, $color-stl-feedback-focused);
        }

        &:active,
        &.active {
          @include buttonStyle($color-stl-brand-900, 'primary-rounded-2-5', 'primary', $color-stl-brand-900, $color-stl-grey-white);
        }

        &:disabled,
        &.disabled,
        &.state--loading {
          pointer-events: none;
          @include buttonStyle($color-stl-grey-100, 'primary-rounded-2-5', 'primary', $color-stl-grey-100, $color-stl-grey-400);
        }

        // backgrounds
        &.background-grey {

          &:disabled,
          &.disabled,
          &.state--loading {
            pointer-events: none;
            @include buttonStyle($color-stl-grey-200, 'primary-rounded-2-5', 'primary', $color-stl-grey-200, $color-stl-grey-400);
          }
        }

        &.background-dark {

          &:disabled,
          &.disabled,
          &.state--loading {
            pointer-events: none;
            @include buttonStyle($color-stl-grey-200, 'primary-rounded-2-5', 'primary', $color-stl-grey-200, $color-stl-grey-400);
          }
        }
      }

      &.style--outline {
        @include buttonStyle(transparent, 'primary-rounded-2-5', 'primary', $color-stl-brand-500, $color-stl-brand-500);

        &:hover,
        &.hover {
          @include buttonStyle($color-stl-brand-700, 'primary-rounded-2-5', 'primary', $color-stl-brand-700, $color-stl-grey-white, pointer);
        }

        &:focus:not(.cdk-mouse-focused),
        &.focus {
          @include buttonStyle(transparent, 'primary-rounded-2-5', 'primary', $color-stl-brand-500, $color-stl-brand-500, pointer, 2px, $color-stl-feedback-focused, true);
        }

        &:active,
        &.active {
          @include buttonStyle($color-stl-brand-900, 'primary-rounded-2-5', 'primary', $color-stl-brand-900, $color-stl-grey-white);
        }

        &:disabled,
        &.disabled,
        &.state--loading {
          pointer-events: none;
          @include buttonStyle(transparent, 'primary-rounded-2-5', 'primary', $color-stl-grey-300, $color-stl-grey-400);
        }
      }
    }

    &.type--secondary {

      // styles
      &.style--solid,
      & {
        @include buttonStyle($color-stl-grey-100, 'primary-rounded-2-5', 'primary', $color-stl-grey-100, $color-stl-grey-900);

        &:hover,
        &.hover {
          @include buttonStyle($color-stl-brand-700, 'primary-rounded-2-5', 'primary', $color-stl-brand-700, $color-stl-grey-white, pointer);
        }

        &:focus:not(.cdk-mouse-focused),
        &.focus {
          @include buttonStyle($color-stl-grey-100, 'primary-rounded-2-5', 'primary', $color-stl-grey-100, $color-stl-grey-900, pointer, 2px, $color-stl-feedback-focused, true);
        }

        &:active,
        &.active {
          @include buttonStyle($color-stl-brand-900, 'primary-rounded-2-5', 'primary', $color-stl-brand-900, $color-stl-grey-white);
        }

        &:disabled,
        &.disabled,
        &.state--loading {
          pointer-events: none;
          @include buttonStyle($color-stl-grey-100, 'primary-rounded-2-5', 'primary', $color-stl-grey-100, $color-stl-grey-400);
        }


        // backgrounds
        &.background-grey {
          @include buttonStyle($color-stl-grey-white, 'primary-rounded-2-5', 'primary', $color-stl-grey-white, $color-stl-grey-900);

          &:hover,
          &.hover {
            @include buttonStyle($color-stl-brand-700, 'primary-rounded-2-5', 'primary', $color-stl-brand-700, $color-stl-grey-white, pointer);
          }

          &:active,
          &.active {
            @include buttonStyle($color-stl-brand-900, 'primary-rounded-2-5', 'primary', $color-stl-brand-900, $color-stl-grey-white);
          }

          &:focus:not(.cdk-mouse-focused),
          &.focus {
            @include buttonStyle($color-stl-grey-200, 'primary-rounded-2-5', 'primary', $color-stl-grey-200, $color-stl-grey-900, pointer, 2px, $color-stl-feedback-focused, true);
          }

          &:disabled,
          &.disabled,
          &.state--loading {
            pointer-events: none;
            @include buttonStyle($color-stl-grey-200, 'primary-rounded-2-5', 'primary', $color-stl-grey-200, $color-stl-grey-400);
          }
        }

        &.background-dark {
          @include buttonStyle($color-stl-grey-50, 'primary-rounded-2-5', 'primary', $color-stl-grey-50, $color-stl-grey-900);

          &:hover,
          &.hover {
            @include buttonStyle($color-stl-brand-700, 'primary-rounded-2-5', 'primary', $color-stl-brand-700, $color-stl-grey-50, pointer);
          }

          &:active,
          &.active {
            @include buttonStyle($color-stl-brand-900, 'primary-rounded-2-5', 'primary', $color-stl-brand-900, $color-stl-grey-50);
          }

          &:focus:not(.cdk-mouse-focused),
          &.focus {
            @include buttonStyle($color-stl-grey-50, 'primary-rounded-2-5', 'primary', $color-stl-grey-50, $color-stl-grey-900, pointer, 2px, $color-stl-feedback-focused, true);
          }

          &:disabled,
          &.disabled,
          &.state--loading {
            pointer-events: none;
            @include buttonStyle($color-stl-grey-200, 'primary-rounded-2-5', 'primary', $color-stl-grey-200, $color-stl-grey-400);
          }
        }
      }

      &.style--outline {
        @include buttonStyle(transparent, 'primary-rounded-2-5', 'primary', $color-stl-grey-900, $color-stl-grey-900);

        &:hover,
        &.hover {
          @include buttonStyle($color-stl-brand-700, 'primary-rounded-2-5', 'primary', $color-stl-brand-700, $color-stl-grey-white, pointer);
        }

        &:focus:not(.cdk-mouse-focused),
        &.focus {
          @include buttonStyle(transparent, 'primary-rounded-2-5', 'primary', $color-stl-grey-900, $color-stl-grey-900, pointer, 2px, $color-stl-feedback-focused, true);
        }

        &:active,
        &.active {
          @include buttonStyle($color-stl-brand-900, 'primary-rounded-2-5', 'primary', $color-stl-brand-900, $color-stl-grey-white);
        }

        &:disabled,
        &.disabled,
        &.state--loading {
          pointer-events: none;
          @include buttonStyle(transparent, 'primary-rounded-2-5', 'primary', $color-stl-grey-100, $color-stl-grey-400);
        }
      }
    }

    &.type--tertiary {
      @include buttonStyle(transparent, 'primary-rounded-2-5', 'primary', $color-stl-grey-900, $color-stl-grey-900);

      &:hover,
      &.hover {
        @include buttonStyle($color-stl-brand-700, 'primary-rounded-2-5', 'primary', $color-stl-brand-700, $color-stl-grey-white, pointer);
      }

      &:focus:not(.cdk-mouse-focused),
      &.focus {
        @include buttonStyle(transparent, 'primary-rounded-2-5', 'primary', $color-stl-grey-900, $color-stl-grey-900, pointer, 2px, $color-stl-feedback-focused);
      }

      &:active,
      &.active {
        @include buttonStyle($color-stl-brand-900, 'primary-rounded-2-5', 'primary', $color-stl-brand-900, $color-stl-grey-white, pointer);
      }

      &:disabled,
      &.disabled,
      &.state--loading {
        pointer-events: none;
        @include buttonStyle(transparent, 'primary-rounded-2-5', 'primary', $color-stl-grey-300, $color-stl-grey-300);
      }

      // backgrounds
      &.background-grey {
        @include buttonStyle(transparent, 'primary-rounded-2-5', 'primary', $color-stl-grey-900, $color-stl-grey-900);

        &:hover,
        &.hover {
          @include buttonStyle($color-stl-brand-700, 'primary-rounded-2-5', 'primary', $color-stl-brand-700, $color-stl-grey-white, pointer);
        }

        &:focus:not(.cdk-mouse-focused),
        &.focus {
          @include buttonStyle(transparent, 'primary-rounded-2-5', 'primary', $color-stl-grey-900, $color-stl-grey-900, pointer, 2px, $color-stl-feedback-focused);
        }

        &:active,
        &.active {
          @include buttonStyle($color-stl-brand-900, 'primary-rounded-2-5', 'primary', $color-stl-brand-900, $color-stl-grey-white, pointer);
        }

        &:disabled,
        &.disabled,
        &.state--loading {
          pointer-events: none;
          @include buttonStyle(transparent, 'primary-rounded-2-5', 'primary', $color-stl-grey-400, $color-stl-grey-400);
        }
      }

      &.background-dark {
        @include buttonStyle(transparent, 'primary-rounded-2-5', 'primary', $color-stl-grey-50, $color-stl-grey-50);

        &:hover,
        &.hover {
          @include buttonStyle($color-stl-brand-700, 'primary-rounded-2-5', 'primary', $color-stl-brand-700, $color-stl-grey-50, pointer);
        }

        &:focus:not(.cdk-mouse-focused),
        &.focus {
          @include buttonStyle(transparent, 'primary-rounded-2-5', 'primary', $color-stl-grey-50, $color-stl-grey-50, pointer, 2px, $color-stl-feedback-focused);
        }

        &:active,
        &.active {
          @include buttonStyle($color-stl-brand-900, 'primary-rounded-2-5', 'primary', $color-stl-brand-900, $color-stl-grey-50, pointer);
        }

        &:disabled,
        &.disabled,
        &.state--loading {
          pointer-events: none;
          @include buttonStyle(transparent, 'primary-rounded-2-5', 'primary', $color-stl-grey-600, $color-stl-grey-600);
        }
      }
    }

    &.type--transparent {
      // @include font('body--bolds', 'stl');
      @include buttonStyle(transparent, 'primary-rounded-2-5', 'primary', transparent, $color-stl-grey-700);

      &:hover,
      &.hover {
        @include buttonStyle($color-stl-grey-50, 'primary-rounded-2-5', 'primary', $color-stl-grey-50, $color-stl-brand-500, pointer);
      }

      &:focus:not(.cdk-mouse-focused),
      &.focus {
        @include buttonStyle(transparent, 'primary-rounded-2-5', 'primary', transparent, $color-stl-grey-700, pointer, 2px, $color-stl-feedback-focused);
      }

      &:active,
      &.active {
        @include buttonStyle($color-stl-grey-100, 'primary-rounded-2-5', 'primary', $color-stl-grey-100, $color-stl-brand-500);
      }

      &:disabled,
      &.disabled,
      &.state--loading {
        pointer-events: none;
        @include buttonStyle(transparent, 'primary-rounded-2-5', 'primary', transparent, $color-stl-grey-300);
      }

      // backgrounds
      &.background-grey {
        @include buttonStyle(transparent, 'primary-rounded-2-5', 'primary', transparent, $color-stl-grey-700);

        &:hover,
        &.hover {
          @include buttonStyle($color-stl-grey-50, 'primary-rounded-2-5', 'primary', $color-stl-grey-50, $color-stl-brand-500, pointer);
        }

        &:focus:not(.cdk-mouse-focused),
        &.focus {
          @include buttonStyle(transparent, 'primary-rounded-2-5', 'primary', transparent, $color-stl-grey-700, pointer, 2px, $color-stl-feedback-focused);
        }

        &:active,
        &.active {
          @include buttonStyle($color-stl-grey-200, 'primary-rounded-2-5', 'primary', $color-stl-grey-200, $color-stl-brand-500);
        }

        &:disabled,
        &.disabled,
        &.state--loading {
          pointer-events: none;
          @include buttonStyle(transparent, 'primary-rounded-2-5', 'primary', transparent, $color-stl-grey-400);
        }
      }

      &.background-dark {
        @include buttonStyle(transparent, 'primary-rounded-2-5', 'primary', transparent, $color-stl-grey-50);

        &:hover,
        &.hover {
          @include buttonStyle($color-stl-grey-700, 'primary-rounded-2-5', 'primary', transparent, $color-stl-grey-50, pointer);
        }

        &:focus:not(.cdk-mouse-focused),
        &.focus {
          @include buttonStyle(transparent, 'primary-rounded-2-5', 'primary', transparent, $color-stl-grey-50, pointer, 2px, $color-stl-feedback-focused);
        }

        &:active,
        &.active {
          @include buttonStyle($color-stl-grey-600, 'primary-rounded-2-5', 'primary', transparent, $color-stl-grey-50, pointer);
        }

        &:disabled,
        &.disabled,
        &.state--loading {
          pointer-events: none;
          @include buttonStyle(transparent, 'primary-rounded-2-5', 'primary', transparent, $color-stl-grey-600);
        }
      }
    }

    &.type--icon,
    &.type--image {
      @include buttonStyle(transparent, 'primary-rounded-2-5', 'primary', transparent, $color-stl-grey-900);

      &:hover,
      &.hover {
        @include buttonStyle($color-stl-grey-50, 'primary-rounded-2-5', 'primary', $color-stl-grey-50, $color-stl-brand-500, pointer);
      }

      &:focus:not(.cdk-mouse-focused),
      &.focus {
        @include buttonStyle(transparent, 'primary-rounded-2-5', 'primary', transparent, $color-stl-grey-900, pointer, 2px, $color-stl-feedback-focused, true);
      }

      &:active,
      &.active {
        @include buttonStyle($color-stl-grey-100, 'primary-rounded-2-5', 'primary', $color-stl-grey-100, $color-stl-brand-500);
      }

      &:disabled,
      &.disabled,
      &.state--loading {
        pointer-events: none;
        @include buttonStyle(transparent, 'primary-rounded-2-5', 'primary', transparent, $color-stl-grey-300);

        >.mdc-button__label {
          >span {
            opacity: .6;
          }
        }
      }
    }

    &.type--image {
      >.mdc-button__label {
        >span {
          @include font('body-m-bold', 'stl');
          color: $color-stl-grey-white;
          background: $color-stl-grey-400;
        }
      }
    }

    &.type--carousel {
      @include buttonStyle($color-stl-grey-50, 'primary-rounded-2-5', 'primary', $color-stl-grey-50, $color-stl-grey-600);

      &:hover,
      &.hover {
        @include buttonStyle($color-stl-brand-500, 'primary-rounded-2-5', 'primary', $color-stl-brand-500, $color-stl-grey-white, pointer);
      }

      &:focus:not(.cdk-mouse-focused),
      &.focus {
        @include buttonStyle($color-stl-grey-50, 'primary-rounded-2-5', 'primary', $color-stl-grey-50, $color-stl-grey-600, pointer, 2px, $color-stl-brand-700, true);
      }

      &:active,
      &.active {
        @include buttonStyle($color-stl-brand-900, 'primary-rounded-2-5', 'primary', $color-stl-brand-900, $color-stl-grey-white);
      }

      &:disabled,
      &.disabled,
      &.state--loading {
        pointer-events: none;
        @include buttonStyle(transparent, 'primary-rounded-2-5', 'primary', transparent, $color-stl-grey-300);
      }
    }

    &.type--fab,
    &.type--fab-mini {
      background: $color-stl-brand-500;
      color: $color-stl-grey-white;
      @include elevation('stl', 's');

      &:hover,
      &.hover {
        background: $color-stl-brand-700;
        color: $color-stl-grey-white;
      }

      &:focus:not(.cdk-mouse-focused),
      &.focus {
        background: $color-stl-brand-500;
        color: $color-stl-grey-white;

        @include outline(2px, $color-stl-brand-700);
      }

      &:active,
      &.active,
      &:focus:active {
        background: $color-stl-brand-700;
        color: $color-stl-grey-white;

        @include elevation('stl', 'm');
      }

      &:disabled,
      &.disabled,
      &.state--loading {
        pointer-events: none;
        background: $color-stl-grey-100;
        color: $color-stl-grey-400;
      }
    }

    // sizes
    &.size--medium {
      @include font('body-m-bold', 'stl');
      > .mat-icon {
        margin-right: $space-3;
        height: 20px;
        width: 20px;
      }
    }

    &.size--small {
      @include font('body-s-bold', 'stl');
      > .mat-icon {
        margin-right: $space-2;
        height: $space-4;
        width: $space-4;
      }
    }

    &.icon--right {
      &.size--medium {
        > .mat-icon {
          margin-left: $space-3;
          margin-right: 0;
        }
      }
      &.size--small {
        > .mat-icon {
          margin-left: $space-2;
          margin-right: 0;
        }
      }
    }
  }

  &.parcel-mat-button-icon {
    min-width: auto;
    padding: 0;

    &.size--medium {
      width: $space-8;
      height: $space-8;

      > .mat-icon {
        margin: 0;
        width: $space-6;
        height: $space-6;
        font-size: 0;
      }
    }

    &.size--small {
      width: $space-6;
      height: $space-6;

      > .mat-icon {
        margin: 0;
        width: $space-5;
        height: $space-5;
        font-size: 0;
      }
    }
  }
}
