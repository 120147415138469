.mat-mdc-paginator {
  &.parcel-mat-paginator {
    @include font('body-s', 'lmh');
    color: $color-lmh-grey-900;

    .mat-mdc-paginator-outer-container {
      .mat-mdc-paginator-container {
        .mat-mdc-paginator-page-size {
          .mat-mdc-text-field-wrapper {
            &.mdc-text-field--focused {
              .mat-mdc-form-field-flex {
                background: $color-lmh-grey-100;
                border-radius: $border-primary-rounded;
              }
            }
          }
        }
        .mat-mdc-paginator-range-actions {
          > button {
            &:hover {
              border-radius: 0;
              > svg {
                fill: $color-lmh-brand-500;
              }
            }
          }
        }
      }
    }

    .mat-mdc-select-value {
      @include font('body-s', 'lmh');
      color: $color-lmh-grey-900;
    }
  }
}


.mdc-list-item--selected .mdc-list-item__primary-text,
.mdc-list-item--activated .mdc-list-item__primary-text {
  @include font('body-s', 'lmh');
  color: $color-lmh-brand-500;
  font-weight: bold !important;
}

.mat-mdc-option:hover:not(.mdc-list-item--disabled),
.mat-mdc-option:focus:not(.mdc-list-item--disabled),
.mat-mdc-option.mat-mdc-option-active,
.mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
  background: $color-lmh-grey-100;
}

.mat-mdc-option .mdc-list-item__primary-text {
  @include font('body-s', 'lmh');
}
.mdc-list-item__primary-text {
  @include font('body-s', 'lmh');
  color: $color-lmh-grey-900;
}

