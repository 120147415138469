.parcel-mat-expansion-panel {
  &.mat-expansion-panel {
    @include font('body-m', 'lmh');
    color: $color-lmh-grey-900;
    box-shadow: none;
    border-radius: 0;
    width: 100%;

    .mat-expansion-panel-content {
      @include font('body-m', 'lmh');
      color: $color-lmh-grey-900;
    }
  }

  .mat-expansion-panel-header {
    @include font('body-m', 'lmh');
    color: $color-lmh-grey-900;
    font-weight: 700;
    height: $space-9;
  }

  &.mat-expansion-panel-header.mat-expanded {
    height: $space-9;
  }

  &.mat-expansion-panel-body {

  }

  // States
  &.mat-expansion-panel-header:hover,
  .mat-expansion-panel-header.mat-expanded,
  .mat-expansion-panel-header.mat-expanded:hover {
    background-color: $color-lmh-grey-100;
  }
}
