@import "mixins/divider.mixin.material";

.parcel-mat-divider {
  @include parcel-mat-divider;
}

@import "themes/divider-kion.material";

.theme-stl {
  @import "themes/divider-stl.material";
}
.theme-lmh, .theme-fwk {
  @import "themes/divider-lmh.material";
}
.theme-bao {
  @import "themes/divider-bao.material";
}
.theme-kion {
  @import "themes/divider-kion.material";
}
