.parcel-mat-notification-list {
  &.mdc-list {
    background: $color-lmh-grey-100;

    mat-list-item, parcelnotificationwidgetitem {
      background: $color-lmh-grey-100;

      + mat-list-item, + parcelnotificationwidgetitem {
        border: none;

        .mdc-list-item__content {
          border-top: 1px solid $color-lmh-grey-200;
        }
      }

      &:not(.empty) {
        &:hover {
          cursor: pointer;
          background: $color-lmh-grey-50;
        }
      }
    }
  }

  &__item__timestamp {
  }

  &__item__icon {
    color: $color-lmh-grey-900;
    border-radius: $border-primary-rounded-0;

    &.state-info {
      background: $color-lmh-grey-200;
    }
    &.state-warning {
      background: $color-lmh-feedback-background-warning-alert;
    }
    &.state-issue {
      background: $color-lmh-feedback-background-danger-alert;
    }
    &.state-resolved {
      background: $color-lmh-feedback-background-success-alert;
    }

    > span {
      @include font('body-s', 'lmh');
      color: $color-lmh-grey-900;
    }
  }

  &__item__time {
    color: $color-lmh-grey-900;
    @include font('body-s', 'lmh');
    font-weight: 700;
  }

  &__item__date {
    color: $color-lmh-grey-700;
    @include font('body-xs', 'lmh');
  }

  &__item__title {
    color: $color-lmh-grey-900;
    @include font('body-s', 'lmh');
    font-weight: 700;
  }

  &__item__subtitle {
    color: $color-lmh-grey-700;
    @include font('body-xs', 'lmh');
  }

  &__item__arrow {
    color: $color-lmh-grey-300;
  }

  &__item__empty {
    color: $color-lmh-grey-700;
    @include font('body-s', 'lmh');
  }
}
