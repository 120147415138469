@mixin parcel-card() {
  &.parcel-mat-card {
    padding: $space-4;
    box-shadow: none;
    transition: box-shadow $parcel-duration-fast;
    outline: none;

    // disabled
    &--disabled {
      pointer-events: none;

      > * {
        opacity: .6;
      }
    }
  }
}
