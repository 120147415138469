.parcel-breadcrumb {
  &.mat-list-base {
    > .mat-list-item {
      .mat-list-item-content {
        .parcel-anchor {
          @include font('body-xs', 'lmh');
          color: $color-lmh-grey-700;
          &:hover {
            color: $color-lmh-brand-500;
          }
        }
      }
    }

    &.dark-mode {
      > .mat-list-item {
        .mat-list-item-content {
          &:after {
            background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="%23c2c2c2" viewBox="0 0 32 32"><path fill-rule="evenodd" d="M12.414 24.414 11 23l7.293-7.293L11 8.414 12.414 7l8.707 8.707-8.707 8.707Z" clip-rule="evenodd"/></svg>') center center;
            background-size: contain;
            background-repeat: no-repeat;
          }
          .parcel-anchor {
            color: $color-lmh-grey-300;
            &:hover {
              color: $color-lmh-brand-500;
            }
          }
        }
      }
    }
  }
}
