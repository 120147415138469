@mixin parcel-mat-table {
  &.mat-mdc-table {
    width: 100%;

    .mdc-data-table__header-row, .mdc-data-table__row {
      height: auto;
      min-height: 29px;

      > .mat-mdc-cell {
        > button {
          width: 29px;
          height: 29px;
        }
      }

    }

    .mdc-data-table__header-cell {
      padding: $space-2 $space-6 $space-2 $space-4;
    }

    .mdc-data-table__cell {
      padding: $space-1 $space-6 $space-1 $space-4;
    }
  }
}

@mixin parcel-mat-table-transparent {

}
