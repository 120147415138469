@import "./resources/variables";
@import "./resources/font-mixins";
@import "./resources/border";
@import "./resources/elevation";
@import "./resources/space";
@import "./resources/transitions";
@import "./resources/cdk-overlay";
@import "./resources/global";
@import "./resources/theme-mixin.scss";

// Font classes to use in html test
.theme-lmh {
  .title-xxl {
    @include font('title-xxl', 'lmh');
  }
  .title-xl {
    @include font('title-xl', 'lmh');
  }
  .title-l {
    @include font('title-l', 'lmh');
  }
  .title-m {
    @include font('title-m', 'lmh');
  }
  .title-s {
    @include font('title-s', 'lmh');
  }
  .title-xs {
    @include font('title-xs', 'lmh');
  }
  .body-l {
    @include font('body-l', 'lmh');
  }
  .body-m {
    @include font('body-m', 'lmh');
  }
  .body-s {
    @include font('body-s', 'lmh');
  }
  .body-xs {
    @include font('body-xs', 'lmh');
  }
  .body-xxs {
    @include font('body-xxs', 'lmh');
  }

  .title-xxl-underline{
    @include font('title-xxl-underline', 'lmh');
  }
  .title-xl-underline {
    @include font('title-xl-underline', 'lmh');
  }
  .title-l-underline {
    @include font('title-l-underline', 'lmh');
  }
  .title-m-underline {
    @include font('title-m-underline', 'lmh');
  }
  .title-s-underline {
    @include font('title-s-underline', 'lmh');
  }
  .title-xs-underline {
    @include font('title-xs-underline', 'lmh');
  }
  .body-l-underline {
    @include font('body-l-underline', 'lmh');
  }
  .body-m-underline {
    @include font('body-m-underline', 'lmh');
  }
  .body-s-underline {
    @include font('body-s-underline', 'lmh');
  }
  .body-xs-underline {
    @include font('body-xs-underline', 'lmh');
  }
  .body-xxs-underline {
    @include font('body-xxs-underline', 'lmh');
  }

  .title-xxl-bold {
    @include font('title-xxl-bold', 'lmh');
  }
  .title-xl-bold {
    @include font('title-xl-bold', 'lmh');
  }
  .title-l-bold {
    @include font('title-l-bold', 'lmh');
  }
  .title-m-bold {
    @include font('title-m-bold', 'lmh');
  }
  .title-s-bold {
    @include font('title-s-bold', 'lmh');
  }
  .title-xs-bold {
    @include font('title-xs-bold', 'lmh');
  }
  .body-l-bold {
    @include font('body-l-bold', 'lmh');
  }
  .body-m-bold {
    @include font('body-m-bold', 'lmh');
  }
  .body-s-bold {
    @include font('body-s-bold', 'lmh');
  }
  .body-xs-bold {
    @include font('body-xs-bold', 'lmh');
  }
  .body-xxs-bold {
    @include font('body-xxs-bold', 'lmh');
  }

  .title-xxl-bold-underline {
    @include font('title-xxl-bold-underline', 'lmh');
  }
  .title-xl-bold-underline {
    @include font('title-xl-bold-underline', 'lmh');
  }
  .title-l-bold-underline {
    @include font('title-l-bold-underline', 'lmh');
  }
  .title-m-bold-underline {
    @include font('title-m-bold-underline', 'lmh');
  }
  .title-s-bold-underline {
    @include font('title-s-bold-underline', 'lmh');
  }
  .title-xs-bold-underline {
    @include font('title-xs-bold-underline', 'lmh');
  }
  .body-l-bold-underline {
    @include font('body-l-bold-underline', 'lmh');
  }
  .body-m-bold-underline {
    @include font('body-m-bold-underline', 'lmh');
  }
  .body-s-bold-underline {
    @include font('body-s-bold-underline', 'lmh');
  }
  .body-xs-bold-underline {
    @include font('body-xs-bold-underline', 'lmh');
  }
  .body-xxs-bold-underline {
    @include font('body-xxs-bold-underline', 'lmh');
  }
}
.theme-stl {
  .title-xxl {
    @include font('title-xxl', 'stl');
  }
  .title-xl {
    @include font('title-xl', 'stl');
  }
  .title-l {
    @include font('title-l', 'stl');
  }
  .title-m {
    @include font('title-m', 'stl');
  }
  .title-s {
    @include font('title-s', 'stl');
  }
  .title-xs {
    @include font('title-xs', 'stl');
  }
  .body-l {
    @include font('body-l', 'stl');
  }
  .body-m {
    @include font('body-m', 'stl');
  }
  .body-s {
    @include font('body-s', 'stl');
  }
  .body-xs {
    @include font('body-xs', 'stl');
  }
  .body-xxs {
    @include font('body-xxs', 'stl');
  }

  .title-xxl-underline{
    @include font('title-xxl-underline', 'stl');
  }
  .title-xl-underline {
    @include font('title-xl-underline', 'stl');
  }
  .title-l-underline {
    @include font('title-l-underline', 'stl');
  }
  .title-m-underline {
    @include font('title-m-underline', 'stl');
  }
  .title-s-underline {
    @include font('title-s-underline', 'stl');
  }
  .title-xs-underline {
    @include font('title-xs-underline', 'stl');
  }
  .body-l-underline {
    @include font('body-l-underline', 'stl');
  }
  .body-m-underline {
    @include font('body-m-underline', 'stl');
  }
  .body-s-underline {
    @include font('body-s-underline', 'stl');
  }
  .body-xs-underline {
    @include font('body-xs-underline', 'stl');
  }
  .body-xxs-underline {
    @include font('body-xxs-underline', 'stl');
  }

  .title-xxl-bold {
    @include font('title-xxl-bold', 'stl');
  }
  .title-xl-bold {
    @include font('title-xl-bold', 'stl');
  }
  .title-l-bold {
    @include font('title-l-bold', 'stl');
  }
  .title-m-bold {
    @include font('title-m-bold', 'stl');
  }
  .title-s-bold {
    @include font('title-s-bold', 'stl');
  }
  .title-xs-bold {
    @include font('title-xs-bold', 'stl');
  }
  .body-l-bold {
    @include font('body-l-bold', 'stl');
  }
  .body-m-bold {
    @include font('body-m-bold', 'stl');
  }
  .body-s-bold {
    @include font('body-s-bold', 'stl');
  }
  .body-xs-bold {
    @include font('body-xs-bold', 'stl');
  }
  .body-xxs-bold {
    @include font('body-xxs-bold', 'stl');
  }

  .title-xxl-bold-underline {
    @include font('title-xxl-bold-underline', 'stl');
  }
  .title-xl-bold-underline {
    @include font('title-xl-bold-underline', 'stl');
  }
  .title-l-bold-underline {
    @include font('title-l-bold-underline', 'stl');
  }
  .title-m-bold-underline {
    @include font('title-m-bold-underline', 'stl');
  }
  .title-s-bold-underline {
    @include font('title-s-bold-underline', 'stl');
  }
  .title-xs-bold-underline {
    @include font('title-xs-bold-underline', 'stl');
  }
  .body-l-bold-underline {
    @include font('body-l-bold-underline', 'stl');
  }
  .body-m-bold-underline {
    @include font('body-m-bold-underline', 'stl');
  }
  .body-s-bold-underline {
    @include font('body-s-bold-underline', 'stl');
  }
  .body-xs-bold-underline {
    @include font('body-xs-bold-underline', 'stl');
  }
  .body-xxs-bold-underline {
    @include font('body-xxs-bold-underline', 'stl');
  }
}
.theme-bao {
  .title-xxl {
    @include font('title-xxl', 'bao');
  }
  .title-xl {
    @include font('title-xl', 'bao');
  }
  .title-l {
    @include font('title-l', 'bao');
  }
  .title-m {
    @include font('title-m', 'bao');
  }
  .title-s {
    @include font('title-s', 'bao');
  }
  .title-xs {
    @include font('title-xs', 'bao');
  }
  .body-l {
    @include font('body-l', 'bao');
  }
  .body-m {
    @include font('body-m', 'bao');
  }
  .body-s {
    @include font('body-s', 'bao');
  }
  .body-xs {
    @include font('body-xs', 'bao');
  }
  .body-xxs {
    @include font('body-xxs', 'bao');
  }


  .title-xxl-underline{
    @include font('title-xxl-underline', 'bao');
  }
  .title-xl-underline {
    @include font('title-xl-underline', 'bao');
  }
  .title-l-underline {
    @include font('title-l-underline', 'bao');
  }
  .title-m-underline {
    @include font('title-m-underline', 'bao');
  }
  .title-s-underline {
    @include font('title-s-underline', 'bao');
  }
  .title-xs-underline {
    @include font('title-xs-underline', 'bao');
  }
  .body-l-underline {
    @include font('body-l-underline', 'bao');
  }
  .body-m-underline {
    @include font('body-m-underline', 'bao');
  }
  .body-s-underline {
    @include font('body-s-underline', 'bao');
  }
  .body-xs-underline {
    @include font('body-xs-underline', 'bao');
  }
  .body-xxs-underline {
    @include font('body-xxs-underline', 'bao');
  }

  .title-xxl-bold {
    @include font('title-xxl-bold', 'bao');
  }
  .title-xl-bold {
    @include font('title-xl-bold', 'bao');
  }
  .title-l-bold {
    @include font('title-l-bold', 'bao');
  }
  .title-m-bold {
    @include font('title-m-bold', 'bao');
  }
  .title-s-bold {
    @include font('title-s-bold', 'bao');
  }
  .title-xs-bold {
    @include font('title-xs-bold', 'bao');
  }
  .body-l-bold {
    @include font('body-l-bold', 'bao');
  }
  .body-m-bold {
    @include font('body-m-bold', 'bao');
  }
  .body-s-bold {
    @include font('body-s-bold', 'bao');
  }
  .body-xs-bold {
    @include font('body-xs-bold', 'bao');
  }
  .body-xxs-bold {
    @include font('body-xxs-bold', 'bao');
  }

  .title-xxl-bold-underline {
    @include font('title-xxl-bold-underline', 'bao');
  }
  .title-xl-bold-underline {
    @include font('title-xl-bold-underline', 'bao');
  }
  .title-l-bold-underline {
    @include font('title-l-bold-underline', 'bao');
  }
  .title-m-bold-underline {
    @include font('title-m-bold-underline', 'bao');
  }
  .title-s-bold-underline {
    @include font('title-s-bold-underline', 'bao');
  }
  .title-xs-bold-underline {
    @include font('title-xs-bold-underline', 'bao');
  }
  .body-l-bold-underline {
    @include font('body-l-bold-underline', 'bao');
  }
  .body-m-bold-underline {
    @include font('body-m-bold-underline', 'bao');
  }
  .body-s-bold-underline {
    @include font('body-s-bold-underline', 'bao');
  }
  .body-xs-bold-underline {
    @include font('body-xs-bold-underline', 'bao');
  }
  .body-xxs-bold-underline {
    @include font('body-xxs-bold-underline', 'bao');
  }
}
.theme-kion {
  .title-xxl {
    @include font('title-xxl', 'wl');
  }
  .title-xl {
    @include font('title-xl', 'wl');
  }
  .title-l {
    @include font('title-l', 'wl');
  }
  .title-m {
    @include font('title-m', 'wl');
  }
  .title-s {
    @include font('title-s', 'wl');
  }
  .title-xs {
    @include font('title-xs', 'wl');
  }
  .body-l {
    @include font('body-l', 'wl');
  }
  .body-m {
    @include font('body-m', 'wl');
  }
  .body-s {
    @include font('body-s', 'wl');
  }
  .body-xs {
    @include font('body-xs', 'wl');
  }
  .body-xxs {
    @include font('body-xxs', 'wl');
  }


  .title-xxl-underline{
    @include font('title-xxl-underline', 'wl');
  }
  .title-xl-underline {
    @include font('title-xl-underline', 'wl');
  }
  .title-l-underline {
    @include font('title-l-underline', 'wl');
  }
  .title-m-underline {
    @include font('title-m-underline', 'wl');
  }
  .title-s-underline {
    @include font('title-s-underline', 'wl');
  }
  .title-xs-underline {
    @include font('title-xs-underline', 'wl');
  }
  .body-l-underline {
    @include font('body-l-underline', 'wl');
  }
  .body-m-underline {
    @include font('body-m-underline', 'wl');
  }
  .body-s-underline {
    @include font('body-s-underline', 'wl');
  }
  .body-xs-underline {
    @include font('body-xs-underline', 'wl');
  }
  .body-xxs-underline {
    @include font('body-xxs-underline', 'wl');
  }

  .title-xxl-bold {
    @include font('title-xxl-bold', 'wl');
  }
  .title-xl-bold {
    @include font('title-xl-bold', 'wl');
  }
  .title-l-bold {
    @include font('title-l-bold', 'wl');
  }
  .title-m-bold {
    @include font('title-m-bold', 'wl');
  }
  .title-s-bold {
    @include font('title-s-bold', 'wl');
  }
  .title-xs-bold {
    @include font('title-xs-bold', 'wl');
  }
  .body-l-bold {
    @include font('body-l-bold', 'wl');
  }
  .body-m-bold {
    @include font('body-m-bold', 'wl');
  }
  .body-s-bold {
    @include font('body-s-bold', 'wl');
  }
  .body-xs-bold {
    @include font('body-xs-bold', 'wl');
  }
  .body-xxs-bold {
    @include font('body-xxs-bold', 'wl');
  }

  .title-xxl-bold-underline {
    @include font('title-xxl-bold-underline', 'wl');
  }
  .title-xl-bold-underline {
    @include font('title-xl-bold-underline', 'wl');
  }
  .title-l-bold-underline {
    @include font('title-l-bold-underline', 'wl');
  }
  .title-m-bold-underline {
    @include font('title-m-bold-underline', 'wl');
  }
  .title-s-bold-underline {
    @include font('title-s-bold-underline', 'wl');
  }
  .title-xs-bold-underline {
    @include font('title-xs-bold-underline', 'wl');
  }
  .body-l-bold-underline {
    @include font('body-l-bold-underline', 'wl');
  }
  .body-m-bold-underline {
    @include font('body-m-bold-underline', 'wl');
  }
  .body-s-bold-underline {
    @include font('body-s-bold-underline', 'wl');
  }
  .body-xs-bold-underline {
    @include font('body-xs-bold-underline', 'wl');
  }
  .body-xxs-bold-underline {
    @include font('body-xxs-bold-underline', 'wl');
  }
}