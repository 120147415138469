body {
	&.drawer-opened {
		&.keyboard-opened {
			--safe-area-bottom: 0 !important;
		}
	}
	&.logged {
		&:before {
			content: '';
			position: fixed;
			width: 100%;
			height: var(--safe-area-top);
			top: 0;
			z-index: 10;
			background: var(--primary-color);
		}
	}
}
