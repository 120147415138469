@import "mixins/tooltip.mixin.material";

.parcel-mat-tooltip {
  @include parcel-mat-tooltip;
}

.mat-mdc-tooltip-panel-left {
  .parcel-mat-tooltip {
    overflow: visible;
    &:after {
      content: '';
      position: absolute;
      right: -6px;
      top:0;
      bottom: 0;
      margin: auto;

      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6px 0 6px 6px;
    }
  }
}
.mat-mdc-tooltip-panel-right {
  .parcel-mat-tooltip {
    overflow: visible;
    &:after {
      content: '';
      position: absolute;
      left: -6px;
      top:0;
      bottom: 0;
      margin: auto;

      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6px 6px 6px 0;
    }
  }
}
.mat-mdc-tooltip-panel-below {
  .parcel-mat-tooltip {
    overflow: visible;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: -6px;
      right: 0;
      margin: auto;

      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 6px 6px 6px;
    }
  }
}
.mat-mdc-tooltip-panel-above {
  .parcel-mat-tooltip {
    overflow: visible;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -6px;
      right: 0;
      margin: auto;

      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6px 6px 0 6px;
    }
  }
}


@import "themes/tooltip-kion.material";

.theme-stl {
  @import "themes/tooltip-stl.material";
}
.theme-lmh, .theme-fwk {
  @import "themes/tooltip-lmh.material";
}
.theme-bao {
  @import "themes/tooltip-bao.material";
}
.theme-kion {
  @import "themes/tooltip-kion.material";
}
