.mat-badge {
  .mat-badge-content {
    background-color: $color-bao-brand-500;
    color: $color-bao-grey-white;
  }

  &-small {
    .mat-badge-content {
      @include font('body-xxs', 'bao');
    }
  }
  &-medium {
    .mat-badge-content {
      @include font('body-xs', 'bao');
    }
  }
  &-large {
    .mat-badge-content {
      @include font('body-m', 'bao');
    }
  }
}
