@mixin fontstyle() {
  color: $color-wl-grey-700;
  @include font('body-s', 'wl');
}

@mixin default() {
  @include border('primary-rounded', 'primary', $color-wl-grey-400);
  background-color: $color-wl-grey-white;
}

@mixin checked-default() {
  @include border('primary-rounded', 'primary', $color-wl-brand-500);
  background-color: $color-wl-brand-500;
}

@mixin hover() {
  @include border('primary-rounded', 'primary', $color-wl-brand-700);
  background-color: $color-wl-grey-white;
}

@mixin checked-hover() {
  @include border('primary-rounded', 'primary', $color-wl-brand-700);
  background-color: $color-wl-brand-700;
}

@mixin disabled() {
  @include border('primary-rounded', 'primary', $color-wl-grey-300);
  background-color: $color-wl-grey-white;
}

@mixin checked-disabled() {
  @include border('primary-rounded', 'primary', $color-wl-grey-300);
  background-color: $color-wl-grey-300;
}

@mixin active() {
  @include border('primary-rounded', 'primary', $color-wl-brand-700);
  background-color: $color-wl-grey-white;
  border-color: $color-wl-brand-900;
}

@mixin checked-active() {
  @include border('primary-rounded', 'primary', $color-wl-brand-700);
  background-color: $color-wl-brand-700;
  border-color: $color-wl-brand-900;
}

@mixin focused() {
  display: block;
  opacity: 1 !important;
  width: 18px;
  height: 18px;
  border-radius: 3px;
  left: calc(50% - 11px);
  top: -1px;
  background: $color-wl-grey-white;
  border: 2px solid $color-wl-brand-700;
}

@mixin error() {
  @include border('primary-rounded', 'primary', $color-wl-feedback-danger);
  background-color: $color-wl-grey-white;
}

@mixin checkmark() {
  color: $color-wl-grey-white;
  stroke-width: 2px;
}

.parcel-mat-checkbox {
  --mdc-checkbox-selected-checkmark-color: #{$color-wl-grey-white};
  &.mat-mdc-checkbox {
    .mdc-form-field {
      @include fontstyle();
    }

    .mdc-checkbox {
      .mdc-checkbox__native-control {
        &:enabled {
          &:not(:checked) {
            &:not(:indeterminate) {
              &:not([data-indeterminate=true]) {
                ~ .mdc-checkbox__background {
                  @include default();
                }
              }
            }
          }

          &:checked, &:indeterminate {
            ~ .mdc-checkbox__background {
              @include checked-default();
            }
          }
        }

        &[data-indeterminate=true] {
          &:enabled {
            ~ .mdc-checkbox__background {
              @include checked-default();
            }
          }
        }

        &:disabled {
          &:not(:checked) {
            &:not(:indeterminate) {
              &:not([data-indeterminate=true]) {
                ~ .mdc-checkbox__background {
                  @include disabled();
                }
              }
            }
          }

          &:checked, &:indeterminate, [data-indeterminate=true][disabled] {
            ~ .mdc-checkbox__background {
              @include checked-disabled();
            }
          }
        }
      }

      // hover
      &:hover {
        .mdc-checkbox__native-control {
          &:enabled {
            &:not(:checked) {
              &:not(:indeterminate) {
                &:not([data-indeterminate=true]) {
                  ~ .mdc-checkbox__background {
                    @include hover();
                  }
                }
              }
            }

            &:checked, &:indeterminate {
              ~ .mdc-checkbox__background {
                @include checked-hover();
              }
            }
          }

          &[data-indeterminate=true] {
            &:enabled {
              ~ .mdc-checkbox__background {
                @include checked-hover();
              }
            }
          }
        }
      }

      // active
      &:not(:disabled) {
        &:active {
          .mdc-checkbox__native-control {
            &:enabled {
              &:not(:checked) {
                &:not(:indeterminate) {
                  &:not([data-indeterminate=true]) {
                    ~ .mdc-checkbox__background {
                      @include active();
                    }
                  }
                }
              }

              &:checked, &:indeterminate {
                ~ .mdc-checkbox__background {
                  @include checked-active();
                }
              }
            }

            &[data-indeterminate=true] {
              &:enabled {
                ~ .mdc-checkbox__background {
                  @include checked-active();
                }
              }
            }
          }
        }
      }
    }

    &.cdk-keyboard-focused {
      .mdc-checkbox__ripple {
        @include focused();
      }
    }

    .mdc-checkbox__checkmark-path {
      @include checkmark();
    }

    .mdc-checkbox__mixedmark {
      width: 10px;
      border-bottom: none;
      margin-bottom: 1px;
    }
  }
}

.mat-pseudo-checkbox {
  border-radius: 3px;
  width: 16px;
  height: 16px;
}

.mat-pseudo-checkbox-full.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-full.mat-pseudo-checkbox-indeterminate {
  --mat-full-pseudo-checkbox-selected-icon-color: #{$color-wl-brand-500};
}