$tab-height-l: 56px;
$tab-height-m: 48px;
$tab-height-s: 32px;

$tab-padding: $space-4;

@mixin parcel-mat-tabs {
  .mat-mdc-tab-list {
    transition: none;
  }

  .mat-mdc-tab,
  .mat-mdc-tab-link {
    &.mdc-tab {
      min-width: 1px;
      flex-grow: 0 !important;
      padding: 0 $tab-padding;

      .mdc-tab-indicator .mdc-tab-indicator__content {
        transition: none;
      }

      // icon
      .mdc-tab__content {
        .mdc-tab__text-label {
          transition: none;

          > .mat-icon {
            width: $space-5;
            height: $space-5;
            margin-right: $space-2;
          }
        }
      }
    }
  }

  &:not(.type--underline) {
    //&:not(.type--normal) {
    //  &:after {
    //    position: absolute;
    //    content: "type missing: type--underline or type--normal";
    //    background: yellow;
    //    padding: 5px;
    //    z-index: 100;
    //  }
    //}
  }
  &:not(.style--white) {
    &:not(.style--grey) {
      &:before {
        position: absolute;
        content: "style missing: style--white or style--grey";
        background: red;
        margin-top: 25px;
        padding: 5px;
        z-index: 101;
      }
    }
  }
}


@mixin parcel-mat-tabs-bold {
  .mat-mdc-tab,
  .mat-mdc-tab-link {
    .mdc-tab__content {
      .mdc-tab__text-label {
        font-weight: bold !important;
      }
    }
  }
}

@mixin parcel-mat-tabs-s {
  .mat-mdc-tab,
  .mat-mdc-tab-link {
    &.mdc-tab {
      height: $tab-height-s;
    }
  }
}
@mixin parcel-mat-tabs-m {
  .mat-mdc-tab,
  .mat-mdc-tab-link {
    &.mdc-tab {
      height: $tab-height-m;
    }
  }
}
@mixin parcel-mat-tabs-l {
  .mat-mdc-tab,
  .mat-mdc-tab-link {
    &.mdc-tab {
      height: $tab-height-l;
    }
  }
}
